import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AssessmentDataContext from "./AssessmentDataContext";
import { solid, thin, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

class AssessmentHeader extends Component {
    constructor (props) {
        super(props);
        this.state = {
            headerText: '',
            subHeaderText: '',
            assessment_status: {
                TimeAssessed: 0,
                FirstAssessed: '',
                LastAssessed: '',
                NextDue: ''
            },
            pills: {
                text: '',
                color: ''
            }
        };

    }
    static contextType = AssessmentDataContext;

    componentDidMount() {
        this.initialiseState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.initialiseState();
        }
    }

    initialiseState = async () => {
        const { assessment_necessary_object } = this.context;
        let pills = this.state.pills;
        let dueDate = assessment_necessary_object.assessment_due_date;
        let headerText = `${assessment_necessary_object.assessment_name} - ${moment.unix(assessment_necessary_object.assessment_due_date).format("DD/MM/YYYY")}`;
        let subHeaderText = `Assessment Assigned to <b>${assessment_necessary_object?.assessment_assigned_to_name} </b>for their Roles at ${this.props?.gcNames?.join(", ")}.`

        let assessment_pills = this.getAssessmentStatus(assessment_necessary_object.re_assessment_window, assessment_necessary_object.assessment_warning_window, dueDate);
        pills["text"] = assessment_pills["text"];
        pills["color"] = assessment_pills["color"];
        this.setState({ headerText, subHeaderText, pills });
    }

    getAssessmentStatus = (reassessmentWindow = 20, warningWindow = 30, assessmentDueDate) => {

        // Calculate the current date/time in Unix timestamp format
        const now = Math.floor(Date.now() / 1000);

        // Calculate the difference between the assessment due date and the current date/time
        const timeDifference = parseInt(assessmentDueDate) - now;

        // Calculate the difference in days
        const daysDifference = Math.floor(timeDifference / 86400); // 86400 seconds in a day


        // Check if it is within the reassessment window

        if (daysDifference <= reassessmentWindow) {
            const dueDate = new Date(assessmentDueDate * 1000).toLocaleDateString('en-GB');
            return { text: `Due ${dueDate}`, color: "#37ADA7" };
        }

        // Check if it is within the warning window
        if (daysDifference <= warningWindow) {
            return { text: "Due Now", color: "#FFBF00" };
        }

        // It is past the due date
        return { text: "Due Now", color: "#EC4A4A" };
    }


    closeAssessmentDetailsModal = () => {
        const { assessment_necessary_object } = this.context;
        let { history } = this.props
        if (assessment_necessary_object.assessment_is_open_from_query_str) {
            history.push('/assessment-dashboard');
        } else {
            window.location.reload(true)
        }
    }
    render() {
        return (
            <div className='assessment-header-modal-header'>
                <div className='assessment-header-modal-header-text-pill-container'>
                    <div className='text-pills'>
                        <div className='mr-modal-header-text-container'>
                            <div className='assessment-header-modal-header-text'>
                                {this.state.headerText}
                            </div>
                            
                            <p className='assessment-header-modal-header-subtext' dangerouslySetInnerHTML={
                                { __html: this.state.subHeaderText }
                            }>
                                {/* {this.state.subHeaderText} */}
                            </p>
                        </div>
                        <div className='assessment-header-pills-container' style={{ backgroundColor: `${this.state.pills.color}` }}>
                            <span className='assessment-header-due-span'>{this.state.pills.text}</span>
                        </div>
                    </div>
                    {
                        !this.props.deactivate_close && 
                        <div className='action m-l-25px' onClick={this.closeAssessmentDetailsModal}>
                            <FontAwesomeIcon title="close" icon={solid('xmark')} size="2x" style={{cursor: "pointer"}} />
                        </div>
                    }
                    {
                        this.props.deactivate_close && 
                        <div className='action'>
                            <FontAwesomeIcon icon={solid('xmark')} size="2x" style={{cursor: "pointer", color : "#ddd"}} />
                        </div>
                    }
                </div>

                {/* Not for MVP right now */}
                {/* <div className='assessment-header-tiles-container'>
                    <div className='assessment-header-tile-card'>
                        <p className='assessment-header-tile-primary-text'>Time Assessed</p>
                        <p className='assessment-header-tile-secondary-text'>{this.state.assessment_status.TimeAssessed}</p>
                    </div>
                    <div className='assessment-header-tile-card'>
                        <p className='assessment-header-tile-primary-text'>First Assessed</p>
                        <p className='assessment-header-tile-secondary-text'> {this.state.assessment_status.FirstAssessed}</p>
                    </div>
                    <div className='assessment-header-tile-card'>
                        <p className='assessment-header-tile-primary-text'>Last Assessed</p>
                        <p className='assessment-header-tile-secondary-text'>{this.state.assessment_status.LastAssessed} </p>
                    </div>
                    <div className='assessment-header-tile-card'>
                        <p className='assessment-header-tile-primary-text'>Next Due</p>
                        <p className='assessment-header-tile-secondary-text'> {this.state.assessment_status.NextDue}</p>
                    </div>
                </div> */}
            </div>
        )
    }
}

export default withRouter(AssessmentHeader);
