import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, thin, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import ProgressBar from "./ProgressBar";
import AssessmentDataContext from "./AssessmentDataContext";
import moment from 'moment';
import Menu from '../Common/Menu';
import APICall from "../../Common/APICall";
// import TopicStatus from './TopicStatus';
import ClassTopicStatus from './ClassTopicStatus';

class TopicsRightPanel extends Component {

    constructor (props) {
        super(props);
        this.assessment_due_date = "";
        this.is_logged_in_user_overall_acessor = false;

        this.state = {
            topics: [],
            ready: false,
            assessment_final_click: false,
            clicked_topic: {},
            topicsMapArray: [],

        }
    }
    componentDidMount() {
        this.setComponentState();
    }
    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setComponentState();
        }
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props !== prevProps) {
    //         this.setComponentState();
    //     }

    // }



    setComponentState = async () => {
        const {
            assessment_final_click,
            assessment_necessary_object
        } = this.context;

        //console.log('assessment_necessary_object ==>', assessment_necessary_object);

        const assessment_due_date = assessment_necessary_object.assessment_due_date;
        const is_completed = assessment_necessary_object.assessment_is_completed;
        let assessment_is_overall_acessor = assessment_necessary_object.assessment_is_overall_acessor;

        this.assessment_due_date = assessment_due_date;
        this.is_logged_in_user_overall_acessor = assessment_is_overall_acessor;


        let isReadyForFinalCompletion = true;
        this.props.data.forEach(item => {
            const { topic_status } = item;
            if (
                topic_status !== "Reviewed" &&
                topic_status !== "Complete" &&
                topic_status !== "Review Required"
            ) {
                isReadyForFinalCompletion = false;
            }
        });

        try {
            const topicsArray = await Promise.all(
                this.props.data.map(topic => this.renderTopicItem(topic))
            );

            const topicsMapArray = topicsArray.filter(Boolean);

            // updateTopicStatusState(this.topic_status);
            this.setState(
                {
                    topicsMapArray,
                    topics: this.props.data,
                    ready: true,
                    assessment_final_click,
                },
                () => {
                    if (isReadyForFinalCompletion && !is_completed) {
                        this.readyForFinalCompletion();
                    }
                }
            );
        } catch (error) {
            console.error(error);
            // handle any errors that occur during the promise resolution
        }
    };



    static contextType = AssessmentDataContext;

    renderQuestion = (e, topic_id, topic_name, role_id, role_code) => {
        console.log('==========  renderQuestion called ==========');
        e.preventDefault();
        this.setState({ ready: false })
        let topics = this.state.topics;

        topics = topics.map(topic => ({
            ...topic,
            is_clicked: false,
            sections: topic.sections.map(section => ({
                ...section,
                questions: []
            }))
        }));

        topics[topics.findIndex(el => el.topic_id === topic_id && el.name === topic_name && el.role_id == role_id && el.role_code === role_code)]["is_clicked"] = true;
        const { updateState } = this.context;
        updateState(topics, topic_id, role_id, role_code);
    }

    showCompleteAssessmentPanel = (e) => {
        e.preventDefault();
        this.setState({ assessment_complete_click: true })
        let topics = this.state.topics;
        topics = topics.map(topic => ({
            ...topic,
            is_clicked: false,
            sections: topic.sections.map(section => ({
                ...section,
                questions: []
            }))
        }));

        const { assessmnentCompletion } = this.context;
        assessmnentCompletion(topics, true);
    }


    readyForFinalCompletion = async () => {
        let is_ready_for_final_comp = false;
        const { assessment_necessary_object } = this.context;
        if (assessment_necessary_object.assessment_cur_lane != "COMP_SMCR_REJECTED") {
            console.log('readyForFinalCompletion ==>', assessment_necessary_object);
            let curlane = assessment_necessary_object.assessment_cur_lane;
            if (curlane != "COMP_SMCR_FINAL_COMPLETION") {
                is_ready_for_final_comp = true;
                const postData = {
                    command: "assessments_details",
                    action: 'update_cur_lane_to_review_required',
                    assignment_id: assessment_necessary_object.assignment_id,
                    contact_id: assessment_necessary_object.assessment_assigned_to_id
                };


                const api = new APICall();
                let answer_response = await api.commandWithoutCallback(postData);
            }

            if (is_ready_for_final_comp) {
                const { updateCurlaneToFinalCompletion } = this.context;
                updateCurlaneToFinalCompletion();
            }
        }
    }


    renderTopicItem = async (topic) => {

        let topic_status = topic.topic_status
        let key = topic.topic_id + '_' + topic.name + '_' + topic.role_id;
        const { assessment_necessary_object } = this.context;
        const topic_visibility = topic.readonly ?  "topic-click-readonly" : (topic.is_clicked ? 'topic-panel-topic-active-item' : '');
        let topic_status_icon = null;

        if (topic_status === "Review Required") {
            topic_status_icon = (
                <div className="topic-status-icon-container">
                    <FontAwesomeIcon
                        className="topic-status-icon-container-icon"
                        title="status"
                        icon={solid("exclamation")}
                    />
                </div>
            );
        } else if (topic_status === "Complete" || topic_status === "Reviewed") {
            topic_status_icon = (
                <div className="topic-status-icon-container">
                    <FontAwesomeIcon
                        className="topic-status-icon-container-icon"
                        title="status"
                        icon={solid("check")}
                    />
                </div>
            );
        }

        return (
            <div key={topic.name} className={`topic-rpanel-topic-item ${topic_visibility}`}
                onClick={(e) => !topic.readonly && this.renderQuestion(e, topic.topic_id, topic.name, topic.role_id, topic.role_code)}>
                <div key={`${key}_name`}>
                    <span className='topic-rpanel-topic-name'>{topic.name}</span>
                    <span className='topic-rpanel-topic-name'><i className='gcnames-all-companies'>{topic?.gcs?.label}</i></span>
                    <span className='topic-rpanel-topic-status'>
                        {topic_status}
                    </span>
                </div>
                <div key={`${key}_status`} className='topic-rpanel-topic-action'>
                    <div className='topic-rpanel-avatar-icon'>
                        {topic_status_icon}
                    </div>
                    <div className='topic-rpanel-action-icon'>
                        {/* <Menu style={{ cursor: 'pointer' }}>
                            <nav>
                                <ul className="nav">
                                    <li key={1}>
                                        <a onClick={() => this.viewTopic()} >View</a>
                                    </li>
                                    <li key={2}>
                                        <a onClick={() => this.reAssignTopic()} >Re-assign</a>
                                    </li>
                                </ul>
                            </nav>
                        </Menu> */}
                    </div>
                </div>
            </div>
        );
    };


    render() {

        let topics = this.state.topics;

        let topic_comp_percentage = 0;
        let completeCount = 0;
        let reviewedCount = 0;

        topics.forEach((topic) => {
            let { topic_status } = topic;
            if (topic_status === "Complete") {
                completeCount++;
            } else if (topic_status === "Reviewed") {
                reviewedCount++;
            }
        });

        const totalItems = topics.length;
        const percentComplete = (completeCount / totalItems) * 100;
        const percentReviewed = (reviewedCount / totalItems) * 100;

        topic_comp_percentage = percentComplete + percentReviewed;



        return (
            <div className='topic-rp-topic-modal' >
                <div className='topic-rp-topic-header'>
                    <div className='topic-rpanel-topic-header'>
                        <span className='topic-rpanel-topic-count'>Topics ({this.state.topics.length})</span>
                        {/* NOT FOR MVP */}
                        {/* <Menu style={{ cursor: 'pointer' }}>
                            <nav>
                                <ul className="nav">
                                    <li key={1}>
                                        <a onClick={() => this.viewTopic()} >View</a>
                                    </li>
                                    <li key={2}>
                                        <a onClick={() => this.reAssignTopic()} >Re-assign</a>
                                    </li>
                                </ul>
                            </nav>
                        </Menu> */}
                    </div>

                    <div className='topic-rpanel-progress-stat'>{completeCount + reviewedCount}/{this.state.topics.length} completed</div>
                    <div className='topic-rpanel-progress-bar-container'>
                        <ProgressBar
                            bgcolor={'#1b3452'}
                            completed={topic_comp_percentage}>
                        </ProgressBar>
                    </div>
                </div>

                <div className='topic-rpanel-topic-list-container' key='topic-rpanel-topic-list-container'>
                    {this.state.topicsMapArray.map((element, index) => {
                        const { type, key, props } = element;
                        const modifiedProps = { ...props };
                        if (modifiedProps.children && Array.isArray(modifiedProps.children)) {
                            modifiedProps.children = modifiedProps.children.map((child) => {
                                if (typeof child === "object") {
                                    const { type, key, props } = child;
                                    return React.createElement(type, { key, ...props });
                                }
                                return child;
                            });
                        }
                        return React.createElement(type, { key: key || index, ...modifiedProps });
                    })}

                    {this.is_logged_in_user_overall_acessor && <div className={`topic-rpanel-topic-item ${this.state.assessment_final_click ? 'topic-panel-topic-active-item' : ''}`} key='assessment_completion' onClick={(e) => this.showCompleteAssessmentPanel(e)}>
                        <div>
                            <span className='topic-rpanel-topic-name'>Assessment Completion</span>
                            <span className='topic-rpanel-topic-status'>Due {moment.unix(this.assessment_due_date).format("DD/MM/YYYY")}</span>
                        </div>
                        <div className='topic-rpanel-topic-action'>
                            <div className='topic-rpanel-avatar-icon'>
                            </div>
                            <div className='topic-rpanel-action-icon'>

                                {/* <Menu style={{ cursor: 'pointer' }}>
                                    <nav>
                                        <ul className="nav">
                                            <li key={1}>
                                                <a onClick={(e) => this.showCompleteAssessmentPanel(e)} >View</a>
                                            </li>
                                            <li key={2}>
                                                <a onClick={() => this.reAssignTopic()} >Re-assign</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </Menu> */}
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        )
    }
}

export default TopicsRightPanel;
