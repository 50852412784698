import React from "react";
import CertSetupField from "./Partials/CertSetup/CertSetupField";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FieldStyleMapping } from "../Certifications/Pdf_Font_n_Icons/FontMapping";
import Store from "../../Common/Store";
import logo_base_64 from './../Certifications/Pdf_Font_n_Icons/MccLogoNew';
import YesNoBox from "../Common/YesNoBox";
import APICall from '../../Common/APICall.js';
import SmallSpinner from "../Common/SmallSpinner";
import { Select,Checkbox, Divider} from 'antd';


const MAX_FILE_SIZE = 512000;

class CertSetup extends React.Component{

    
    state = { 
        field_styles : {}, 
        uploaded_logo : null, 
        logo_position : null, 
        ready : false, 
        alertParam : null,
        group_companies: [],
        selected_group_company_id: 0,
        group_company_selection_options:[],
        checked: true
    }
    
    constructor(props) {
        super(props);
        this.fileRef = React.createRef();
        this.lastlogo = ""    
    }

    async componentDidMount(){
        let api = new APICall();
        const postData = {command: "get_group_companies"}
		let group_companies = await api.commandWithoutCallback(postData)
        //prepare group_company_selection_options and select default company (parent|first company)
        let group_company_selection_options = []
        let selected_group_company_id = 0;
        if(typeof group_companies?.userGroupCompanies!=='undefined' && group_companies?.hasGroupCompanies){
            group_companies.userGroupCompanies.map(item => {
                if(item?.isParent) selected_group_company_id = item?.id
                group_company_selection_options?.push({
                    label: item?.name,
                    value: item?.id,
                })
            });
            //pick first one from list in case there is no parent
            if(selected_group_company_id==0) selected_group_company_id = group_company_selection_options[0]?.value;
        }
        this.setState({group_companies, group_company_selection_options, selected_group_company_id, ready : false,});

        this.loadCertficateFieldStyles(selected_group_company_id)
    }
    loadCertficateFieldStyles = async (selected_group_company_id)=>{
        let api = new APICall();
        const postData = {command: "get_assess_cert_setup", selected_group_company_id: selected_group_company_id}
		const result = await api.commandWithoutCallback(postData)
        let field_styles = null;
        if(Object.keys(result?.result?.field_styles)?.length > 1){
            field_styles = result?.result?.field_styles;
        } 
        else{
            field_styles = FieldStyleMapping;
        }
        //get logo value from DB and set as last_logo for reference
        let logo_value = null;
        if(field_styles?.logo?.value === 'default'){
            logo_value = logo_base_64; // set default 
        } else if(field_styles?.logo?.value !='default'){
            logo_value = field_styles?.logo?.value; // set value from DB
        }
        this.lastlogo = logo_value;
        let logo_position = field_styles?.logo?.format;
        this.setState({field_styles , ready : true, uploaded_logo: logo_value, logo_position});
    }
    getFieldStyle = (field, field_style) => {
        let field_styles = JSON.parse(JSON.stringify(this.state?.field_styles));
        field_styles[field] = field_style;
        this.setState({ field_styles});
    }

    setShow = (field) => (e) => {
        e.preventDefault();
        let field_styles = JSON.parse(JSON.stringify(this.state.field_styles));
        field_styles[field] = {...field_styles[field], show : field_styles[field].show ? false : true}
        this.setState({ field_styles});
    }

    changeLogoPosition = (position) => (e) => {
        e.preventDefault();
        this.setState({logo_position : position});
    }

    handleUploadClick = (e) => {
        e.preventDefault();
        if(this.fileRef.current){
            this.fileRef.current.click();
        }
    }

    handleBrowse = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        let file_size = file?.size;
        if( file_size > MAX_FILE_SIZE){
            let alertParam = {
                "header" : "Alert",
                "subheader" : "File too large",
                "msg" : "Please upload file within 500kB",
                "closefunc" : this.closeYesNoBox,
                "buttons" : {
                    "OK" : {
                        "caption" : "OK",
                        "bgcolor" : "#24B3BF",
                        "color" : "#fff",
                        "callback" : this.closeYesNoBox
                    }
                }
            }
            this.setState({ alertParam })
            return;
        }

        const data = new FileReader();
    
        data.addEventListener('load', () => {
            const uploaded_logo = data.result;
            this.setState({ uploaded_logo });
        });
    
        data.readAsDataURL(file);
    }

    closeYesNoBox = () => {
        this.setState({alertParam : null});
    }

    closePage = (event) => {
		//event.preventDefault()
		let url = "/"
		window.location.replace(url);
	}

    handleChange = async(selected_group_company_id) =>{
        this.setState({selected_group_company_id: selected_group_company_id});
        this.loadCertficateFieldStyles(selected_group_company_id)
    }
    onChange = (e) =>{
        this.setState(({ checked }) => ({ checked: !checked }));
    }
    updateCertSetup = async (event) => {
        event.preventDefault()
        let api = new APICall()
        let field_styles = JSON.parse(JSON.stringify(this.state?.field_styles));
        if (this.state?.uploaded_logo !== this.lastlogo){
            field_styles.logo.value = this.state?.uploaded_logo;
        }
        field_styles.logo.format = this.state?.logo_position; 

        if( field_styles?.record_status === 'old'){
            if (this.state.uploaded_logo == this.lastlogo){ // no need to update. so remove logo value
                delete field_styles?.logo?.value;
            }
        }
        let selected_group_company_ids = []
        if(this.state?.group_companies?.hasGroupCompanies){
            if(this.state?.checked){
                selected_group_company_ids=this.state?.group_companies?.userGroupCompanies?.map(({ id }) => id)
            }else{
                //save for selected company
                selected_group_company_ids.push(this.state?.selected_group_company_id);
            }
        }
            const parentCmp=this.state.group_companies?.allCompanies?.find(c=>c?.isParent)
            const gcId= this.state?.selected_group_company_id == parentCmp?.id?0:this.state?.selected_group_company_id
                    // adding parent cmp id & gc id to cert config
                Object.keys(field_styles)?.forEach((field) => {
                  if (field != "record_status") {
                    field_styles[field]["company_id"] = parentCmp?.id;
                    field_styles[field]["gc_id"] = gcId;
                  }
                });

        //set selected group company id
		const postData = {command: "set_assess_cert_setup", cert_setup : field_styles, selected_group_company_ids: selected_group_company_ids}
		
        const result = await api.commandWithoutCallback(postData)
        if(result.error_code === 0){
            let alertParam = {
                "header" : "Saved",
                "subheader" : "",
                "msg" : "Certificate set-up saved successfully",
                "closefunc" : this.closeYesNoBox,
                "buttons" : {
                    "OK" : {
                        "caption" : "OK",
                        "bgcolor" : "#24B3BF",
                        "color" : "#fff",
                        "callback" : this.closeYesNoBox
                    }
                }
            }
            this.setState({ alertParam })
        }
    }

    render(){
        if(!this.state.ready)  {
			// return <div><CSLLoader /></div>
			return <div className='mc-general-empty-container'><SmallSpinner /></div>
		}
        return(
            <div className="mc-general-container">
                <div style={{fontSize : "15px", fontWeight : "600", marginTop : "15px"}}><span className='mc-general-label-header'>Certificate set-up</span></div>
                <div>Customise you Assessment Certificates by updating the sections below with your company logo and choices of text font, colour and size</div>
                {
                    this.state?.group_companies?.hasGroupCompanies &&  
                    (<div>
                    <div style={{marginTop : "15px", float: "left"}}>
                        <span style={{fontSize : "15px", fontWeight : "500"}}>Company - </span>
                        <span className="">
                            select the company you would like to configure the certificsate for
                        </span>
                    </div>
                    <div style={{marginTop : "15px", marginBottom: "15px", float: "left", width:"100%"}}>
                        <Select
                            defaultValue={this.state?.selected_group_company_id}
                            style={{ width: "40%" }}
                            onChange={this.handleChange}
                            options={this.state?.group_company_selection_options}
                        /> &nbsp;
                        <span style={{marginLeft: "60px"}}>
                            <Checkbox checked={this.state?.checked} onChange={this.onChange}></Checkbox> &nbsp; These settings apply to the certificates for all companies
                        </span>
                    </div>
                        <Divider style={{borderBlockStart: "2px solid grey"}}/>
                </div>)
                }
                
                <div className="mc-cert-setup-container">
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Logo</span></div>
                    </div>
                    <div className="mc-cert-setup-logo-selection-container">
                        <div className="mc-cert-setup-logo-selection-option-container">
                            <div className="mc-cert-setup-logo-selection-option">
                                {
                                    (()=>{
                                        if(this.state.logo_position === 'left') return <FontAwesomeIcon icon={solid('circle')} className="mc-cert-setup-radio" />
                                        else return <FontAwesomeIcon icon={regular('circle')} className="mc-cert-setup-radio" onClick={this.changeLogoPosition('left')} />
                                    })()
                                }
                                <div>Left</div>
                            </div>
                            <div className="mc-cert-setup-logo-selection-option">
                                {
                                    (()=>{
                                        if(this.state.logo_position === 'middle') return <FontAwesomeIcon icon={solid('circle')} className="mc-cert-setup-radio" />
                                        else return <FontAwesomeIcon icon={regular('circle')} className="mc-cert-setup-radio" onClick={this.changeLogoPosition('middle')} />
                                    })()
                                }
                                <div>Middle (Default)</div>
                            </div>
                            <div className="mc-cert-setup-logo-selection-option">
                                {
                                    (()=>{
                                        if(this.state.logo_position === 'right') return <FontAwesomeIcon icon={solid('circle')} className="mc-cert-setup-radio" />
                                        else return <FontAwesomeIcon icon={regular('circle')} className="mc-cert-setup-radio" onClick={this.changeLogoPosition('right')} />
                                    })()
                                }
                                <div>Right</div>
                            </div>
                        </div>
                        <input type="file" id="logo-input" style={{display:"none"}} accept=".jpg,.png" onChange={this.handleBrowse}/>
                        <label className="mc-cert-setup-upload" htmlFor="logo-input">Upload Logo</label>
                        <div className="mc-cert-setup-logo-container" style={{backgroundImage : `url(${this.state.uploaded_logo})`}}/>
                    </div>
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Certificate - </span>change the text and appearance of the Certificate Title</div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="certificate" field_style={this.state.field_styles.certificate} getFieldStyle={this.getFieldStyle}/>
                    </div>
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Assessment Name - </span>change the appearance of the Assessment Name</div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="assessment_name" field_style={this.state.field_styles.assessment_name} getFieldStyle={this.getFieldStyle}/>
                    </div>
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Employee Name - </span>change the appearance of the Employee's Name</div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="emp_name" field_style={this.state.field_styles.emp_name} getFieldStyle={this.getFieldStyle}/>
                    </div>
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Job Title - </span>change the appearance of the Employee's Job Title</div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="job_title" field_style={this.state.field_styles.job_title} getFieldStyle={this.getFieldStyle}/>
                    </div>
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Declaration Text - </span>change the text and appearance of the Declaration Text</div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="dec_text" field_style={this.state.field_styles.dec_text} getFieldStyle={this.getFieldStyle}/>
                    </div>
                    <div className="mc-cert-setup-label-container-flex">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Role ID's - </span>change the appearance of the Role ID's</div>
                        <div className="mc-cert-setup-label">
                            Do not show Role IDs 
                            { this.state.field_styles.role_id.show === true && <FontAwesomeIcon icon={regular('square')} className="mc-cert-setup-icon" onClick={this.setShow('role_id')}/>}
                            { this.state.field_styles.role_id.show === false && <FontAwesomeIcon icon={regular('square-check')} className="mc-cert-setup-icon" onClick={this.setShow('role_id')}/>}
                        </div>
                    </div>
                    {
                        this.state.field_styles.role_id.show === true &&
                        <div className="mc-cert-setup-field-container">
                            <CertSetupField field="role_id" field_style={this.state.field_styles.role_id} getFieldStyle={this.getFieldStyle}/>
                        </div>
                    }
                    <div className="mc-cert-setup-label-container-flex">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Role Title's - </span>change the appearance of the Role Titles</div>
                        <div className="mc-cert-setup-label">
                            Do not show Role Titles 
                            { this.state.field_styles.role_title.show === true && <FontAwesomeIcon icon={regular('square')} className="mc-cert-setup-icon" onClick={this.setShow('role_title')}/>}
                            { this.state.field_styles.role_title.show === false && <FontAwesomeIcon icon={regular('square-check')} className="mc-cert-setup-icon" onClick={this.setShow('role_title')}/>}
                        </div>
                    </div>
                    {
                        this.state.field_styles.role_title.show === true &&
                        <div className="mc-cert-setup-field-container">
                            <CertSetupField field="role_title" field_style={this.state.field_styles.role_title} getFieldStyle={this.getFieldStyle}/>
                        </div>
                    }
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Edit the text and appearance for the message when no roles were assessed</span></div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="no_role" field_style={this.state.field_styles.no_role} getFieldStyle={this.getFieldStyle}/>
                    </div>
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Assessor Name - </span>change the appearance of the Assessor's name</div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="assessor_name" field_style={this.state.field_styles.assessor_name} getFieldStyle={this.getFieldStyle}/>
                    </div>
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Assessment Date - </span>change the appearance of the Assessment Date</div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="assessment_date" field_style={this.state.field_styles.assessment_date} getFieldStyle={this.getFieldStyle}/>
                    </div>
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">"Certified By" Label - </span>change the text and appearance</div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="cert_by" field_style={this.state.field_styles.cert_by} getFieldStyle={this.getFieldStyle}/>
                    </div>
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">"Date" Label - </span>change the text and appearance</div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="date" field_style={this.state.field_styles.date} getFieldStyle={this.getFieldStyle}/>
                    </div>
                </div>
                <div className='mc-general-footer'>
                    <button className='mc-general-cancel-button' onClick={this.closePage}>Cancel</button>
                    <button className='mc-general-save-button' onClick={this.updateCertSetup}>Save</button>
                </div>
                { this.state.alertParam !== null && <YesNoBox alertParam={this.state.alertParam} />}
            </div>
        )
    }
}

export default CertSetup;