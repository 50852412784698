import React, { Component } from 'react';
import Tabs from './Tabs'
import AssessmentAttestation from './AssessmentAttestation';
import AssessmentFollowUpTasks from './AssessmentFollowUpTasks';
import AssessmentAttachment from './AssessmentAttachment';
import AssessmentCertification from './AssessmentCertification';
import AssessmentRoles from './AssessmentRoles';
import AssessmentDataContext from "./AssessmentDataContext";
import Store from '../../Common/Store.js';


class AssessmentBody extends Component {
    state = {
        assessment_certificate_generated : false,
        user_role: Store.getStoreData('role'),
        logged_in_user: ''
    }

    componentDidMount(){
        const sldata = JSON.parse(localStorage.getItem("site_layout"));
        const logged_in_user = sldata["contact_id"];
        this.setState({assessment_certificate_generated : this.props.assessment_certificate_generated, logged_in_user});
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps) this.setState({assessment_certificate_generated : this.props.assessment_certificate_generated});
    }
    
    static contextType = AssessmentDataContext;


    getAssessmentTopicDetails = () => {

    }

    getFollowupTasks = () => {

    }

    getAttachments = () => {

    }

    getCertifications = () => {

    }

    getRoles = () => {

    }

    render() {
        let certification_visibility = false;
        const { assessment_necessary_object, data, assessment_certificate_generated } = this.context;
        let { assignment_id, assessment_id, assessment_name, assessment_gcs, assessment_assigned_to_id } = assessment_necessary_object
        certification_visibility = data.every(obj => obj.topic_status === "Complete" || obj.topic_status === "Reviewed") && this.state.assessment_certificate_generated === true;
        if(!assessment_necessary_object.assessment_is_overall_acessor && assessment_assigned_to_id != this.state.logged_in_user){
            if(certification_visibility && this.state.user_role =='team'){
                certification_visibility = false;
            }
        }
        return (
            <div>
                <div>
                    <Tabs>
                        <div className='assessment-body-tab-panel' key={1} label="Assessment" visibility={true} onClick={this.getAssessmentTopicDetails}>
                            <AssessmentAttestation />
                        </div>

                        {/* <div className='assessment-body-tab-panel' key={2} label="Follow up Tasks" visibility={true} onClick={this.getFollowupTasks}>
                            <AssessmentFollowUpTasks />
                        </div>

                        <div className='assessment-body-tab-panel' key={3} label="Attachments" visibility={true} onClick={this.getAttachments}>
                            <AssessmentAttachment />
                        </div> */}

                        <div className='assessment-body-tab-panel' key={4} label="Certificate" visibility={certification_visibility} onClick={this.getCertifications}>
                            <AssessmentCertification assessment_name={assessment_name} assignment_id={assignment_id} assessment_id={assessment_id} assessment_gcs={assessment_gcs} />
                        </div>

                        {/* <div className='assessment-body-tab-panel' key={5} label="Roles" visibility={true} onClick={this.getRoles}>
                            <AssessmentRoles />
                        </div> */}
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default AssessmentBody
