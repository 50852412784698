import React, { useEffect, useState } from "react";
import { Modal, Button, Table } from "antd";
import APICall from "../../../Common/APICall";
import "./gcRoles.css";

const ROLE_STATUS = Object.freeze({
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
  PENDING_ACCEPTANCE: "Pending Acceptance",
  ASSIGNED: "Assigned",
});

const gcColumns = [
  {
    title: "GROUP COMPANY",
    dataIndex: "companyName",
    key: "companyName",
  },
  {
    title: "ROLES",
    dataIndex: "numOfRoles",
    key: "numOfRoles",
  },
];
const statusColumns = [
  {
    title: "ROLE ID",
    dataIndex: "role_code",
    key: "role_code",
  },
  {
    title: "GROUP COMPANY",
    dataIndex: "companyName",
    key: "companyName",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
  },
];
const roleTypeColumns = [
  {
    title: "ROLE TYPE",
    dataIndex: "roleType",
    key: "roleType",
  },
  {
    title: "GROUP COMPANY",
    dataIndex: "companyName",
    key: "companyName",
  },
  {
    title: "ROLES",
    dataIndex: "numOfRoles",
    key: "numOfRoles",
  },
];
const certManagerColumns = [{
  title: "ROLE ID",
  dataIndex: "role_code",
  key: "role_code",
},
{
  title: "GROUP COMPANY",
  dataIndex: "companyName",
  key: "companyName",
},
{
  title: "CERT MANAGER",
  dataIndex: "certMgrName",
  key: "certMgrName",
},]



export const CompanyWiseRoles = ({ open, close, modelConfig }) => {
  const [config, setConfig] = useState({ columns: [], dataSource: [], title: null, subTitle: null, rowKey: "index" })

  useEffect(() => {
    const api = new APICall();
    async function fetchData() {
      const payload = {
        command: "personnel",
        action: getPayloadAction(),
        user_id: modelConfig?.employeeId,
      };
      const apiResults = await api.commandWithoutCallback(payload);
      if (modelConfig?.modelType == "groupCompanies") {
        groupByGcName(apiResults?.result);
      } else if (modelConfig?.modelType == "roleTypes") {
        configRoleTypeModel(apiResults)
      } else if (modelConfig?.modelType == "status") {
        configStatusModel(apiResults);
      } else if (modelConfig?.modelType == "certManager") {
        configCertManagerModel(apiResults)
      }

    }
    fetchData();
  }, []);

  const getPayloadAction = () => {
    if (["groupCompanies", "status"].includes(modelConfig?.modelType)) {
      return "get_user_roles"
    } else if (modelConfig?.modelType === "roleTypes") {
      return "distinct_role_types"
    }
    return "get_user_smcr_rolescolor_and_cert_manager"
  }

  const configCertManagerModel = (apiResults) => {
    const hasGC = JSON.parse(localStorage.getItem('has_gc'));
    const dataSource = apiResults?.result?.map((role, index) => ({ ...role, index, companyName: role?.gc_id ? role?.gcName : role?.companyName }))
    setConfig((state) => ({
      ...state, dataSource: dataSource, 
      columns: hasGC?certManagerColumns:certManagerColumns?.filter(c=>c?.key !== "companyName"),
      title: "Employee's Certificate Managers",
      subTitle: `${modelConfig?.employeeName} has roles &
      Responsibilities at the following Certificate Managers`,

    }))
  }

  const configRoleTypeModel = (apiResults) => {
    const hasGC = JSON.parse(localStorage.getItem('has_gc'));
    const dataSource = apiResults?.result?.map((role, index) => ({ ...role, index, companyName: role?.gc_id ? role?.gcName : role?.companyName }))
    setConfig((state) => ({
      ...state, dataSource: dataSource, 
      columns: hasGC?roleTypeColumns:roleTypeColumns?.filter(c=>c?.key !== "companyName"),
      title: "Employee's Role Types",
      subTitle: `${modelConfig?.employeeName} has roles &
      Responsibilities at the following Role Types`,

    }))
  }

  const configStatusModel = (apiResults) => {
    const dataSource = apiResults?.result?.map((role, index) => ({
      ...role,
      index, companyName: role?.gc_id ? role?.gcName : role?.companyName,
      status: getRoleStatus(role)
    }))
    const hasGC = JSON.parse(localStorage.getItem('has_gc'));
    setConfig(state => ({
      ...state,
      columns: hasGC?statusColumns:statusColumns?.filter(c=>c?.key !== "companyName"),
      dataSource: dataSource,
      title: "Employee's Role Wise status",
      subTitle: `${modelConfig?.employeeName} has roles &
                 Responsibilities at the following Role Status`
    }))
  }

  const getRoleStatus = (role) => {
    if (role?.is_accepted === 1) {
      return ROLE_STATUS.ACCEPTED
    } else if (role?.is_rejected === 1) {
      return ROLE_STATUS.REJECTED
    } else if (role?.for_acceptance === 1) {
      return ROLE_STATUS.PENDING_ACCEPTANCE
    }
    return ROLE_STATUS.ASSIGNED
  }

  const groupByGcName = (roles) => {
    const groupJson = roles?.reduce((acc, role) => {
      let key = role?.gc_id ? role?.gcName : role?.companyName;
      if (!acc[key]) {
        acc[key] = 0;
      }
      acc[key] = acc[key] + 1;
      return acc;
    }, {});
    const gcRolesCount = [];
    Object.keys(groupJson)?.forEach((key) => {
      gcRolesCount.push({
        companyName: key,
        numOfRoles: groupJson[key],
      });
    });
    setConfig((state) => ({
      ...state, dataSource: gcRolesCount, columns: gcColumns,
      title: "Employee's Group Company Roles & Responsibilities",
      subTitle: `${modelConfig?.employeeName} has roles &
      Responsibilities at the following Group Comapanies`
    }))
  };

  return (
    <>
      <Modal
        className="gcRoles-model-section"
        title={
          <div className="add-role-modal-header-container">
            <div className="add-role-modal-header-text-container">
              <div className="add-role-modal-header-title">
                {config?.title}
              </div>
              <div className="add-role-modal-header-subtitle">
                {config?.subTitle}
              </div>
            </div>
          </div>
        }
        open={open}
        onOk={close}
        footer={[
          <Button
            key="submit"
            className="gc-close-btn"
            type="primary"
            onClick={close}
          >
            Ok
          </Button>,
        ]}
        onCancel={close}
        width={"45%"}
      >
        <div>
          <Table
            columns={config?.columns}
            pagination={{ defaultPageSize: 5 }}
            dataSource={config?.dataSource}
            rowKey={config?.rowKey}
          />
        </div>
      </Modal>
    </>
  );
};
