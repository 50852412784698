import React from 'react';
import styled from 'styled-components';
import * as Style from '../Common/StyledComponents';
import AlertBox from '../Common/AlertBox.js';
import { FaUsers } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import Store from '../../Common/Store.js';
import CSLTable from '../Common/CSLTable';
//import Menu from '../Common/Menu.js';
import APICall from '../../Common/APICall.js';
import AssignmentConfimationModal from './AssignmentConfirmationModal';
import AssignmentDateRangeModal from './AssignmentSectionDateRangeModal';
import "../Common/YesNoBox.css";
import { confirm } from "react-confirm-box";
import OverlapReasonModal from './OverlapReasonModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, thin, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

const moment = require('moment');

const NotesDiv = styled.div`
    margin: 1% 3% 3% 3% ;
    color: #337CBE;
    // font-size: 11px;
    padding:10px;
    border: 1px solid;
    border-radius: 4px;
    box-sizing: border-box;
    background: #E7F0F7;
    font-weight: 600;
`;

const ModalInnerHeader = styled.div`
	background: #11ABA6;
    height: 30px;
    margin: 1% 3% 0px 3% ;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color:#FFFFFF;
    padding: 5px;
    box-sizing: border-box;
`;

const ModalInnerBody = styled.div`
	background: #FFFFFF;
    height: auto;
    margin: 0px 3% 3% 3% ;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 5px;
    box-sizing: border-box;
	position : relative;
`

class AssignmentSectionNew extends React.Component {

    state = {
		role_list: [],
        alert_param: null,
        mode: 'add',
        show_assignment_date_range_modal: false,
		show_assignment_confirmation_modal : false,
        role_info: {},
        list_contacts: [],
        assignment_temp_data: [],
		is_shown_confirm_assignment: false,
        assign_error_msg: null,
        assign_warning_msg: null,
		show_sub_menu : false,
		date_modal_data : {
			id : 0,
			mode : "add",
			action : "insert",
			contact_id : 0,
			role_id : null,
			role_type_id : null,
			role_creation_date : null,
			assigned_date : null,
			effective_from_date : null,
			effective_till_date : null,
			userGroupCompanies : null
		},
		show_multi_user_title : false,
		multi_user_icon_index : 0,
		reason_to_show_for_id : 0,
		show_sad_reason_modal : false,
		sad_reason_data : {},
		ready : false
	};

	componentDidMount() {
		this.initialiseState();
	}

	// componentDidUpdate(prevProps) {
	// 	if (prevProps !== this.props) {
	// 		this.initialiseState();
	// 	}
	// }

    initialiseState = () =>{
		
		let list_contacts = this.props.list_contacts;
		let role_info = JSON.parse(JSON.stringify(this.props.role_info));
		role_info.role_id = role_info.role_id === 0 ? Store.getStoreData("newly_added_role_id") : role_info.role_id;
		role_info.assignment_info = [];
        this.setState({ role_info : role_info, list_contacts : list_contacts, mode : this.props.mode, ready : true})
		let api = new APICall();
		let postData = { "command": "get_role_assignment", "role_id" : role_info.role_id };
		api.command(postData, this.processRoleAssignment);
    }

	processRoleAssignment = async (result) =>{
		let assignment_data = [];
		result.forEach((item) => {
			let cur_assigned_to = "single"
			for(let i=0; i < result.length; i++){
				if(result[i].id !== item.id){
					if(item.effective_from_date >= result[i].effective_from_date && item.effective_from_date <= result[i].effective_till_date ||
						item.effective_till_date >= result[i].effective_from_date && item.effective_till_date <= result[i].effective_till_date ||
						item.effective_from_date >= result[i].effective_from_date && item.effective_till_date <= result[i].effective_till_date ||
						item.effective_from_date < result[i].effective_from_date && item.effective_till_date > result[i].effective_till_date){
						cur_assigned_to = "multiple";
						break;
					}
				}
			}
			const groupCompany = this.props?.userConfig?.allCompanies?.find(cmp => item?.gc_id == 0 ? cmp?.id == item?.company_id : cmp?.id == item?.gc_id)?.name
			let elem = {
				id : item.id,
				contact_id : item.contact_id,
				role_id : item.role_id,
				role_type_id : item.role_type_id,
				role_creation_date : item.created_at,
				assigned_date : item.assigned_date,
				effective_from_date : item.effective_from_date,
				effective_till_date : item.effective_till_date,
				is_ovr : item.is_ovr === 0 ? false : true,
				ovr_reason : item.ovr_reason === null ? "" : item.ovr_reason,
				cur_assigned_to : cur_assigned_to,
				context_visible : false,
				groupCompany : groupCompany,
				companyId: item?.gc_id == 0 ? item?.company_id : item?.gc_id,
				gcId: item?.gc_id
			};
			assignment_data.push(elem);
		})
		let role_info = JSON.parse(JSON.stringify(this.state.role_info))
		role_info.assignment_info = assignment_data;
		this.setState({ assignment_data, role_info });
	}

	toDateObject = (date) =>{
		let date_str = (date%100).toString() + "-" + Math.floor((date%10000)/100).toString() + "-" + Math.floor(date/10000).toString();
		// console.info("date_str:", date_str);
		let date_obj = moment(date_str, 'DD-MM-YYYY').toDate();
		// console.info("date_obj:", date_obj);
		return date_obj;
	}

	increase1day = (date) =>{
        let date_str = (date%100).toString() + "-" + Math.floor((date%10000)/100).toString() + "-" + Math.floor(date/10000).toString();
        let date_obj = moment(date_str, 'DD-MM-YYYY').add(1, 'days');
        let ret = moment(date_obj).format('YYYY') + moment(date_obj).format("MM") + moment(date_obj).format("DD")
        ret = parseInt(ret)
        // console.info("date_obj:", date_obj);
        return ret;
    }

	openEditViewAssignmentModal = (a,b) => {

	}

	closeConfirmAssignmentModal = () => {
		// let date_modal_data = JSON.parse(JSON.stringify(this.state.date_modal_data))
		// date_modal_data.assigned_date = date_range_data.assigned_date;
		// date_modal_data.effective_from_date = date_range_data.effective_from_date;
		// date_modal_data.effective_till_date = date_range_data.effective_till_date;
		// date_modal_data.contact_id = date_range_data.contact_id;
		// console.log("CLosing : ",date_modal_data);
		this.setState({show_assignment_confirmation_modal : false})
	}

	setSubMenu = (a,b,c) => {

	}

	openAddAssignmentModal = (mode, id) => (e) => {
		e.preventDefault();
		let date_modal_data = {}
		if(mode === "add"){
			date_modal_data = {
				id : 0,
				mode : 'add',
				action : "insert",
				contact_id : 0,
				role_id : this.state.role_info.role_id,
				role_code : this.state.role_info.role_code,
				role_type_id : this.state.role_info.role_type_id,
				role_creation_date : this.state.role_info.created_at,
				assigned_date : null,
				effective_from_date : -1,
				effective_till_date : parseInt(process.env.REACT_APP_NO_END_DATE)
			}
		}else if(mode === "edit"){
			
			let assignment_obj = this.state.role_info.assignment_info.find(item => item.id === id)
			date_modal_data = {
				id : id,
				mode : 'edit',
				action : "nochange",
				contact_id : assignment_obj.contact_id,
				role_id : assignment_obj.role_id,
				role_type_id : assignment_obj.role_type_id,
				role_creation_date : this.state.role_info.created_at,
				assigned_date : assignment_obj.assigned_date,
				effective_from_date : assignment_obj.effective_from_date,
				effective_till_date : assignment_obj.effective_till_date,
				companyId : assignment_obj?.companyId
			}
		}
		this.setState({ show_assignment_date_range_modal : true, date_modal_data });
	}

	closeAddAssignmentModal = () => {
		// let date_modal_data = JSON.parse(JSON.stringify(this.state.date_modal_data))
		// date_modal_data.assigned_date = date_range_data.assigned_date;
		// date_modal_data.effective_from_date = date_range_data.effective_from_date;
		// date_modal_data.effective_till_date = date_range_data.effective_till_date;
		// date_modal_data.contact_id = date_range_data.contact_id;
		// console.log("CLosing : ",date_modal_data);
		this.setState({ show_assignment_date_range_modal : false});
	}

	deleteAssignment = (id) => async (event) => {
		event.preventDefault();
		//debugger;
		let assignment_data = JSON.parse(JSON.stringify(this.state.assignment_data));
		let assignment_obj = assignment_data.find(item => item.id === id);
		let user_name = this.state.list_contacts.find(item => item.ID === assignment_obj.contact_id.toString()).ContactName;
		const message = {
			header : "Are you sure?",
			subheader : "You are going to delete a role assignment",
			msg : `Are you sure, you want to delete the following assignment:<br><br>${user_name} from ${assignment_obj.effective_from_date !== -1 ? moment(assignment_obj.effective_from_date.toString(), "YYYYMMDD").format("DD/MM/YYYY") : "Regime Start"} to ${assignment_obj.effective_till_date !== parseInt(process.env.REACT_APP_NO_END_DATE) ? moment(assignment_obj.effective_till_date.toString(), "YYYYMMDD").format("DD/MM/YYYY") : "No End Date"}`,
			buttons : {
				"2" : {
					caption : "Yes",
					bgcolor : "#0000ff",
					color : "#fff",
				},
				"1" : {
					caption : "No",
					bgcolor : "#eee",
					color : "#555",
				}
			}
		}
		let i=-1;
		const options = {
			render: (message, onConfirm, onCancel) => {
				return (
					<div className="YesNoBoxContainer">
						<div className="YesNoBoxHeader">
							<div className="YesNoBoxHeaderText">{message.header}</div>
							<div className="YesNoBoxSubheaderText">{message.subheader}</div>
						</div>
						<div className="YesNoBoxBody">
							<div className="YesNoBoxAlertImage" />
							<div className="YesNoBoxMsg" dangerouslySetInnerHTML={{__html: message.msg}} style={{fontSize : message.msg.length > 200 ? "12px" : message.msg.length >= 100 ? "14px" : "16px"}}></div>
						</div>
						<div className="YesNoBoxFooter">
							{
								Object.keys(message.buttons).map(key => {
									i++;
									return(
										<button className="FooterButton" style={{backgroundColor : message.buttons[key].bgcolor, color : message.buttons[key].color}} onClick={ Object.keys(message.buttons).length === 2 ? i === 0 ? onCancel : onConfirm : onConfirm}>{ message.buttons[key].caption }</button>
									)
								})
							}
						</div>
					</div>
				)
			}
		}
		const result = await confirm(message, options)
		
		if(result === false) return;
		assignment_obj["action"] = "delete";
		this.saveAssignment(assignment_obj);
	}

	saveAssignment = async (assignment_data) => {
		
		let api = new APICall();
		let postData = { "command": "save_role_assignment", "assignment_data" : assignment_data };
		let result = await api.commandWithoutCallback(postData, this.processRoleAssignment);
		
		this.setState({ show_assignment_confirmation_modal : false, show_assignment_date_range_modal : false});
		if(result.error_code === 0) this.initialiseState();
	}

	getAssignedInfo = (date_range_data) => {
		
		// debugger;
		date_range_data = JSON.parse(JSON.stringify(date_range_data));
		let assignment_temp_data = [];
		assignment_temp_data.push(date_range_data);
		// let date_modal_data = JSON.parse(JSON.stringify(this.state.date_modal_data))
		// date_modal_data.assigned_date = date_range_data.assigned_date;
		// date_modal_data.effective_from_date = date_range_data.effective_from_date;
		// date_modal_data.effective_till_date = date_range_data.effective_till_date;
		// date_modal_data.contact_id = date_range_data.contact_id;
		this.setState({ assignment_temp_data , show_assignment_confirmation_modal : true});
	}

	showSadReasonTitle = (id,index) => (event) => {
		event.preventDefault();
	
		if(index !== this.state.multi_user_icon_index) {
			this.setState({show_multi_user_title : false})
		}
		let multi_user_icon_index = index;
		this.setState({ multi_user_icon_index, show_multi_user_title : index === this.state.multi_user_icon_index ? this.state.multi_user_icon_index === true ? false : true : true, reason_to_show_for_id : id })
	}

	hideSadReasonTitle = (event) => {
		event.preventDefault();
		this.setState({show_multi_user_title : false})
	}

	showSadReasonModal = (event) =>{
        event.preventDefault();
		let id = this.state.reason_to_show_for_id;
	
		let role_info = JSON.parse(JSON.stringify(this.state.role_info));
		let date_range = role_info.shared_date_ranges.concat(role_info.divided_date_ranges);
		let assignment_data = role_info.assignment_info.find(item => item.id === id)
		let date_range_reason_data = null;
		if(assignment_data.is_ovr === true){
				date_range_reason_data =
				{
					from : assignment_data.effective_from_date,
					to : assignment_data.effective_till_date,
					reason : assignment_data.ovr_reason,
					reason_type : "ovr"
				}
		}
		if(date_range_reason_data === null){
			for(let i=0; i<= date_range.length-1; i++){
				if(assignment_data.effective_from_date >= date_range[i].from && assignment_data.effective_till_date <= date_range[i].to){
					date_range_reason_data =
					{
						from : date_range[i].from,
						to : date_range[i].to,
						reason : date_range[i].reason,
						reason_type : "sad"
					}
					break;
				}
			}
		}
		if(date_range_reason_data === null){
			let alert_param = {
				title: 'Multiple Assignment', message: 'Other employees are sharing this role with this employee. Please check the assignments of other employees to see the reason why this is the case.', ok_text: 'Ok', confirm: false,
				alertHandler: this.closeAlert, stack: {}
			}
			this.setState({ alert_param });
		}
	
        if(date_range_reason_data !== null)
        	this.setState({ show_sad_reason_modal : true, sad_reason_data : date_range_reason_data, show_multi_user_title : false});
    }

    closeSadReasonModal = () =>{
        let show_sad_reason_modal = false;
        this.setState({ show_sad_reason_modal});
    }

	closeAlert = () => {
		this.setState({ alert_param : null })
	}
	tableData = () => {
		// debugger;
		let ret = { data: [], columns: [] };
		ret.columns = [
			{ Header: 'Name', accessor: 'assigned_to_name', minWidth: 50, headerStyle: { textAlign: 'left' } },
			{ Header: 'Group Companies', accessor: 'groupCompany', minWidth: 100, headerStyle: { textAlign: 'left' }, 
            	Cell: row => <span title={row?.value}>{row?.value}</span> },
			{ Header: 'Assigned', accessor: 'assigned_date', minWidth: 50, headerStyle: { textAlign: 'left' } },
			{ Header: 'From', accessor: 'assigned_fromdate', minWidth: 50, headerStyle: { textAlign: 'left' } },
			{ Header: 'To', accessor: 'assigned_todate', minWidth: 50, headerStyle: { textAlign: 'left' } },
			{
				Header: '',
				minWidth: 25,
				filterable: false,
				Cell: row => (
					<div style={{display: "flex", justifyContent: "flex-end", columnGap: "15px", paddingRight: "15px"}}>
						{
							row.original.cur_assigned_to === 'single' ?
							<FaUser /> :
							<div className='multi-user-icon-container' onMouseEnter={this.showSadReasonTitle(row.original.id, row.viewIndex)}>
								<FaUsers />
							</div>
						}
						{ Store.getStoreData('role') === 'admin_manager' && <FontAwesomeIcon className="table-ellipsis" icon={regular('ellipsis-vertical')} onClick={this.toggleContextMenu(row.original.id)}/>}
						{
							row.original.context_visible === true &&
							<div className="table-context-menu" style={{right : "27px"}} onMouseLeave={this.closeContextMenu(row.original.id)}>
								<div className="table-context-menu-item" style={{textAlign : "justify"}} onClick={this.openAddAssignmentModal('edit', row.original.id)}>Edit</div>
								<div className="table-context-menu-item" style={{textAlign : "justify"}} onClick={this.deleteAssignment(row.original.id)}>Revoke</div>
							</div>
						}
					</div>
				)
			}];
		if (this.state.role_info.assignment_info.length !== 0) {

			this.state.role_info.assignment_info.forEach((item) => {
				////debugger;
				// let _assigned_date = item.assigned_date == null ? '-' : Utils.isTimestamp(item.assigned_date) ? moment.unix(item.assigned_date).format("DD-MM-YYYY").toString() : moment(new Date(item.assigned_date).toString()).format("DD-MM-YYYY").toString()
				// let _assigned_fromdate = item.assigned_fromdate == null ? '-' : Utils.isTimestamp(item.assigned_fromdate) ? moment.unix(item.assigned_fromdate).format("DD-MM-YYYY").toString() : moment(new Date(item.assigned_fromdate).toString()).format("DD-MM-YYYY").toString()
				// let _assigned_todate = item.assigned_todate == null ? '-' : Utils.isTimestamp(item.assigned_todate) ? moment.unix(item.assigned_todate).format("DD-MM-YYYY").toString() : moment(new Date(item.assigned_todate).toString()).format("DD-MM-YYYY").toString()
				let elem = {
					id: item.id,
					assigned_to_name: (this.state.list_contacts.find(con => parseInt(con.ID) === item.contact_id)).ContactName,
					groupCompany: item?.groupCompany,
					assigned_date: moment(this.toDateObject(item.assigned_date)).format("DD-MM-YYYY").toString(),
					assigned_fromdate: item.effective_from_date === -1 ? "Regime Start" : moment(this.toDateObject(item.effective_from_date)).format("DD-MM-YYYY").toString(),
					assigned_todate: item.effective_till_date === parseInt(process.env.REACT_APP_NO_END_DATE) ? item.effective_from_date < parseInt(moment().format("YYYYYMMDD")) ? "Present" : "Not Set" : moment(this.toDateObject(item.effective_till_date)).format("DD-MM-YYYY").toString(),
					from : item.effective_from_date,
					to : item.effective_till_date,
					cur_assigned_to : item.cur_assigned_to,
					context_visible : item.context_visible
				};
				ret.data.push(elem);
			})
		}
	
		return ret;
	}

		
	toggleContextMenu = (ass_id) => (event) => {
        event.preventDefault();
     
        let role_info = JSON.parse(JSON.stringify(this.state.role_info));
      
        for(let i=0; i<role_info.assignment_info.length; i++){
		
            if(role_info.assignment_info[i].id === parseInt(ass_id)) {
                // role_info.assignment_info[i].context_visible = role_info.assignment_info[i].context_visible === true ? false : true;
                role_info.assignment_info[i].context_visible = true;
                //break;
            }else{
				role_info.assignment_info[i].context_visible = false;
			}
        }
       
        this.setState({role_info : JSON.parse(JSON.stringify(role_info))})
    }

	closeContextMenu = (ass_id) => (event) => {
        event.preventDefault()
      
        let role_info = JSON.parse(JSON.stringify(this.state.role_info));
        for(let i=0; i < role_info.assignment_info.length; i++){
            if(role_info.assignment_info[i].id === parseInt(ass_id)){
                role_info.assignment_info[i].context_visible = false;
                break;
            }
        }
        this.setState({ role_info : JSON.parse(JSON.stringify(role_info)) })
    }


	toDateObject = (date) =>{
		let date_str = (date%100).toString() + "-" + Math.floor((date%10000)/100).toString() + "-" + Math.floor(date/10000).toString();
		// console.info("date_str:", date_str);
		let date_obj = moment(date_str, 'DD-MM-YYYY').toDate();
		// console.info("date_obj:", date_obj);
		return date_obj;
	}

	toDateValueAsDatabase = (date) => {
		let ret = moment(date).format('YYYY') + moment(date).format("MM") + moment(date).format("DD")
		
		return parseInt(ret);
	}


    render() {
		if (this.state.ready === false) {
			return (<div></div>);
		}
	

		return (
			<div>
				<Style.ModalBody>
					{/* {
						(() => {
							if (this.state.show_sub_menu == true) {
								return (<Style.MemberMenu id="dvMemberMenu" onMouseEnter={() => this.setSubMenu(true, this.state.assign_id, this.state.index)}
									onMouseLeave={() => this.setSubMenu(false, this.state.assign_id, 0)} style={{
										backgroundColor: '#FEEBF4', width: 80,
										top: `${226 + (this.state.index * 46)}px`, left: '88%', zIndex: '4000'
									}}>
									<div>
										<Style.MemberMenuLine style={{ fontSize: '16px', fontWeight: '700' }}
											onClick={this.openEditViewAssignmentModal('edit', this.state.assign_id)}>
											Edit
										</Style.MemberMenuLine>
										<Style.MemberMenuLine
											style={{ fontSize: '16px', fontWeight: '700' }}
											onClick={this.openEditViewAssignmentModal('view', this.state.assign_id)}>View</Style.MemberMenuLine>
									</div>
								</Style.MemberMenu>)
							}
						})()
					} */}
					<NotesDiv>
						Use this form to end, add or amend assignments of this role to your employees.
						More than one employee can be assigned a role at the same time only if<br />
						i) the role can be shared, <br />
						ii) divided or  <br />
						iii) there is an allowed overlap window configured by your firm, to cover handover or training, for example.
					</NotesDiv>
					<ModalInnerHeader>
						Assigned To
						{
							Store.getStoreData('role') === 'admin_manager' &&
							<Style.Circle style={{ cursor: "pointer" }} onClick={this.openAddAssignmentModal('add', 0)}>
								<div style={{ position: "relative", fontSize: "31px", color: "#11ABA6", top: "-45%", paddingLeft: "1px"}}>+</div>
							</Style.Circle>
						}
					</ModalInnerHeader>
					<ModalInnerBody>
						<CSLTable add={false} export={false} processData={this.tableData} tableRows="8" />
						{ this.state.show_multi_user_title === true && <div className='multi-user-icon-title' style={{ top : `${110 + this.state.multi_user_icon_index*39}px`}} onMouseLeave={this.hideSadReasonTitle} onClick={this.showSadReasonModal}>Multiple Assignments - click for details</div>}
					</ModalInnerBody>
				</Style.ModalBody>
				<AlertBox alertParam={this.state.alert_param} />
				{
					this.state.show_sad_reason_modal === true &&
					<OverlapReasonModal date_range_data={this.state.sad_reason_data} closeModal={this.closeSadReasonModal}/>
				}
				{
					(() => {
						if (this.state.show_assignment_date_range_modal) {
							return (
								<div>
									<Style.InactiveOverlay style={{ height: "100%" }} />
									<AssignmentDateRangeModal assignment_date_range_data={this.state.date_modal_data}
										closeModal={this.closeAddAssignmentModal}
										getAssignedInfo={this.getAssignedInfo}
										list_contacts={this.state.list_contacts} 
										userGroupCompanies={this.state.userGroupCompanies}
										userConfig={this.props.userConfig}
										/>
								</div>)
						}
					})()
				}
				{
					(() => {
						if (this.state.show_assignment_confirmation_modal) {
							return (
								<div>
									<Style.InactiveOverlay style={{ height: "100%" }} />
									<AssignmentConfimationModal
										assignment_data={this.state.assignment_temp_data}
										role_info = {this.state.role_info}
										closeModal = {this.closeConfirmAssignmentModal}
										saveAssignment = {this.saveAssignment}
										userConfig={this.props.userConfig}
									/>
								</div>)
						}
					})()
				}
			</div>
		);
	}

}

export default AssignmentSectionNew;
