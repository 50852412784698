import React, { Component } from 'react'
import Utils from '../../Common/Utils';
import APICall from "./../../Common/APICall";
import moment from 'moment';
import TopicQuestion from './TopicQuestion'
import TopicsRightPanel from './TopicsRightPanel'
import AssessmentDataContext from "./AssessmentDataContext";

class AssessmentAttestation extends Component {

    constructor (props) {
        super(props);

        this.topic_acessor = {
            "Name": "",
            "Title": ""
        }
    }

    state = {
        ready: false,
        current_assessment_name: "",
        topics: [],
        assessment_assigned_to: "",
        assessment_over_all_acessor: "",
        assessment_assigned_by: "",
        assessment_due_date: "",
        assessment_assigned_date: "",
        infoWindow: {},
        attach_info: {}
    };


    static contextType = AssessmentDataContext;

    componentDidMount() {
        this.setComponentState();
    }
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setComponentState();
        }
    }

    async setComponentState() {
        let infoWindow = {};
        let result = {
            text: "Please note that following topics have questions where attachments / comments are necessary.",
            color: "#FBC1CB",
            border: "#DA7C9E",
            topic_names: []
        };
        const { data, assessment_necessary_object, } = this.context;

        let assessment_topics_array = data;
        if (assessment_topics_array.length > 0) {
            for (let i = 0; i < assessment_topics_array.length; i++) {
                const sections_array = assessment_topics_array[i]["sections"];
                for (let j = 0; j < sections_array.length; j++) {
                    const section = sections_array[j];
                    let section_name = section.section_name;

                    const questions_array = section["questions"];
                    for (let k = 0; k < questions_array.length; k++) {
                        let question = questions_array[k];
                        let req_attach = question.req_attach;
                        let req_comment = question.req_comment;

                        if (Utils.isBase64(question["text"])) {
                            let encodedQuestionText = question["text"];
                            let decodedString = decodeURIComponent(atob(encodedQuestionText));
                            assessment_topics_array[i].sections[j].questions[k]["text"] = decodedString;
                        }

                        let answerValue = question.answer_json.answer.value;
                        let answerText = question.answer_json.answer.text.trim();


                        if (
                            (answerValue !== "" &&
                                (req_attach === "mand_corrt_ans" || req_attach === "mand_incorrt_ans" || req_attach === "mand_both")) &&
                            question.response_attachment.length === 0
                        ) {

                            if (answerValue != "na") {

                                if (req_attach === "mand_incorrt_ans") {
                                    if (question.correct_answer.toLowerCase() != question.answer_json.answer.value) {
                                        result.topic_names.push(`${assessment_topics_array[i].name} - ${section_name} - Q${k + 1} -(Missing Attachment)`);
                                    }
                                } else if (req_attach === "mand_corrt_ans") {
                                    if (question.correct_answer.toLowerCase() == question.answer_json.answer.value) {
                                        result.topic_names.push(`${assessment_topics_array[i].name} - ${section_name} - Q${k + 1} -(Missing Attachment)`);
                                    }
                                } else if (req_attach === "mand_both") {
                                    result.topic_names.push(`${assessment_topics_array[i].name} - ${section_name} - Q${k + 1} -(Missing Attachment)`);
                                }
                            }
                        } if (
                            (answerValue !== "" &&
                                (req_comment === "mand_corrt_ans" || req_comment === "mand_incorrt_ans" || req_comment === "mand_both")) &&
                            answerText === '') {

                            if (answerValue != "na") {

                                if (req_comment === "mand_incorrt_ans") {
                                    if (question.correct_answer.toLowerCase() != question.answer_json.answer.value) {
                                        result.topic_names.push(`${assessment_topics_array[i].name} - ${section_name} - Q${k + 1} -(Missing Comment)`);
                                    }
                                } else if (req_comment === "mand_corrt_ans") {
                                    if (question.correct_answer.toLowerCase() == question.answer_json.answer.value) {
                                        result.topic_names.push(`${assessment_topics_array[i].name} - ${section_name} - Q${k + 1} -(Missing Comment)`);
                                    }
                                } else if (req_comment === "mand_both") {
                                    result.topic_names.push(`${assessment_topics_array[i].name} - ${section_name} - Q${k + 1} -(Missing Comment)`);
                                }
                            }
                        }

                    }
                }
            }
        }


        let assessment_over_all_acessor = assessment_necessary_object.assessment_over_all_acessor;

        let assessment_assigned_to = assessment_necessary_object.assessment_assigned_to_name;
        let assessment_assigned_to_date = moment.unix(assessment_necessary_object.assessment_assigned_date).format("DD/MM/YYYY");

        let assessment_assigned_by = assessment_necessary_object.assessment_assigned_by_name;
        let assessment_due_date = moment.unix(assessment_necessary_object.assessment_due_date).format("DD/MM/YYYY");


        let active_topic_name = "";

        let o = assessment_topics_array.find(t => t["is_clicked"] == true);

        if (typeof o !== 'undefined') {
            if (Object.keys(o).length != 0) {
                active_topic_name = o["name"]
                let topic_accessed_by = o["topic_assessed_by"];
                let r = this.getContactNames(topic_accessed_by);
                this.topic_acessor.Name = r.name;
                this.topic_acessor.Title = r.title;
            }
        }

        if (data.length > 0 && data.every(item => item.topic_status !== "")) {
            const result = {};

            data.forEach(item => {
                result[item.name] = item.topic_status;
            });
            infoWindow = this.assessStatus(result);
        }

        console.log("assessment_topics_array===>", assessment_topics_array)
        console.log("assessment_is_reviewed_send_mail===>", assessment_necessary_object.assessment_is_reviewed_send_mail)
        let isCountSendStatus = 0;
        for(let d of assessment_topics_array){
            if(d.topic_status === 'Complete' || d.topic_status === 'Reviewed'){
                isCountSendStatus = isCountSendStatus + 1;
            }
        }
        let isCheckSendStatus = 0;
        if(assessment_topics_array.length === isCountSendStatus){
            isCheckSendStatus = 1;
        }

        if('assessment_is_reviewed_send_mail' in assessment_necessary_object && assessment_necessary_object.assessment_is_reviewed_send_mail === 0 && isCheckSendStatus === 1){
            const { assessmnentCheckReviewMail } = this.context;
            assessmnentCheckReviewMail();
            const postData = {
                command: "assessments_details",
                action: 'assessment_ready_final_completion_mail',
                assignment_id: assessment_necessary_object.assignment_id,
                assessment_name: assessment_necessary_object.assessment_name,  
                assessment_over_all_acessor: assessment_necessary_object.assessment_over_all_acessor
            };

            console.log('postdata ==>', postData)
            const api = new APICall();
            let assessment_response = await api.commandWithoutCallback(postData);
        }

        this.setState({
            topics: assessment_topics_array,
            current_assessment_name: active_topic_name,
            assessment_assigned_to: assessment_assigned_to,
            assessment_assigned_by: assessment_assigned_by,
            assessment_due_date: assessment_due_date,
            assessment_assigned_date: assessment_assigned_to_date,
            infoWindow,
            attach_info: result.topic_names.length > 0 ? result : {},
            assessment_over_all_acessor: assessment_over_all_acessor,
            gcNames: data?.find(obj=>obj?.is_clicked)?.gcs?.label
        });
    }

    assessStatus = (obj) => {
        let keys = Object.keys(obj);
        let numDue = 0;
        let numReview = 0;
        let numComplete = 0;
        let numInProgress = 0;

        keys.forEach((key) => {
            let value = obj[key];

            if (value.toLowerCase().includes("due")) {
                numDue++;
            } else if (value.toLowerCase() === "review required") {
                numReview++;
            } else if (value.toLowerCase() === "complete") {
                numComplete++;
            } else if (value.toLowerCase() === "in progress") {
                numInProgress++;
            }
        });

        if (numInProgress + numDue === keys.length) {
            return {
                text:
                    "Please complete the assessment by completing all the Topics. Click on the topics on the right hand side to open it in the Topic Review Pane below",
                color: "#FFF2CC",
                border: "#FFD966",
            };
        } else if (numComplete === keys.length) {
            return {
                text: "There are no question flagged for review in any of the completed topic",
                color: "#E2F0D9",
                border: "#A9D18E",
            };
        } else if (numReview > 0) {
            let reviewKeys = keys.filter((key) => obj[key].toLowerCase() === "review required");
            return {
                text: "Please note that following topics have questions that are flagged for review.",
                color: "#FBC1CB",
                border: "#DA7C9E",
                topic_names: reviewKeys,
            };
        } else {
            return {}
        }
    }

    getContactNames = (inputString) => {
        const cts = JSON.parse(localStorage.getItem("contactlist"));
        let contactArray = cts.result.contactlist;
        let names = [...new Set(inputString?.toString().split(','))];
        let title = "";
        let name = "";

        if (names.length === 1) {
            let contact = contactArray[names[0]];
            if (contact) {
                title = contact.ContactName;
                name = contact.ContactName;
            }
        } else {
            let contactNames = [];
            for (let i = 0; i < names.length; i++) {
                let contact = contactArray[names[i]];
                if (contact) {
                    contactNames.push(contact.ContactName);
                }
            }

            if (contactNames.length > 0) {
                title = contactNames.join(', ');
                name = "Multiple";
            }
        }

        return {
            title: title,
            name: name
        };
    }

    render() {

        return (
            <div className='assessment-attest-wrapper' >
                <div className='assessment-attest-text-container'>
                    <p className='assessment-attest-text-header'>
                        As part of our obligations under the FCA's SMCR regime,
                        all certification employees are required to be assessed for their continued fitness and propriety
                        to perform the functions for which they are certified.
                        This is an exercise which should be done at least annually.
                        You are receiving this email because your annual cert is due.
                        Please follow the link below to complete your self-assessment.
                        Note that as a Certification employee, you are required to be completely open and honest
                        in your responses. Disciplinary proceedings could be taken against you if you are found to have
                        been dishonest in your responses. This could lead to withdrawing your certificate,
                        if you currently have one, and notifying the regulator. If you are unsure whether an event that has
                        taken place since your last assessment is reportable, please speak to the Compliance Officer.
                    </p>
                </div>

                {Object.keys(this.state.infoWindow).length > 0 && (
                    <div style={{
                        "width": "68%", "marginTop": "22px", "paddingTop": "7px", "paddingLeft": "18px",
                        "paddingBottom": "8px",
                        backgroundColor: this.state.infoWindow.color,
                        borderRadius: '6px',
                        border: `2px solid ${this.state.infoWindow.border}`
                    }}>
                        <span>{this.state.infoWindow.text}</span>
                        {this.state.infoWindow.topic_names && (
                            <ul className='assessment-attest-ul'>
                                {this.state.infoWindow.topic_names.map(topic => <li className='assessment-attest-li'>{topic}</li>)}
                            </ul>
                        )}
                    </div>
                )}

                {Object.keys(this.state.attach_info).length > 0 && (
                    <div style={{
                        "width": "68%", "marginTop": "22px", "paddingTop": "7px", "paddingLeft": "18px",
                        "paddingBottom": "8px",
                        backgroundColor: this.state.attach_info.color,
                        borderRadius: '6px',
                        border: `2px solid ${this.state.attach_info.border}`
                    }}>
                        <span>{this.state.attach_info.text}</span>
                        {this.state.attach_info.topic_names && (
                            <ul className='assessment-attest-ul'>
                                {this.state.attach_info.topic_names.map(topic => <li className='assessment-attest-li'>{topic}</li>)}
                            </ul>
                        )}
                    </div>
                )}

                <div className='assessment-attest-question-topic-section'>
                    <div className='assessment-attest-question-sections'>
                        <p className='assessment-attest-topic-name'>{this.state.current_assessment_name} for <i className='gcnames-all-companies'>{this.state?.gcNames}</i></p>
                        <div className='assessment-attest-assignee-info'>
                            <table className='assessment-attest-table'>
                                <tbody>
                                    <tr>
                                        <td className='assessment-attest-table-td-label'>Assigned To</td>
                                        <td><span className='assessment-attest-table-td-value'>
                                            <span title={this.topic_acessor.Title}>
                                                {this.topic_acessor.Name}
                                            </span>
                                        </span></td>
                                        <td className='assessment-attest-table-td-label'>Assigned Date</td>
                                        <td><span className='assessment-attest-table-td-value'>{this.state.assessment_assigned_date}</span></td>
                                    </tr>
                                    <tr>
                                        <td className='assessment-attest-table-td-label'>Assigned By</td>
                                        <td><span className='assessment-attest-table-td-value'>{this.state.assessment_assigned_by}</span></td>
                                        <td className='assessment-attest-table-td-label'>Due Date</td>
                                        <td><span className='assessment-attest-table-td-value'>{this.state.assessment_due_date}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className='assessment-attest-question-container'>
                            {
                                this.state.topics.length > 0 &&
                                <div>
                                    <TopicQuestion data={this.state.topics.find((a) => a["is_clicked"] == true) || {}}>
                                    </TopicQuestion>
                                </div>
                            }

                        </div>
                    </div>

                    <div className='assessment-attest-div-seperator'>

                    </div>

                    <div className='assessment-attest-topic-sections'>
                        {
                            this.state.topics.length > 0 && <TopicsRightPanel data={this.state.topics}></TopicsRightPanel>
                        }

                    </div>
                </div>
            </div>

        )
    }
}


export default AssessmentAttestation
