import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import "./../Modals/ModalStyle.css"
import APICall from "../../../Common/APICall"
import MultiSelectDropDown from "../../Common/MultiSelectDropdown"
import Store from "../../../Common/Store"
import AlertBox from "../../Common/AlertBox"



class AssessmentAddTopicModal extends React.Component {

    state = {
        role_types: [],
        group_companies: [],
        assessors: [],
        topics: [],
        role_assessed_types: [],
        topics_obj: {},
        rts_obj: {},
        gts_obj: {},
        selected_role_types: [],
        selected_gcs: [],
        selected_assessor_id: 0,
        specific_user_id: 0,
        selected_topic_id: 0,
        selected_role_assessed_type_id: 0,
        alert_param: null,
        ready: false
    }

    componentDidMount() {
        this.setComponentState()
    }

    setComponentState = async () => {
        const payload1 = { command: "assessments", action: 'list_assessed_by' }
        const payload2 = { command: "assessments", action: 'topics_by_role_types', assessment_id: this.props.assessment_id }
        const payload3 = { command: "assessments", action: 'topics_by_gcs', assessment_id: this.props.assessment_id }
        const payload4 = { command: "assessments", action: 'topic_role_assessment_master' }
        const api = new APICall()
        const [assessor_result, rt_result, gt_result, ra_result] = await Promise.all([
            api.commandWithoutCallback(payload1),
            api.commandWithoutCallback(payload2),
            api.commandWithoutCallback(payload3),
            api.commandWithoutCallback(payload4)
        ])
        let assessors = []
        if (assessor_result.error_code === 0) {
            assessors = assessor_result.result.assed_by_types
        }
        let role_assessed_types = []
        if (ra_result.error_code === 0) {
            role_assessed_types = ra_result.result.role_assessed_types
        }

        let topics_obj = {}

        // Topics by role types
        let rts = []
        let rts_obj = {}
        let arts_obj = {}
        if (rt_result.error_code === 0) {
            rts = rt_result.result
        }
        if (rts.length !== 0) {
            for (let item of rts) {
                topics_obj[item.id.toString()] = item.name
                arts_obj[item.role_type_id.toString()] = item.base
                if (item.role_type_id.toString() in rts_obj) {
                    rts_obj[item.role_type_id.toString()][item.id.toString()] = item.name
                } else {
                    rts_obj[item.role_type_id.toString()] = { [item.id.toString()]: item.name }
                }
            }
        }

        // Topics by group companies
        let gts = []
        let gts_obj = {}
        let agcs_obj = {}
        if (gt_result.error_code === 0) {
            gts = gt_result.result
        }
        if (gts.length !== 0) {
            for (let item of gts) {
                topics_obj[item.id.toString()] = item.name
                agcs_obj[item.gc_id.toString()] = item.company_name
                if (item.gc_id.toString() in gts_obj) {
                    gts_obj[item.gc_id.toString()][item.id.toString()] = item.name
                } else {
                    gts_obj[item.gc_id.toString()] = { [item.id.toString()]: item.name }
                }
            }
        }
        //debugger;
        const role_types = this.objToArray(arts_obj)
        const group_companies = this.objToArray(agcs_obj)
        const selected_assessor_id = assessors.find(item => item.is_default === 1).id
        this.setState({ assessors, role_types, group_companies, rts_obj, gts_obj, selected_assessor_id, topics_obj, role_assessed_types, ready: true })
    }

    objToArray = (obj) => {
        let arr = []
        for (let key in obj) {
            arr.push({ id: parseInt(key), name: obj[key] })
        }
        return arr
    }

    saveAssessmentTopic = async (event) => {
        event.preventDefault();
        let has_gc = JSON.parse(localStorage.getItem('has_gc'));
        let err_entities = []
        let msg = ""

        if (has_gc) {
            if (this.state.selected_gcs.length === 0) err_entities.push("Select Company")
        }

        if (this.state.selected_role_types.length === 0) err_entities.push("Select Regime")
        if (this.state.selected_topic_id === 0) err_entities.push("Select Topic")
        if (this.state.selected_assessor_id === 0) err_entities.push("Assessed by")
        if (this.state.selected_assessor_id === 6 && this.state.specific_user_id === 0) err_entities.push("Select User")
        if (this.state.selected_role_assessed_type_id === 0) err_entities.push("This topic is assessed")
        if (err_entities.length !== 0) {
            if (err_entities.length === 1) {
                msg = `${err_entities[0]} cannot be empty`
            } else {
                msg = "The following fields cannot be empty<br/><ul>"
                for (let item of err_entities) {
                    msg = `${msg}<li>${item}</li>`
                }
                msg = `${msg}</ul>`
            }
            let alert_param = {
                title: 'ERROR', message: msg, ok_text: 'Ok',
                alertHandler: this.processNothing, stack: {}
            }
            this.setState({ alert_param })
        } else {

            let isTopicPresent = this.checkIfTopicExist();
            if (!isTopicPresent) {
                const payload = {
                    command: "assessments",
                    action: "save_topic_assessment",
                    assessment_id: this.props.assessment_id,
                    topic_assessed_by_type: this.state.selected_assessor_id,
                    topic_assessed_by_id: this.state.specific_user_id,
                    topic_id: this.state.selected_topic_id,
                    role_assessed_type: this.state.selected_role_assessed_type_id,
                    selected_topic_gcs: this.state.selected_gcs.join(','),
                    selected_topic_regimes: this.state.selected_role_types.join(',')
                }
                const api = new APICall()
                const resp = await api.commandWithoutCallback(payload)
                this.props.refreshTopics(resp.result.topics)
            } else {
                let alert_param = {
                    title: 'ERROR', message: 'This Topic is already present in current assessment', ok_text: 'Ok',
                    alertHandler: this.processNothing, stack: {}
                }
                this.setState({ alert_param })
            }
        }
    }


    checkIfTopicExist = () => {
        let topic_ids = this.props.presentTopics;
        if (topic_ids.indexOf(this.state.selected_topic_id) !== -1) {
            return true;
        } else {
            return false;
        }
    }

    processNothing = () => {
        this.setState({ alert_param: null })
    }

    closeModal = (event) => {
        event.preventDefault()
        this.props.closeModal()
    }

    selectedGcOptions = (options) => {
        let selected_gcs = []
        const gts_obj = this.state.gts_obj
        const rts_obj = this.state.rts_obj
        let gc_topic_ids_arr = []
        let rt_topic_ids_arr = []
        let topics = []
        if (options.length !== 0) {
            for (let item of options) {
                selected_gcs.push(item.id)
                for (let key in gts_obj[item.id.toString()]) {
                    if (gc_topic_ids_arr.includes(parseInt(key)) === false) gc_topic_ids_arr.push(parseInt(key))
                }
            }
            if (this.state.selected_role_types.length !== 0) {
                for (let rt_id of this.state.selected_role_types) {
                    for (let key in rts_obj[rt_id.toString()]) {
                        if (rt_topic_ids_arr.includes(parseInt(key)) === false) rt_topic_ids_arr.push(parseInt(key))
                    }
                }
                const topic_ids_arr = gc_topic_ids_arr.filter(element => rt_topic_ids_arr.includes(element))
                for (let topic_id of topic_ids_arr) {
                    topics.push({ id: topic_id, name: this.state.topics_obj[topic_id.toString()] })
                }
            }
        }
        this.setState({ selected_gcs, topics })
    }

    selectedRtOptions = (options) => {

        let has_gc = JSON.parse(localStorage.getItem('has_gc'));
        console.log("Selected Options", options);
        let selected_role_types = []
        const gts_obj = this.state.gts_obj
        const rts_obj = this.state.rts_obj
        let gc_topic_ids_arr = []
        let rt_topic_ids_arr = []
        let topics = []
        if (options.length !== 0) {
            for (let item of options) {
                selected_role_types.push(item.id)
                for (let key in rts_obj[item.id.toString()]) {
                    if (rt_topic_ids_arr.includes(parseInt(key)) === false) rt_topic_ids_arr.push(parseInt(key))
                }
            }
            if (has_gc) {
                if (this.state.selected_gcs.length !== 0) {
                    for (let gc_id of this.state.selected_gcs) {
                        for (let key in gts_obj[gc_id.toString()]) {
                            if (gc_topic_ids_arr.includes(parseInt(key)) === false) gc_topic_ids_arr.push(parseInt(key))
                        }
                    }
                    const topic_ids_arr = gc_topic_ids_arr.filter(element => rt_topic_ids_arr.includes(element))
                    for (let topic_id of topic_ids_arr) {
                        topics.push({ id: topic_id, name: this.state.topics_obj[topic_id.toString()] })
                    }
                }
            } else {
                for (let roleType of options) {
                    const roleTypeId = roleType.id.toString();
                    const roleTypeTopics = rts_obj[roleTypeId];
                    if (roleTypeTopics) {
                        for (let topicId in roleTypeTopics) {
                            const topicName = roleTypeTopics[topicId];
                            const existingTopic = topics.find(topic => topic.id === parseInt(topicId));
                            if (!existingTopic) {
                                topics.push({ id: parseInt(topicId), name: topicName });
                            }
                        }
                    }
                }
            }
        }
        this.setState({ selected_role_types, topics })
    }

    changeTopic = (event) => {
        event.preventDefault()
        this.setState({ selected_topic_id: parseInt(event.target.value) })
    }

    changeAssessor = (event) => {
        event.preventDefault()
        this.setState({ selected_assessor_id: parseInt(event.target.value), specific_user_id: 0 })
    }

    changeSpecificUser = (event) => {
        event.preventDefault()
        this.setState({ specific_user_id: parseInt(event.target.value) })
    }

    changeRoleAssessedType = (event) => {
        event.preventDefault()
        this.setState({ selected_role_assessed_type_id: parseInt(event.target.value) })
    }

    render() {
        console.log("State from Assessment Add Topic", this.state)
        if (this.state.ready === false) {
            return (<div></div>)
        }
        //let users = Store.getStoreData("listcontacts")
        const cts = JSON.parse(localStorage.getItem("contactlist"));
        let contacts = cts.result.contactlist;

        let user_roles = cts.result.user_roles.smcr;

        const users = [];

        for (const empId in contacts) {
            if (contacts.hasOwnProperty(empId)) {
                const contact = contacts[empId];
                const contactId = contact["ID"];
                const contactName = contact["ContactName"];
                if (user_roles.hasOwnProperty(contactId) && user_roles[contactId] !== "no_access") {
                    users.push({ 'ID': contactId, 'ContactName': contactName });
                }
            }
        }
        return (
            <div>
                {this.state.alert_param !== null && <AlertBox alertParam={this.state.alert_param} />}
                <div className="add-role-modal-container" style={{ width: "55%" }}>
                    <div className="add-role-modal-header-container">
                        <div className="add-role-modal-header-text-container">
                            <div className="add-role-modal-header-title">Add Topic</div>
                            <div className="add-role-modal-header-subtitle">Use this section to add Topic to this assessment.</div>
                        </div>
                        <FontAwesomeIcon icon={solid('xmark')} onClick={this.closeModal} size="2x" style={{ cursor: "pointer" }} />
                    </div>
                    <div className="add-role-modal-body-container">
                        {
                            this.state.group_companies.length !== 0 &&
                            <div>
                                <div className="add-role-modal-label" style={{ fontSize: "14px", fontWeight: "600" }}>Select Company</div>
                                <MultiSelectDropDown options={this.state.group_companies} get_selected_items={this.selectedGcOptions} isCMPSelection={true}/>
                            </div>
                        }
                        {
                            this.state.role_types.length !== 0 &&
                            <div>
                                <div className="add-role-modal-label" style={{ fontSize: "14px", fontWeight: "600" }}>Select Regime</div>
                                <MultiSelectDropDown options={this.state.role_types} get_selected_items={this.selectedRtOptions} />
                            </div>
                        }
                        <div>
                            <div className="add-role-modal-label" style={{ fontSize: "14px" }}>Select Topic</div>
                            <select className="mc-dropdown" value={this.state.selected_topic_id} onChange={this.changeTopic}>
                                <option value={0}>Select</option>
                                {
                                    this.state.topics.map(item => {
                                        return <option key={`assr${item.id}`} value={item.id}>{item.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div>
                            <div className="add-role-modal-label" style={{ fontSize: "14px" }}>Assessed By</div>
                            <select className="mc-dropdown" value={this.state.selected_assessor_id} onChange={this.changeAssessor}>
                                <option value={0}>Select</option>
                                {
                                    this.state.assessors.map(item => {
                                        return <option key={`assr${item.id}`} value={item.id}>{item.assessed_by}</option>
                                    })
                                }
                            </select>
                        </div>
                        {
                            this.state.selected_assessor_id === 6 &&
                            <div>
                                <div className="add-role-modal-label" style={{ fontSize: "14px" }}>Select User</div>
                                <select className="mc-dropdown" value={this.state.specific_user_id} onChange={this.changeSpecificUser}>
                                    <option value={0}>Select</option>
                                    {
                                        users.map(item => {
                                            return <option key={`user${item.ID}`} value={item.ID}>{item.ContactName}</option>
                                        })
                                    }
                                </select>
                            </div>
                        }
                        <div>
                            <div className="add-role-modal-label" style={{ fontSize: "14px" }}>This topic is assessed</div>
                            <select className="mc-dropdown" value={this.state.selected_role_assessed_type_id} onChange={this.changeRoleAssessedType}>
                                <option value={0}>Select</option>
                                {
                                    this.state.role_assessed_types.map(item => {
                                        return <option key={`assr${item.id}`} value={item.id}>{item.assessed_type}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="add-role-modal-footer-container">
                        <button className="add-role-modal-cancel-button" onClick={this.closeModal}>Cancel</button>
                        <button className="add-role-modal-save-button" onClick={this.saveAssessmentTopic}>Save</button>
                    </div>
                </div>
            </div>
        )
    }

}

export default AssessmentAddTopicModal
