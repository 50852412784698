import React from 'react';
import Store from '../../Common/Store.js';
import APICall from "../../Common/APICall";
import {MdClose} from 'react-icons/md';
import CSLMultiSelect from '../Common/CSLMultiSelect.js'
import Draggable from 'react-draggable';
import styled from 'styled-components';
import { Checkbox, Select } from 'antd';


import "./AssignCertManagerModel.css"

const ModalContainer = styled.div`
    width : 750px;
    top : 70px;
    left : 25%;
    position : absolute;
    background-color : white;
    box-sizing : border-box;
    border-radius : 5px;
    box-shadow : 0px 0px 4px rgba(0, 0, 0, 0.4);
    border : 1px solid #d1d0d0;
    z-index: 1;
`

const Label = styled.div`
    padding : 15px 5px 5px 15px;
    color : #222222;
    font-size : 20px;
    font-weight: 600;
`
const LargeLabel = styled.div`
    padding : 10px;
    padding-left : 15px;
    color : #222222;
    font-size : 13px;
    font-weight : 600;
    
`

const GridLabel = styled.div`
    padding : 10px 10px 10px 0px;
    color : #3c3c3c;
    font-size : 16px;
    font-weight : 600;
`

const ModalHeader = styled.div`
    border-left : 7px solid #11aba6;
    background : white;
    box-sizing : border-box;
`

const ModalBody=styled.div`
    background-color : #F3F7FB;
    width : 100%;
    padding : 15px;
    box-sizing : border-box;
`

const ModalFooter=styled.div`
    height : 70px;
    background-color: #fff;
    box-sizing : border-box;
    display : flex;
    flex-direction : row;
    justify-content : flex-end;
    align-items : center;
    padding : 20px;
    border-radius : 5px;
`
const ModalCloseIcon=styled.div`
    position : absolute;
    top : 25px;
    right : 25px;
    color : #222222;
    font-size : 24px; 
`
const ModalCloseBtn=styled.button`
    background-color: #18b8a5;
    border: 1px solid #18b8a5;
    color: white;
    border-radius: 5px;
    width: 80px;
    padding: 5px 10px;
    box-sizing: border-box;
    cursor: pointer;

`

const ModalBodyFlex=styled.div`
    box-sizing : border-box;
    display : flex;
    justify-content : space-between;
    align-items : center;
    padding : 5px;
`

const ModalBodyFlexLeft=styled.div`
    width : 25%;
`
const ModalBodyFlexRight=styled.div`
    width : 65%;
`

const TriangleRight=styled.div`
    width: 0;
    height: 0;
    border-top: 18px solid transparent;
    border-left: 14px solid #555;
    border-bottom: 18px solid transparent;
`
const RoleLabel=styled.div`
    height : 36px;
    width : 200px;
    background-color : #555;
    color : white;
    font-size : 16px;
    font-weight : 600px;
    line-height : 38px;
    padding-left : 20px;
`

const RoleContainer=styled.div`
    display : flex;
    flex-direction : row;
`

class AssignCertManagerModal extends React.Component
{
    state = {
        contacts_in_list : {},
        selected_cert_mgrs : {},
        current_user : {},
        assigned_roles : {},
        assigned_roles_backup : {},
        applyToAllRoles : false,
        loading : false,
        filteredAssignedRoles: []
    } 
    
    componentDidMount = async () => {
		await this.setComponentState()
	}
    async componentDidUpdate(prevProps) {
		if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
			await this.setComponentState()
		}
	}
    setComponentState = async (selectedGcId) =>{
        let contacts = Store.getStoreData('listcontacts');
        let current_user = contacts.find(item => item.ID === this.props.user_id)
         let contacts_in_list = {};
        this.setState({ loading : true })
        let groupCompanies = {};
        let selectedGroupCompany = selectedGcId;
        Object.keys(this.props.assigned_roles)?.forEach(key => {
            
            if(!groupCompanies[this.props.assigned_roles[key]?.gc_id]) {
                groupCompanies[this.props.assigned_roles[key]?.gc_id] = {id: this.props.assigned_roles[key]?.gc_id, name: this.props.assigned_roles[key]?.companyName}
            }
            if(selectedGroupCompany === undefined) {
                selectedGroupCompany = this.props.assigned_roles[key]?.gc_id;
            }
                
        })
		const api = new APICall()
		//Get List of users associated with selected company/group company
        const selected_gc_id = selectedGcId ? selectedGcId : this.props.mode === 'edit' ? this.props.assigned_roles[this.props.row_id]?.gc_id : selectedGroupCompany;
		const payload1 = { command: 'personnel', action: 'get_contacts_under_assigned_company', gc_id:selected_gc_id, user_id: parseInt(this.props.selected_employee_id) }
		const [gc_contacts_response] = await Promise.all([api.commandWithoutCallback(payload1)])
        if(gc_contacts_response?.error_code === 0){
            if(gc_contacts_response?.listcontacts?.length > 0){
                for(let con of gc_contacts_response?.listcontacts){
                    if(con?.ID != this.props.user_id){
                        contacts_in_list[con?.ID] = {id : parseInt(con?.ID), name : con?.ContactName};
                    } 
                }
            }else{
                contacts_in_list[current_user?.ID] = {id : parseInt(current_user?.ID), name : current_user?.ContactName};
            }
        }
        let assigned_roles = JSON.parse(JSON.stringify(this.props.assigned_roles));
        let assigned_roles_backup = JSON.parse(JSON.stringify(this.props.assigned_roles_backup));
        let selected_cert_mgrs = {};
        //Initialize selected_cert_mgrs with previously assigned cert mgrs
        Object.keys(assigned_roles)?.forEach((key) => {
            if(assigned_roles[key]?.cert_mgr_id !== 0) selected_cert_mgrs[key] = contacts_in_list[(assigned_roles[key]?.cert_mgr_id)];
        })
        
        
        let filteredAssignedRoles = Object.values(this.props.assigned_roles)?.filter(role => role?.gc_id === selectedGroupCompany)
        this.setState({contacts_in_list, current_user, selected_cert_mgrs, assigned_roles, assigned_roles_backup, loading:false, ready : true, groupCompanies, selectedGroupCompany, filteredAssignedRoles});
    }

    assignCertMgr = (contact_id, assigned_roles_row_id = null) => {
        let selected_cert_mgrs = JSON.parse(JSON.stringify(this.state.selected_cert_mgrs));
        //Add modified row
        if(this.state?.applyToAllRoles) {
            Object.keys(this.props.assigned_roles)?.forEach(id => {
                if(this.props.assigned_roles[id]?.gc_id === this.state?.selectedGroupCompany) {
                    selected_cert_mgrs[id] = this.state?.contacts_in_list[contact_id]
                }                   
            })
        } else {
            selected_cert_mgrs[assigned_roles_row_id] = this.state?.contacts_in_list[contact_id]
        }
        
        this.setState({selected_cert_mgrs});
    }

    removeCertMgr = (contact_id, assigned_roles_row_id = null) => {
        let selected_cert_mgrs = JSON.parse(JSON.stringify(this.state?.selected_cert_mgrs));
        selected_cert_mgrs[assigned_roles_row_id]={};
        this.setState({selected_cert_mgrs});
    }

    saveAssignment = (e) =>{
        e.preventDefault();
        //check changed rows
        let assigned_roles = JSON.parse(JSON.stringify(this.state.assigned_roles));
        let selected_cert_mgrs = JSON.parse(JSON.stringify(this.state.selected_cert_mgrs))
        let role_cert_mgrs={}
        Object.keys(selected_cert_mgrs)?.forEach((key) => {
            //Verify action based on data change
            if(Object.keys(selected_cert_mgrs[key]).length>0){
                assigned_roles[key].cert_mgr_id = selected_cert_mgrs[key]?.id;
            }else{
                assigned_roles[key].cert_mgr_id = 0;
            }
            if(assigned_roles[key].cert_mgr_id === this.state.assigned_roles_backup[key].cert_mgr_id){
                assigned_roles[key]["action"] = "nochange";
            }else if(assigned_roles[key].cert_mgr_id !== 0 && this.state.assigned_roles_backup[key].cert_mgr_id === 0){
                assigned_roles[key]["action"] = "insert";
                role_cert_mgrs[key]=assigned_roles[key]
            }else if(assigned_roles[key].cert_mgr_id !== this.state.assigned_roles_backup[key].cert_mgr_id){
                assigned_roles[key]["action"] = "update";
                role_cert_mgrs[key]=assigned_roles[key]
            }
        })
        this.props.assignCertificationMgr(role_cert_mgrs);
    }

    closeDialog = (e) =>{
        e.preventDefault();
        this.props.closeModal();    
    }

    onChange = (e) =>{
        this.setState({ applyToAllRoles: !this.state?.applyToAllRoles });
    }
   
	render() {
        if(this.state.ready !== true) return <div></div>
        return (<Draggable>
            <ModalContainer>
                <ModalHeader>
                    <Label>Assign Certification Manager to </Label>
                    <LargeLabel>{this.state.current_user.ContactName} for their role(s) at {this.state?.groupCompanies[this.state?.selectedGroupCompany]?.name} </LargeLabel>
                    <ModalCloseIcon key={this.state.current_user.ID} onClick={this.closeDialog} style={{cursor : "pointer", color: "#222222"}}><MdClose /></ModalCloseIcon>
                </ModalHeader>
                <ModalBody>
                { this.props.mode === 'add' && <ModalBodyFlex>
                        <ModalBodyFlexLeft className='assign-certmanager-gc-label'><GridLabel>Group Company</GridLabel></ModalBodyFlexLeft>
                        <Select
                            mode="single"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            value={this.state?.selectedGroupCompany}
                            onChange={(selectedOption) => {
                                this.setState({ selectedGroupCompany: selectedOption, filteredAssignedRoles: Object.values(this.props.assigned_roles).filter(role => role.gc_id === selectedOption)  }, () => {this.setComponentState(selectedOption)})
                            }
                            }
                        >
                            {Object.values(this.state?.groupCompanies)?.map(gc => (<Select.Option key={gc?.id} value={gc?.id}>{gc?.name}</Select.Option>))}
                        </Select>
                    </ModalBodyFlex> }
                    <ModalBodyFlex>
                        <ModalBodyFlexLeft><GridLabel>Role</GridLabel></ModalBodyFlexLeft>
                        <ModalBodyFlexRight>
                            <GridLabel className='assign-cert-to'>Assign to</GridLabel>
                            { this.props.mode === 'add' && <GridLabel className='assign-apply-selection'>Apply selection to all Roles &nbsp; <Checkbox onChange={this.onChange}></Checkbox></GridLabel> }
                        </ModalBodyFlexRight>
                    </ModalBodyFlex>
                    {
                        (() => {
                            let ret = [];
                            let assigned_roles = JSON.parse(JSON.stringify(this.props.assigned_roles))
                            if (this.props.mode === 'edit') {
                                let bgc = assigned_roles[this.props.row_id]?.role_color;
                                if (this.props.row_id !== 0 && assigned_roles[this.props.row_id]?.row_id === this.props.row_id) {
                                    ret.push(
                                        <ModalBodyFlex key={this.props.row_id}>
                                            <ModalBodyFlexLeft>
                                                <RoleContainer>
                                                    <RoleLabel style={{ backgroundColor: bgc }}>{assigned_roles[this.props.row_id]?.role_code}</RoleLabel>
                                                    <TriangleRight style={{ borderLeftColor: bgc }} />
                                                </RoleContainer>
                                            </ModalBodyFlexLeft>
                                            <ModalBodyFlexRight>
                                                <CSLMultiSelect
                                                    selectedItems={this.state?.selected_cert_mgrs[this.props.row_id] === undefined ? {} : { key: this.state?.selected_cert_mgrs[this.props.row_id] }}
                                                    items={this.state?.contacts_in_list}
                                                    selectCallback={this.assignCertMgr}
                                                    deselectCallback={this.removeCertMgr}
                                                    entity={this.props.row_id}
                                                />
                                            </ModalBodyFlexRight>
                                        </ModalBodyFlex>
                                    )
                                }
                            } else if (this.props.mode === 'add') {
                                this.state?.filteredAssignedRoles?.forEach(assignedRole => {
                                    const bgc = assignedRole?.role_color;
                                    ret.push(
                                        <ModalBodyFlex key={assignedRole?.role_id}>
                                            <ModalBodyFlexLeft>
                                                <RoleContainer>
                                                    <RoleLabel style={{ backgroundColor: bgc }}>{assignedRole?.role_code}</RoleLabel>
                                                    <TriangleRight style={{ borderLeftColor: bgc }} />
                                                </RoleContainer>
                                            </ModalBodyFlexLeft>
                                            <ModalBodyFlexRight>
                                                <CSLMultiSelect
                                                    selectedItems={this.state?.selected_cert_mgrs[assignedRole?.row_id] === undefined ? {} : { key: this.state?.selected_cert_mgrs[assignedRole?.row_id] }}
                                                    items={this.state?.contacts_in_list}
                                                    selectCallback={this.assignCertMgr}
                                                    deselectCallback={this.removeCertMgr}
                                                    entity={assignedRole?.row_id}
                                                />
                                            </ModalBodyFlexRight>
                                        </ModalBodyFlex>
                                    )
                                })
                            }
                            return ret;
                        })()
                    }             
                </ModalBody>
                <ModalFooter>
                    <ModalCloseBtn onClick={this.saveAssignment}>OK</ModalCloseBtn>
                </ModalFooter>
            </ModalContainer>
        </Draggable>)
    }
}

export default AssignCertManagerModal;


