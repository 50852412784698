import React from "react";
import APICall from "../../../Common/APICall";
import CSLTable from "../../Common/CSLTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import AlertBox from '../../Common/AlertBox';

class AssessmentLegalEntities extends React.Component {

    state = { assessment_gcs: {}, gcs: [], alert_param: null }

    componentDidMount() {
        this.setComponentState()
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) this.setComponentState()
    }

    setComponentState = async () => {
        const payload1 = { command: "assessments", action: "list_gcs" }
        const payload2 = { command: "assessments", action: "list_assessment_gcs", assessment_id: this.props.assessment_id }
        const api = new APICall()
        const [gcs_result, assessment_gcs_result] = await Promise.all([api.commandWithoutCallback(payload1), api.commandWithoutCallback(payload2)])
        let assessment_gcs = {}
        let gcs = []
        if (gcs_result.length !== 0) {
            gcs = gcs_result
        }
        if (assessment_gcs_result.error_code === 0) {
            if (assessment_gcs_result.result.length !== 0) {
                const list = assessment_gcs_result.result
                for (let item of list) {
                    assessment_gcs[item.gc_id.toString()] = 1
                }
            }
        }
        this.setState({ assessment_gcs: assessment_gcs, gcs })
    }

    toggleActive = (gCompany) => async (event) => {
        event.preventDefault()
        let idsNotPresent = [];
        let alert_param = null;
        let assessment_gcs = JSON.parse(JSON.stringify(this.state.assessment_gcs));

        if (gCompany?.is_active == 1) {
            let in_active_ids = this.state.gcs.filter(gc => !(gc.id?.toString() in assessment_gcs)).map(gc => gc.id);
            if (in_active_ids.length > 0) {
                // idsNotPresent.push(in_active_ids);
                idsNotPresent = in_active_ids; 
            }

            if (!idsNotPresent.includes(gCompany?.id)) {

                idsNotPresent.push(gCompany?.id);
                idsNotPresent = idsNotPresent.join(',');

                let payload = { command: "assessments", action: 'check_for_gc_validation', gc_id: idsNotPresent, assessment_id: this.props.assessment_id }
                const api = new APICall()
                const result = await api.commandWithoutCallback(payload);

                let affectedTopics = result.result;



                if (affectedTopics != null) {
                    let affected_topic_ids = [];

                    if (affectedTopics.length > 0) {
                        let msg = `<div>
                                Removing the Legal Entity/Entities will invalidate the use of the
                               following Topics within the Assessment.Please check and press
                               confirm to proceed.
                               <div style='margin-top:15px'>`;

                        affectedTopics.forEach((topic) => {
                            affected_topic_ids.push(topic.topic_id)
                            msg += `<div style='text-align:left;margin-left:24px;margin-bottom:5px'>
                                    ${topic.name}
                                </div>`;
                        });
                        msg += '</div></div>';

                        alert_param = {
                            title: 'Please confirm your updates',
                            html_message: msg,
                            ok_text: 'Confirm',
                            cancel_text: 'Cancel',
                            confirm: true,
                            body_align: 'left',
                            alertHandler: this.handleClientRequest,
                            stack: {
                                topic_ids: affected_topic_ids,
                                gc_id: gCompany?.id
                            }
                        }

                    } else {
                        let payload = { command: "assessments", gc_id: gCompany?.id, assessment_id: this.props.assessment_id }
                        if (gCompany?.id.toString() in assessment_gcs === true) {
                            delete assessment_gcs[gCompany?.id.toString()]
                            payload.action = "remove_gcs_assessment_association"
                        } else {
                            assessment_gcs[gCompany?.id.toString()] = 1
                            payload.action = "add_gcs_assessment_association"
                        }

                        const api = new APICall()
                        const result = await api.commandWithoutCallback(payload)
                    }
                }
            }
        } else {
            let payload = { command: "assessments", gc_id: gCompany?.id, assessment_id: this.props.assessment_id }
            if (gCompany?.id.toString() in assessment_gcs === true) {
                delete assessment_gcs[gCompany?.id.toString()]
                payload.action = "remove_gcs_assessment_association"
            } else {
                assessment_gcs[gCompany?.id.toString()] = 1
                payload.action = "add_gcs_assessment_association"
            }

            const api = new APICall()
            const result = await api.commandWithoutCallback(payload)
        }
        this.setState({ assessment_gcs: assessment_gcs, alert_param })
    }


    handleClientRequest = async (status, stack) => {
        let assessment_gcs = JSON.parse(JSON.stringify(this.state.assessment_gcs));
        if (status === true) {
            let topic_ids = stack.topic_ids;
            topic_ids = topic_ids.join(',');

            let gc_id = stack.gc_id;

            let payload = { command: "assessments", action: 'remove_gc_and_topics', gc_id: gc_id, assessment_id: this.props.assessment_id, topic_ids: topic_ids }
            const api = new APICall()
            const result = await api.commandWithoutCallback(payload)
            console.log('result innside handleClientRequest()', result);
            if (result.error_code == 0) {
                delete assessment_gcs[gc_id.toString()]
            }
        }
        this.setState({ alert_param: null, assessment_gcs: assessment_gcs })
    }




    tableData = () => {
        let ret = { data: [], columns: [] }
        ret.columns = [
            { Header: 'GROUP COMPANY', accessor: 'name', filterable: false },
            {
                Header: "",
                Cell: row => (
                    <>
                        {
                            row.original.is_active === 1 ?
                                <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('toggle-on')} onClick={this.toggleActive(row.original)} /> :
                                <FontAwesomeIcon className="mc-roles-add-icon" style={{ color: "#ababab" }} icon={regular('toggle-off')} onClick={this.toggleActive(row.original)} />
                        }
                    </>
                ),
                maxWidth: 50,
                filterable: false
            }
        ]
        if (this.state.gcs.length !== 0) {
            for (let item of this.state.gcs) {
                let compId = item?.isParent ? 0 : item?.companyId;
                let is_active = compId.toString() in this.state.assessment_gcs === true ? 1 : 0
                let elem = { id:  compId, name: item?.companyName, is_active, isParent: item?.isParent }
                ret.data.push(elem)
            }
        }
        return ret
    }

    render() {
        return (
            <div style={{ marginTop: "25px" }}>
                <AlertBox alertParam={this.state.alert_param} />
                <CSLTable processData={this.tableData} />
            </div>
        )
    }

}

export default AssessmentLegalEntities
