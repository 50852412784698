import React from "react"
import APICall from "../../../Common/APICall"
import Store from "../../../Common/Store"
import CSLTable from "../../Common/CSLTable"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import AlertBox from "../../Common/AlertBox";
import AssessmentAddTopicModal from "./AssessmentAddTopicModal";
import AssessmentEditTopicModal from "./AssessmentEditTopicModal";

class AssessmentTopics extends React.Component {

    state = { topics: [], alert_param: null, show_add_topic_modal: false, show_edit_topic_modal: false, selected_topic_obj: {} }

    componentDidMount() {
        this.setComponentState()
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setComponentState();
        }
    }

    setComponentState = async () => {
        const payload = { command: "assessments", action: 'get_topic_of_assessments', assessment_id: this.props.assessment_id }
        const api = new APICall()
        const result = await api.commandWithoutCallback(payload)
        if (result.error_code === 0) {
            let topics = result.result.topics
            for (let i = 0; i < topics.length; i++) {
                topics[i].context_visible = false
            }
            this.setState({ topics })
        }
    }

    refreshTopics = (topics) => {
        for (let i = 0; i < topics.length; i++) {
            topics[i].context_visible = false
        }
        this.setState({ topics, show_add_topic_modal: false, show_edit_topic_modal: false })
    }

    toggleRole = async (id, role_assessed_type) => {
        const newData = JSON.parse(JSON.stringify(this.state.topics));
        let data = newData.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    role_assessed_type: role_assessed_type
                };
            }
            return item;
        });
        const payload = { command: "assessments", action: 'toggle_topic_assessed_type', assessment_id: this.props.assessment_id, id: id, topic_assessed_type: role_assessed_type };
        console.log('toggle payload', payload)
        const api = new APICall()
        const result = await api.commandWithoutCallback(payload);
        this.setState({ topics: data })
    }

    toggleContextMenu = (topic_id) => (event) => {
        event.preventDefault()
        let topics = JSON.parse(JSON.stringify(this.state.topics))
        for (let i = 0; i < topics.length; i++) {
            if (topics[i].id === topic_id) {
                topics[i].context_visible = topics[i].context_visible === true ? false : true
            } else {
                topics[i].context_visible = false
            }
        }
        this.setState({ topics })
    }

    closeContextMenu = (topic_id) => (event) => {
        event.preventDefault()
        let topics = JSON.parse(JSON.stringify(this.state.topics))
        for (let i = 0; i < topics.length; i++) {
            if (topics[i].id === topic_id) {
                topics[i].context_visible = false
                break
            }
        }
        this.setState({ topics })
    }

    deleteTopic = (e, topic_id) => {
        e.preventDefault()
        let alert_param = {
            title: 'Are you sure!', message: "You have selected to delete this topic.", ok_text: 'Ok', confirm: true,
            alertHandler: this.processDeleteTopic, stack: { topic_id: topic_id }
        }
        this.setState({ alert_param })
    }

    editTopic = (topicObj) => {
        console.log('topicObj', topicObj)
        this.setState({ selected_topic_obj: topicObj, show_edit_topic_modal: true })
    }

    processDeleteTopic = async (result, stack) => {
        if (result === true) {
            let topic_id = stack.topic_id;
            let assessment_id = this.props.assessment_id;

            const payload = { command: "assessments", action: "remove_assessment_topics", assessment_id: assessment_id, topic_id: topic_id };
            console.log('payload remove topic from assessment', payload)
            const api = new APICall()
            const result = await api.commandWithoutCallback(payload);
            console.log('delete result ==>', result);
            const topics = JSON.parse(JSON.stringify(this.state.topics))
            let filtered_topics = []
            for (let i = 0; i < topics.length; i++) {
                if (topics[i].topic_id !== stack.topic_id) filtered_topics.push(topics[i])
            }
            this.setState({ topics: filtered_topics, alert_param: null })
        } else {
            this.setState({ alert_param: null })
        }
    }

    tableData = () => {
        let has_gc = JSON.parse(localStorage.getItem('has_gc'));
        let ret = { data: [], columns: [] };
        ret.columns = [
            {
                Header: "TOPIC",
                id: "code",
                width: 250,
                filterable: false,
                minWidth: 250,
                accessor: "topic_name",
            },
            {
                Header: 'ROLE',
                filterable: false,
                accessor: 'role_types',
                minWidth: 250,
                Cell: (row) => {
                    if (row.original.role_types.split(',').length > 1) {
                        return (
                            <span title={row.original.role_types} style={{ color: "#1a3552", cursor: 'pointer' }}>Multiple</span>
                        )

                    } else {
                        return (
                            <span>{row.original.role_types}</span>
                        )
                    }
                },
                headerStyle: { textAlign: 'left', visible: 'false' }
            },
            {
                Header: 'PER ROLE',
                filterable: false,
                accessor: 'role_assessed_type',
                minWidth: 100,
                Cell: row => (
                    <>
                        {
                            row.original.role_assessed_type === 2 ?
                                <FontAwesomeIcon className="mc-roles-add-icon" title="Edit Role Type" icon={regular('toggle-on')} onClick={() => this.toggleRole(row.original.id, 1)} /> :
                                <FontAwesomeIcon className="mc-roles-add-icon" style={{ color: "#ababab" }} title="Edit Role Type" icon={regular('toggle-off')} onClick={() => this.toggleRole(row.original.id, 2)} />
                        }
                    </>
                ),
                headerStyle: { textAlign: 'left', visible: 'false' }
            },
            {
                Header: 'GROUP COMPANY',
                filterable: false,
                accessor: 'company_name',
                minWidth: 100,
                show: has_gc,
                Cell: (row) => {
                    if (row.original.company_name.split(',').length > 1) {
                        return (
                            <span title={row.original.company_name} style={{ color: "#1a3552", cursor: 'pointer' }}>Multiple</span>
                        )

                    } else {
                        return (
                            <span>{row.original.company_name}</span>
                        )
                    }
                },
                headerStyle: { textAlign: 'left', visible: 'false' }
            },
            {
                Header: 'Assessed By',
                filterable: false,
                accessor: 'topic_assessed_type',
                minWidth: 150,
                Cell: (row) => {
                    if (row.original.topic_assessed_type == 'Self-Assessed') {
                        return (
                            <span>Employee (Self-Assessed)</span>
                        )

                    } else {
                        return (
                            <span>{row.original.topic_assessed_type}</span>
                        )
                    }
                },
                headerStyle: { textAlign: 'left', visible: 'false' }
            },
            {
                'Header': '', Cell: row => (
                    <div>
                        <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('ellipsis-vertical')} onClick={this.toggleContextMenu(row.original.id)} />
                        {
                            row.original.context_visible &&
                            <div className="context-menu" onMouseLeave={this.closeContextMenu(row.original.id)}>
                                {(() => {
                                    if (row.original.can_edit_delete) {
                                        return (
                                            <React.Fragment>
                                                <div div className="context-menu-item" onClick={(e) => this.deleteTopic(e, row.original.topic_id)}>Remove</div>
                                                <div className="context-menu-item" onClick={(e) => this.editTopic(row.original)}>Edit</div>
                                            </React.Fragment>
                                        )
                                    }
                                    return null;
                                })()}
                            </div>
                        }
                    </div>
                ), width: 70, headerStyle: { textAlign: 'center' }, filterable: false
            },
        ];


        for (let [index, d] of this.state.topics.entries()) {
            let elem = {
                id: d.id,
                topic_id: d.topic_id,
                topic_name: d.topic_name,
                role_types: Array.from(new Set(d.role_types.split(','))).toString(),
                role_assessed_type: d.role_assessed_type,
                company_name: Array.from(new Set(d.company_name.split(','))).toString(),
                topic_assessed_type_id: d.topic_assessed_by_type,
                topic_assessed_type: d.topic_assessed_by_type != 6 ? d.topic_assessed_type : Store.getStoreData('listcontacts').find((c) => c["ID"] == d.topic_assessed_type)["ContactName"],
                topic_assessed_by_id: d.topic_assessed_type,
                display_order: d.display_order,
                color: Array.from(new Set(d.color.split(','))).toString(),
                index: index,
                context_visible: d.context_visible,
                can_edit_delete: 1
            }
            ret.data.push(elem);
        }
        return ret
    }

    addTopicModalInAssessment = async (event) => {
        event.preventDefault()
        this.setState({ show_add_topic_modal: true })
        // const payload = {command: "assessments", action: 'get_assessment_filter_topic', id: this.props.assessment_id}
        // const api = new APICall()
        // const result = await api.commandWithoutCallback(payload)
    }

    closeModal = () => {
        this.setState({ show_add_topic_modal: false })
    }

    closeEditModal = () => {
        this.setState({ show_edit_topic_modal: false })
    }

    render() {
        console.log('inide assessment topics modal ==>', this.state);
        return (
            <div style={{ marginTop: "30px" }}>
                {
                    this.state.show_edit_topic_modal === true &&
                    <div className="interactive-overlay" style={{ zIndex: "2" }}>
                        <AssessmentEditTopicModal
                            assessment_id={this.props.assessment_id}
                            refreshTopics={this.refreshTopics}
                            closeModal={this.closeEditModal}
                            topic_obj={this.state.selected_topic_obj}
                        />
                    </div>
                }
                {
                    this.state.show_add_topic_modal === true &&
                    <div className="interactive-overlay" style={{ zIndex: "2" }}>
                        <AssessmentAddTopicModal
                            assessment_id={this.props.assessment_id}
                            refreshTopics={this.refreshTopics}
                            closeModal={this.closeModal}
                            presentTopics={this.state.topics.map(obj => obj.topic_id)}
                        />
                    </div>
                }
                <div className="mc-table-header-container">
                    <div style={{ fontWeight: "600", fontSize: "15px" }}>Topics</div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <FontAwesomeIcon className="mc-roles-add-icon" title="Add Topic" icon={regular('square-plus')} onClick={this.addTopicModalInAssessment} style={{ marginRight: "10px" }} />
                        {/* <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('ellipsis-vertical')} onClick={this.openCloseContexMenu}/> */}
                    </div>
                </div>
                <CSLTable processData={this.tableData} />
                {this.state.alert_param !== null && <AlertBox alertParam={this.state.alert_param} />}
            </div>
        )
    }


}

export default AssessmentTopics
