import React from 'react';
import * as Style from '../Common/StyledComponents';
import {MdClose} from 'react-icons/md';
import { Select } from "antd"
import AlertBox from '../Common/AlertBox';
import SpinningLoaderSmall from '../Common/SpinningLoaderSmall';
import { DatePicker, Checkbox } from 'antd';
import dayjs from 'dayjs';
import "./DateRange/dateRangeModal.css"
const { RangePicker } = DatePicker;
const { Option } = Select

class DateRangeModal extends React.Component{
    state = {
        mode : null,
        subtitle_msg : "",
        from_nodate : true,
        to_nodate : true,
        date_range_data : {},
		error_msg : null,
		ready : false,
		date_range_data_from_prop : {},
		alert_param : null,
		show_spinner : false,
		groupCompanies:[],
		fromToDate:null,
		noFromToDate:true,
		enableGcSelect:true
    }

	constructor(props){
		super(props);
		this.state.date_range_data = this.props.date_range_data;
	}

	componentDidMount()
	{
		this.initialiseState();
	}

	initialiseState()
	{
		let data = JSON.parse(JSON.stringify(this.props.date_range_data));
		let date_range_data_from_prop = JSON.parse(JSON.stringify(data));
		let noFromToDate = true;
		let fromToDate = null;
		const enableGcSelect = data?.data?.action === "insert" ? true : false;
		if (!(data.data.from === -1 && data.data.to === parseInt(process.env.REACT_APP_NO_END_DATE))) {
			noFromToDate = false;
			fromToDate = [dayjs(data?.data?.from?.toString(), "YYYYMMDD"), dayjs(data?.data?.to?.toString(), "YYYYMMDD")]
		}
		let subtitle_msg = ""
		switch(this.props.date_range_data.type){
			case "na":
				subtitle_msg = "Enter the date range and reason why this role is marked as Not Applicable at this firm during this date range"
				break;
			case "shared":
				subtitle_msg = "Enter the date range and reason why this role is allowed to be shared during this date range"
				break;
			case "divided":
				subtitle_msg = "Enter the date range and reason why this role is allowed to be divided during this date range"
				break;
		}
		if(this.props.date_range_data.mode === 'add'){
			this.setState({  subtitle_msg, ready : true, date_range_data : data, mode : this.props.date_range_data.mode, groupCompanies:this.props?.date_range_data?.data?.groupCompanies, fromToDate, noFromToDate,enableGcSelect:true });
		}else if(this.props.date_range_data.mode === 'edit'){
			this.setState({  subtitle_msg, ready : true, date_range_data : data, date_range_data_from_prop ,  mode : this.props.date_range_data.mode, groupCompanies:this.props?.date_range_data?.data?.groupCompanies, fromToDate , noFromToDate, enableGcSelect: enableGcSelect});
		}

	}

	closeModal =(type) => (event) => {
		event.preventDefault();
		this.props.closeModal(type);
	}

	onChangeDateRange = (dateRange) => {
		const date_range_data = JSON.parse(JSON.stringify(this.state.date_range_data));
		if (dateRange && dateRange.length > 1) {
			date_range_data.data.from = dayjs(dateRange[0]).format('YYYYMMDD')
			date_range_data.data.to = dayjs(dateRange[1]).format('YYYYMMDD')
		} else {
			date_range_data.data.from = null
			date_range_data.data.to = null
		}
		this.setState({ date_range_data: date_range_data, fromToDate: dateRange });
	}

	onChangeNoDate = (checked) => {
		const date_range_data = JSON.parse(JSON.stringify(this.state.date_range_data));
		if (checked) {
			date_range_data.data.from = null
			date_range_data.data.to = null
			this.setState(state => ({ ...state, date_range_data, noFromToDate: checked, fromToDate: null }))
		} else {
			this.setState(state => ({ ...state, fromToDate: [dayjs(new Date()), dayjs(new Date())], noFromToDate: checked }))
		}
	}

	saveDateRange = (e) =>{
		if(this.hasEnableSubmitButton()){
			e.preventDefault();
			let date_range_data = JSON.parse(JSON.stringify(this.state.date_range_data));
			if (this.state.noFromToDate) {
				date_range_data.data.from = -1
				date_range_data.data.to = parseInt(process.env.REACT_APP_NO_END_DATE)
			}
			if(date_range_data?.mode === 'edit'){
				if(this.state.date_range_data_from_prop.data.from !== date_range_data?.data?.from || this.state.date_range_data_from_prop.data.to !== date_range_data?.data?.to || this.state.date_range_data_from_prop.data.reason !== date_range_data?.data?.reason) {
					date_range_data.data['action'] = date_range_data.data['action'] !== 'insert' ? 'update' : date_range_data.data['action'];
				}
			}
			date_range_data.data["groupCompanies"] = this.state.groupCompanies			
			this.props.saveDateRange(date_range_data);
		}
	}

	reasonOnChange = (e) =>{
		e.preventDefault();
		let date_range_data = JSON.parse(JSON.stringify(this.state.date_range_data));
		date_range_data.data.reason = e.target.value;
		this.setState({date_range_data});
	}

	closeAlert = () => {

		this.setState({ alert_param : null})
	}

	hasEnableSubmitButton = () => {
		return this.state.date_range_data?.data?.reason?.length > 1 && this.state.groupCompanies?.length &&
			(!this.state.noFromToDate && this.state.fromToDate ? true : this.state.noFromToDate)
	}

    render()
	{
		if(this.state.ready !== true) return (<div></div>);
		return (
			<div>
                <Style.ModalContainer style={{color:"#1B3451", top: "40%", width:"90%", margin:"0px 5% 0px 5%"}}>
                    <Style.ModalHeader style={{color:"#1B3451"}}>
                    	 <div style={{fontSize: "20px", float: "left", marginTop: "15px", marginLeft: "30px"}}>
                    	 {
                    	     (() =>{
                    	         if(this.state.mode == 'add'){
                    	             return "Add Role Date Range"
                    	         }else{
                    	             return "Edit Role Date Range"
                    	         }
                    	     })()
                    	 }
                    	 </div>

        	         	<div style={{fontSize: "13px", marginTop: "50px", marginLeft: "30px", marginBottom: "15px"}}>
	                    	{this.state.subtitle_msg}
	                    </div>

                        <Style.ModalHeaderCloseBtn style={{fontSize: "19px"}} onClick={this.closeModal(this.props.date_range_data.type)}><MdClose /></Style.ModalHeaderCloseBtn>
                    </Style.ModalHeader>
                    <Style.ModalBody>
						{
							(()=>{
								return(
									<div style={{paddingLeft: "28px", color: "red"}}>
										{this.state.error_msg}
									</div>
									)
							})()
						}
						<div className='input-form'>
						{this.props?.userConfig?.hasGroupCompanies ?
							<div className='input-field'>
								<div className='input-label'>Group Companies:</div>
								<Select
									mode="multiple"
									allowClear
									style={{ width: '55%' }}
									placeholder="Please select"
									className='incident-type-filter'
									maxTagCount={2}
									value={this.state.groupCompanies}
									onChange={(selectedOptions) => {
										this.setState({ groupCompanies: selectedOptions })
									}
									}
									disabled={!this.state.enableGcSelect}
								>
									{this.props.userGroupCompanies.map(type => (<Option key={type?.id} value={type?.id}>{type?.name}</Option>))}
								</Select>
							</div>
							: <div className='no-group-companies-section'> Company: <span className='no-group-companies'>{this.props?.userConfig?.parentCompany?.name}</span></div>}

							<div className='input-field'>

								<Checkbox onChange={(e) => this.onChangeNoDate(e.target.checked)} checked={this.state.noFromToDate}>
									<label className='input-label'>No Date</label>
								</Checkbox>
								{!this.state.noFromToDate && <div><RangePicker className='date-range'
									format={"DD/MM/YYYY"}
									value={this.state.fromToDate}
									onChange={(dateRange) => this.onChangeDateRange(dateRange)}
								/></div>}
							</div>
							<div className='input-label'>Reason:</div>
							<Style.ModalTextArea type="text" name="reason" style={{ width: "89%", height: "70px" }} onChange={this.reasonOnChange} value={this.state.date_range_data.data.reason} />
						</div>
						
                    </Style.ModalBody>
                    <div style={{width: "100%", height: "2px", backgroundColor: "#EBEBEB", marginTop: "20px"}}></div>
                    <div style={{textAlign: "right", marginTop: "20px", paddingLeft : "20px", display : "flex", justifyContent : "space-between", alignItems : "center"}}>
						{this.state.show_spinner && <SpinningLoaderSmall text={'Validating'}/>}
						{!this.state.show_spinner && <div></div>}
						<div>
							<Style.ConfigBtn style={{backgroundColor: "#FFFFFF", borderColor:"#8D8989", color:"#8D8989"}} onClick={this.closeModal(this.props.date_range_data.type)}>Cancel</Style.ConfigBtn>
							<Style.ConfigBtn className={this.hasEnableSubmitButton()?' submit-btn-enable ':'submit-btn-disable'} onClick={this.saveDateRange} >OK</Style.ConfigBtn>
						</div>
                    </div>
					<AlertBox alertParam={this.state.alert_param} />
                    <div style={{width: "100%", height: "1px", backgroundColor: "#EBEBEB", marginTop: "20px"}}></div>
                </Style.ModalContainer>
            </div>
		);
	}
}

export default DateRangeModal;
