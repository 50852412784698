import React from 'react';
import AlertBox from '../Common/AlertBox.js';
import APICall from '../../Common/APICall.js';
import CSLLoader from '../Common/CSLLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' //{,thin, regular}


const moment = require('moment');

class AssessmentConfimationModal extends React.Component {
    state = {
        hasError: false,
        assessment_details: [],
        assessment_topic_details: [],
        dueDate: this.props.dueDate,
        ready: false,
        comment: "",
        alert_param: null,
        has_gc: JSON.parse(localStorage.getItem('has_gc'))
    };


    constructor (props) {
        super(props);

        const cts = JSON.parse(localStorage.getItem("contactlist"));
        //console.log('cts ==>', cts);
        this.contacts = cts.result.contactlist;
    }

    componentDidMount() {
        this.initialiseState();

    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.initialiseState();
        }
    }

    initialiseState = async () => {
        this.setState({ ready: false })
        let assessment_id = this.props.id;
        let employee_id = this.props.emp_id;
        const cts = JSON.parse(localStorage.getItem("contactlist"));
        let user_roles = cts.result.user_roles.smcr;
        console.log('user roles ', user_roles);
        const postData = { command: "assessments_details", action: 'fetch_assessment_confirmation', assessment_id, employee_id, selectedGroupCompanies: this.props.selectedCompanies };
        const api = new APICall();

        let result = await api.commandWithoutCallback(postData);
        if (result.result != null) {
            let hasError = result.result.hasError;
            let assessment_details = result.result.assessment_details;
            let topic_details = result.result.topic_details;
            if (assessment_details != null) {
                if (assessment_details.length > 0) {
                    assessment_details = Array.from(new Set(assessment_details.map(JSON.stringify))).map(JSON.parse);
                    assessment_details = assessment_details.map(item => {
                        if (user_roles[item.assessment_overall_completion.toString()] === "no_access") {
                            hasError = true
                            return { ...item, has_no_access_error: true };
                        }
                        return item;
                    });
                }
            }
            if (topic_details != null) {
                if (topic_details.length > 0) {
                    console.log('topic_details ==>', topic_details);
                    // GET DISTINCT VALUES FROM ARRAY OF OBJECTS
                    // for (let i = 0; i < topic_details.length; i++) {
                    //     topic_details[i]["role_id"] = topic_details[i]["role_assessed_type"] == 1 ? 0 : topic_details[i]["role_id"];
                    //     topic_details[i]["role_code"] = topic_details[i]["role_assessed_type"] == 1 ? "" : topic_details[i]["role_code"];
                    // }

                    for (let i = 0; i < topic_details.length; i++) {
                        if (topic_details[i].role_assessed_type === 1) {
                            let roleIds = "";
                            let roleCodes = "";

                            for (let j = 0; j < topic_details.length; j++) {
                                if (topic_details[j].topic_id === topic_details[i].topic_id && topic_details[j].role_assessed_type === 1) {
                                    //debugger;
                                    if (!roleIds.includes(topic_details[j].role_id)) {
                                        roleIds += topic_details[j].role_id + ",";
                                    }
                                    if (!roleCodes.includes(topic_details[j].role_code)) {
                                        roleCodes += topic_details[j].role_code + ",";
                                    }
                                }
                            }

                            roleIds = roleIds.slice(0, -1); // Remove the last comma
                            roleCodes = roleCodes.slice(0, -1); // Remove the last comma

                            topic_details[i].role_id = roleIds;
                            topic_details[i].role_code = roleCodes;
                        }
                    }



                    console.log('topic_details', topic_details)
                    //topic_details = Array.from(new Set(topic_details.map(JSON.stringify))).map(JSON.parse);
                    topic_details = topic_details.map(item => {
                        if (user_roles[item.topic_assessed_by_id.toString()] === "no_access") {
                            hasError = true
                            return { ...item, has_no_access_error: true };
                        }
                        return item;
                    });
                }
            }

            this.setState({ ready: true, assessment_details, assessment_topic_details: topic_details, hasError })
        }

    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }

    closeView = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }

    assignAssessmentSubmit = async (e) => {
        e.preventDefault();
        let alert_param = null;
        this.setState({ ready: false });
        let assessment_id = this.props.id;
        let employee_id = this.props.emp_id;
        let due_date = moment(this.props.dueDate, 'DD-MM-YYYY').unix();
        let comment = this.state.comment;
        let group_companies = this.props.matchedGroupCompanies?.map(gc => gc.id)

        let result = [];

        this.state.assessment_details.forEach(assessment => {

            this.state.assessment_topic_details.forEach(topic => {
                if (assessment.assessment_id == topic.assessment_id) {
                    result.push({
                        assessment_name: assessment.assessment_name,
                        assessment_assessed_by_type: assessment.assessed_by_type_text,
                        assessment_assessed_by_id: assessment.assessment_overall_completion,
                        assignment_id: null,
                        assessment_runs_every: assessment.assessment_runs_every,
                        topic_name: topic.name,
                        role_id: topic.role_id,
                        role_code: topic.role_code,
                        legal_entity: assessment.assessment_gc,
                        topic_assessed_by_type: topic.topic_assessed_by,
                        topic_assessed_by_id: topic.topic_assessed_by_id,
                        topic_id: topic.topic_id,
                        topic_assessed_type: topic.role_assessed_type
                    });
                }
            });
        });

        // get distinct topic id so that I can assign question for each topic
        const distinctTopicIds = [...new Set(result.map(item => item.topic_id))];



        const postData = { command: "assessments_details", action: 'insert_assign_assessment', assessment_id, employee_id, due_date, comment, assessment_topics_copy: result, topic_ids: distinctTopicIds, group_companies };
        const api = new APICall();
        console.log('post data ==>', postData)
        let assessment_response = await api.commandWithoutCallback(postData);
        let inserted_id = assessment_response.result.assign_id;
        //let inserted_id = 1
        if (inserted_id > 0) {
            alert_param = { title: 'Assessment assignment', message: "Assessment assigned successfully", ok_text: 'OK', confirm: false, alertHandler: this.confirmHandler }
        } else {

            alert_param = { title: 'Assessment assignment', message: "Problem while inserting assessment", ok_text: 'OK', confirm: false, alertHandler: this.confirmHandler }
        }
        this.setState({ alert_param: alert_param, ready: true });
    }



    handleChange = (event) => {
        this.setState({ comment: event.target.value });
    }

    confirmHandler = () => {
        this.setState({ alert_param: null });
        this.props.closeModal();
    }

    render() {
        if (!this.state.ready) {
            return (
                <div style={{ position: 'relative' }}>
                    <div className="spinner" />
                </div>
            );
        }
        return (
            <div>
                <div className='assign-assessment-confirmation-modal-container'>
                    <div className='assign-assessment-confirmation-modal-header'>
                        <div className='assign-assessment-confirmation-header-text'>
                            {this.props.assessment_name} Of {this.props.employee_name}
                        </div>


                        <div className='assign-assessment-confirmation-header-close-btn'
                            onClick={this.closeModal}>
                            <FontAwesomeIcon title="Action" icon={solid('xmark')} />
                        </div>
                    </div>
                    <div className='assign-assessment-confirmation-modal-body'>
                        <div className='assign-assessment-confirmation-notes'>
                            You are about to send the following topics/assessment for completion.
                            Please review and confirm your selection.
                        </div>

                        {
                            this.state.hasError &&
                            <div className='assign-assessment-confirmation-error-box'>
                                <FontAwesomeIcon title="Action" icon={solid('triangle-exclamation')} />
                                There are issues with the assessment that needs to be addressed before you can submit.
                            </div>

                        }


                        {
                            this.state.assessment_details.length > 0 &&
                            <table className='assign-assessment-confirmation-table'>
                                <thead className='assign-assessment-confirmation-thead'>
                                    <tr>
                                        <th className='assign-assessment-confirmation-first-th'>ASSIGNED TO</th>
                                        <th className='assign-assessment-confirmation-th'>ASSESSMENT OF</th>
                                        <th className='assign-assessment-confirmation-th'>ASSESSMENT</th>
                                        <th className='assign-assessment-confirmation-th'>CERT. DUE DATE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.assessment_details.map(obj => {
                                        let empName = "";
                                        let assessment_overall_completion = obj["assessment_overall_completion"];
                                        let assessed_by_type = obj["assessed_by_type"];
                                        let has_no_access_error = obj.hasOwnProperty('has_no_access_error') ? true : false
                                        if (assessment_overall_completion == 0) {
                                            if (assessed_by_type == "5") {
                                                empName = "Line Manager is missing"
                                            } else if (assessed_by_type == "4") {
                                                empName = "Cert Manager is missing"
                                            }
                                        }

                                        else if (has_no_access_error) {
                                            if (assessed_by_type == "5") {
                                                empName = `Line Manager ${this.contacts[assessment_overall_completion]["ContactName"]} has no access role`
                                            } else if (assessed_by_type == "4") {
                                                empName = `Cert Manager ${this.contacts[assessment_overall_completion]["ContactName"]} has no access role`
                                            } else if (assessed_by_type == "6" || assessed_by_type == "3") {
                                                empName = `${this.contacts[assessment_overall_completion]["ContactName"]} has no access role`
                                            }
                                        }
                                        else {
                                            empName = this.contacts[assessment_overall_completion]["ContactName"]
                                        }

                                        return (
                                            <tr>
                                                <td className={`assign-assessment-confirmation-first-td ${(assessment_overall_completion == 0 || has_no_access_error) ? 'assign-assessment-td-error' : 'assign-assessment-td-no-error'}`}>{empName}</td>
                                                <td className='assign-assessment-confirmation-td'>{this.props.employee_name}</td>
                                                <td className='assign-assessment-confirmation-td'>{this.props.assessment_name}</td>
                                                <td className='assign-assessment-confirmation-td'>{moment(new Date(this.state.dueDate)).format("DD/MM/YYYY")}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        }

                        <div className='assessment-grpcomp'>
                            {
                                this.props.matchedGroupCompanies?.map(mgc => {
                                    return (
                                        <>
                                            <div className='assessment-grpcomp-label'>For their Roles at {mgc?.name}</div>
                                            {
                                                this.state.assessment_topic_details?.length > 0 &&
                                                <div >
                                                    <table className='assign-assessment-confirmation-table'>
                                                        <thead className='assign-assessment-confirmation-thead'>
                                                            <tr>
                                                                <th className='assign-assessment-confirmation-first-th'>ASSIGNED TO</th>
                                                                <th className='assign-assessment-confirmation-th'>ASSESSMENT OF</th>
                                                                <th className='assign-assessment-confirmation-th'>TOPIC</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.assessment_topic_details?.map(obj => {
                                                                if(obj?.gc_id == (mgc?.isParent ? "0": mgc?.id)) {
                                                                    let empName = "";
                                                                    const topic_assessed_by_id = obj["topic_assessed_by_id"];
                                                                    const topic_assessed_by_type = obj["topic_assessed_by_type"];
                                                                    const has_no_access_error = obj.hasOwnProperty('has_no_access_error') ? true : false
    
                                                                    if (topic_assessed_by_id == 0) {
                                                                        if (topic_assessed_by_type == "5") {
                                                                            empName = "Line Manager is missing"
                                                                        } else if (topic_assessed_by_type == "4") {
                                                                            empName = "Cert Manager is missing"
                                                                        }
                                                                    }
                                                                    else if (has_no_access_error) {
                                                                        if (topic_assessed_by_type == "5") {
                                                                            empName = `Line Manager ${this.contacts[topic_assessed_by_id]["ContactName"]} has no-access role`
                                                                        } else if (topic_assessed_by_type == "4") {
                                                                            empName = `Cert Manager ${this.contacts[topic_assessed_by_id]["ContactName"]} has no-access role`
                                                                        }
                                                                        else if (topic_assessed_by_type == "6" || topic_assessed_by_type == "3") {
                                                                            empName = `${this.contacts[topic_assessed_by_id]["ContactName"]} has no access role`
                                                                        }
                                                                    }
    
                                                                    else {
                                                                        empName = this.contacts[topic_assessed_by_id]["ContactName"]
                                                                    }
    
                                                                    return (
                                                                        <tr>
                                                                            <td className={`assign-assessment-confirmation-first-td ${(topic_assessed_by_id == 0 || has_no_access_error) ? 'assign-assessment-td-error' : 'assign-assessment-td-no-error'}`}>{empName}</td>
                                                                            <td className='assign-assessment-confirmation-td'>{topic_assessed_by_type == 3 ? "Self Assessed" : this.props.employee_name}</td>
                                                                            <td className='assign-assessment-confirmation-td'>{obj.topic_name}</td>
                                                                        </tr>
                                                                    );
                                                                }
                                                                
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                        </>
                                    )
                                })
                            }
                        </div>

                        <div className='assign-assessment-confirmation-modal-label'>Comments</div>
                        <textarea className='assign-assessment-text-area' name="textarea" cols="30" rows="5"
                            placeholder='Add explanatory text or guidence notes. All recipients will see this.'
                            value={this.state.comment}
                            onChange={this.handleChange}
                        ></textarea>

                    </div>
                    <div className='assign-assessment-confirmation-footer'>
                        <button
                            className={`assign-assessment-confirmation-btn assign-assessment-submit-button ${this.state.hasError ? 'submit-btn-error' : 'submit-btn-no-error'}`}
                            onClick={(e) => this.assignAssessmentSubmit(e)}
                            disabled={this.state.hasError}
                            id="submit"
                        >
                            Assign
                        </button>
                        <button className='assign-assessment-confirmation-btn assign-assessment-conf-cancel-button'
                            onClick={(e) => this.closeView(e)}>Cancel</button>
                    </div>
                </div>

                {
                    this.state.alert_param != null &&
                    <div class="employee-edit-modal">
                        <div class="employee-edit-modal-content">
                            <div class="employee-edit-modal-header">
                                <span class="employee-edit-modal-title">{this.state.alert_param.title}</span>
                            </div>
                            <div class="employee-edit-modal-body">
                                <p>{this.state.alert_param.message}</p>
                            </div>
                            <div class="employee-edit-modal-footer">
                                <button class="employee-edit-modal-button" onClick={this.confirmHandler}>OK</button>
                            </div>
                        </div>
                    </div>
                }
            </div >

        );
    }
}

export default AssessmentConfimationModal;
