import React, { Component } from 'react'
import CertificateDownloadLink from '../Certifications/CertificateDownloadLink';
const pdf_icon = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzODQgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuNC4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNNjQgMEMyOC43IDAgMCAyOC43IDAgNjRWNDQ4YzAgMzUuMyAyOC43IDY0IDY0IDY0SDMyMGMzNS4zIDAgNjQtMjguNyA2NC02NFYxNjBIMjU2Yy0xNy43IDAtMzItMTQuMy0zMi0zMlYwSDY0ek0yNTYgMFYxMjhIMzg0TDI1NiAwek0yMTYgMjMyVjMzNC4xbDMxLTMxYzkuNC05LjQgMjQuNi05LjQgMzMuOSAwczkuNCAyNC42IDAgMzMuOWwtNzIgNzJjLTkuNCA5LjQtMjQuNiA5LjQtMzMuOSAwbC03Mi03MmMtOS40LTkuNC05LjQtMjQuNiAwLTMzLjlzMjQuNi05LjQgMzMuOSAwbDMxIDMxVjIzMmMwLTEzLjMgMTAuNy0yNCAyNC0yNHMyNCAxMC43IDI0IDI0eiIvPjwvc3ZnPg==';

class AssessmentCertification extends Component {

    state = { 
        assessment_name : "",
        pdf_size : "",
        gen_date: ""
    }

    getSize = (file_info) =>{
        let pdf_size = '';
        if(file_info.size > 1024) pdf_size = `${(file_info.size/1024).toFixed(2)} MB`;
        else pdf_size = `${file_info.size} kB`;
        let gen_date = file_info.date;
        this.setState({pdf_size, gen_date});
    }

    getCertficateRecords = () =>{
        let assessment_gcs = this.props.assessment_gcs??"0";// default to parent
        assessment_gcs = assessment_gcs == '' ? '0': assessment_gcs;
        const tr_data= assessment_gcs?.split(",").map((gc_id,index) => {
            return (
                <tr className='assessment-certtification-data' key={index}>
                            <td>
                                <img src={pdf_icon} alt='Certificate PDF' className='assessment-certtification-pdf' />
                            </td>
                            <td>
                                <CertificateDownloadLink gc_id= {gc_id} assessment_name={this.props.assessment_name} assessment_id={this.props.assessment_id} assignment_id={this.props.assignment_id} getSize={this.getSize}/>
                            </td>
                            <td>{this.props.assessment_name}</td>
                            <td>{this.state.pdf_size}</td>
                            <td>{this.state.gen_date}</td>
                        </tr>
            )
         });
         return tr_data;
    }
    render() {
        return (
            <div style={{height : "68vh"}}>
                <div style={{position: "relative", border : "1px solid #ccc", margin : "7% auto", width : "60%"}}>
                    <div style={{position: "relative", width : "100%", padding : "15px", fontSize : "1.1rem", fontWeight : "600"}}>
                        Certification - This Assessment
                    </div>
                    <table className='assessment-certtification-table'>
                        <tr className='assessment-certtification-header'>
                            <td></td>
                            <td>Name</td>
                            <td>Assessment</td>
                            <td>Size</td>
                            <td>Generated Date</td>
                        </tr>
                        {
                            this.getCertficateRecords()
                        }
                    </table>
                </div>
            </div>
        )
    }
}

export default AssessmentCertification;
