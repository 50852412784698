import React from 'react';
import styled from 'styled-components';
import * as Style from '../Common/StyledComponents';
import {MdClose} from 'react-icons/md';
import DatePicker from 'react-date-picker';
import AlertBox from '../Common/AlertBox';


const CSLDateCover = styled.div`
    width: 200px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
`

const moment = require('moment');

class OverlapReasonModal extends React.Component{
    state = {
        subtitle_msg : "",
        from_nodate : true,
        to_nodate : true,
        date_range_data : {},
		ready : false,
		date_range_data_from_prop : {},
		alert_param : null
    }

	componentDidMount()
	{
		this.initialiseState();
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.initialiseState();
		}
	}

	initialiseState()
	{
		console.log("Date Range Modal Props ", this.props);
		let data = JSON.parse(JSON.stringify(this.props.date_range_data));
		let from_nodate = data.from === -1 ? true : false;
		let to_nodate = data.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? true : false;
		data.from = data.from === -1 ? null : this.toDateObject(data.from);
		data.to = data.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? null : this.toDateObject(data.to);
		let title = "reason_type" in this.props.date_range_data && this.props.date_range_data.reason_type === "sad" ? "Shared / Divided Date Range" : "Temporary Shared Date Range Reason"
		let subtitle_msg = "reason_type" in this.props.date_range_data && this.props.date_range_data.reason_type === "sad" ? "This role was shared/divided during the date range shown for the following reason" : "reason_type" in this.props.date_range_data && this.props.date_range_data.reason_type === "ovr" ? "This assignment was allowed according to temporary overlap window with following reason" : "Please provide a reason why this role can be shared temporarily during this date range."
		this.setState({from_nodate, to_nodate, title, subtitle_msg, ready : true, date_range_data : data});
	}

	closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
	}

	onFromDateChange = (date) => {
	}

	onToDateChange = (date) => {
	}

	changeClear = () => {
		this.setState({ isclear: false })
	}


	toDateObject = (date) =>{
		let date_str = (date%100).toString() + "-" + Math.floor((date%10000)/100).toString() + "-" + Math.floor(date/10000).toString();
		// console.info("date_str:", date_str);
		let date_obj = moment(date_str, 'DD-MM-YYYY').toDate();
		// console.info("date_obj:", date_obj);
		return date_obj;
	}

	toDateValueAsDatabase = (date) => {
		let ret = moment(date).format('YYYY') + moment(date).format("MM") + moment(date).format("DD")
		console.log("RET : " , ret);
		return parseInt(ret);
	}

	getReason = (e) =>{
		e.preventDefault();
		let date_range_data = JSON.parse(JSON.stringify(this.state.date_range_data));
		date_range_data.from = 0;
		date_range_data.from = this.state.from_nodate === true ? -1 : this.toDateValueAsDatabase(this.state.date_range_data.from);
		date_range_data.to = 0;
		date_range_data.to = this.state.to_nodate === true ? parseInt(process.env.REACT_APP_NO_END_DATE) : this.toDateValueAsDatabase(this.state.date_range_data.to);
		console.log("saveDateRange data : ",date_range_data);
		this.props.getReason(date_range_data);
	}

	reasonOnChange = (e) =>{
		e.preventDefault();
		let date_range_data = JSON.parse(JSON.stringify(this.state.date_range_data));
		date_range_data.reason = e.target.value;
		date_range_data.to = date_range_data.to != null ? new Date(date_range_data.to) : null;
		date_range_data.from = date_range_data.from !== null ? new Date(date_range_data.from) : null;
		this.setState({date_range_data});
	}

	closeAlert = () => {

		this.setState({ alert_param : null})
	}

    render()
	{
		console.log("STate : ", this.state);
		if(this.state.ready !== true) return (<div></div>);
		return (
			<div>
                <Style.ModalContainer style={{color:"#1B3451",top:"13vh", width: "90%", margin : "0px 5% 0px 5%"}}>
                    <Style.ModalHeader style={{color:"#1B3451"}}>
                    	 <div style={{fontSize: "20px", float: "left", marginTop: "15px", marginLeft: "30px"}}>
                            {this.state.title}
                    	 </div>

        	         	<div style={{fontSize: "13px", marginTop: "50px", marginLeft: "30px", marginBottom : "15px", marginRight : "60px"}}>
	                    	{this.state.subtitle_msg}
	                    </div>

                        <Style.ModalHeaderCloseBtn style={{fontSize: "19px"}} onClick={this.closeModal}><MdClose /></Style.ModalHeaderCloseBtn>
                    </Style.ModalHeader>
                    <Style.ModalBody>
						{
							(()=>{
								return(
									<div style={{paddingLeft: "28px", color: "red"}}>
										{this.state.error_msg}
									</div>
									)
							})()
						}
						<div style={{display : "flex", flexDirection : "row", justifyContent : "space-between", alignItems : "center", width: "90%", margin : "auto"}}>
							<div style={{display : "flex", flexDirection : "column", justifyContent : "flex-start", width : "44%"}}>
								<div style={{fontSize: "14px", marginTop: "5px", fontWeight: "700"}}>
									From :
								</div>
								{
									(()=>{
										if(this.state.from_nodate){
											return <div style={{width : "80%", height : "30px", border : "1px solid #bbb", borderRadius : "3px",marginTop : "10px", backgroundColor : "#eee" }}/>
										}else{
											return(
												<CSLDateCover style={{marginTop : "10px"}}>
												<DatePicker
														onChange={this.onFromDateChange}
														clearIcon={null}
														calendarIcon={null}
														locale={"en-GB"}
														value={this.state.date_range_data.from}
														disabled = {true}
												/>
												</CSLDateCover>
											)
										}
									})()
								}

							</div>
							<div style={{display : "flex", flexDirection : "column", justifyContent : "flex-start", width : "44%"}}>
								<div style={{fontSize: "14px", marginTop: "5px", fontWeight: "700"}}>
									To :
								</div>
								{
									(()=>{
										if(this.state.to_nodate){
											return <div style={{width : "80%", height : "30px", border : "1px solid #bbb", borderRadius : "3px",marginTop : "10px", backgroundColor : "#eee"}}/>
										}else{
											return(
												<CSLDateCover style={{marginTop : "10px"}}>
												<DatePicker
														onChange={this.onToDateChange}
														clearIcon={null}
														calendarIcon={null}
														locale={"en-GB"}
														value={this.state.date_range_data.to}
														disabled = {true}
												/>
												</CSLDateCover>
											)
										}
									})()
								}

							</div>
						</div>
                    	<div style={{fontSize: "14px", marginTop: "5px", marginLeft: "30px", fontWeight: "700"}}>
							Reason :
						</div>
						<Style.ModalTextArea type="text" name="reason" style={{width: "89%",height: "70px",margin: "7px", marginLeft:"30px"}} onChange={this.reasonOnChange} value={this.state.date_range_data.reason} />
                    </Style.ModalBody>
                    <div style={{width: "100%", height: "2px", backgroundColor: "#EBEBEB", marginTop: "20px"}}></div>
                    <div style={{textAlign: "right", marginTop: "20px"}}>
						{
							"reason_type" in this.props.date_range_data === false &&
							<Style.ConfigBtn style={{backgroundColor: "#FFFFFF", borderColor:"#8D8989", color:"#8D8989"}} onClick={this.closeModal}>Cancel</Style.ConfigBtn>
						}

                    	{
                    		(()=>{
								if("reason_type" in this.props.date_range_data === false) {
									if(this.state.date_range_data?.reason?.length > 0){
										return <Style.ConfigBtn style={{marginLeft: "15px", marginRight: "15px", backgroundColor: "#18B8A5"}} onClick={this.getReason}>SUBMIT</Style.ConfigBtn>
									}else{
										return <Style.ConfigBtn style={{marginLeft: "15px", marginRight: "15px", backgroundColor: "#888888", borderColor:"#666666"}}>SUBMIT</Style.ConfigBtn>
									}
								}
								else{
									return <Style.ConfigBtn style={{marginLeft: "15px", marginRight: "15px", backgroundColor: "#18B8A5"}} onClick={this.closeModal}>OK</Style.ConfigBtn>
								}
                    		})()
                    	}

                    </div>
                    <div style={{width: "100%", height: "1px", backgroundColor: "#EBEBEB", marginTop: "20px"}}></div>
                </Style.ModalContainer>
				<AlertBox alertParam={this.state.alert_param} />
            </div>
		);
	}
}

export default OverlapReasonModal;
