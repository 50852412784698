import React from 'react';
import APICall from "../../Common/APICall";
import moment from 'moment';
import { saveAs } from 'file-saver';
import AlertBox from '../Common/AlertBox';

class CertificateDownloadLink extends React.Component{
    state = {
        file_name : "",
        file_size : "",
        file_data : null,
        alert_param : null,
        ready : false
    };

    intialize = async () => {
        const api = new APICall();
        let res = await api.commandWithoutCallback({command : 'get_assess_cert_pdf_data', gc_id: this.props.gc_id, assessment_id : this.props.assessment_id, assignment_id : this.props.assignment_id});
        if(res.error_code === 0){
            let file_name = res.result.file_name;
            let file_size = res.result.cert_data_size;
            let file_data = res.result.cert_data;    
            this.setState({file_name, file_size, file_data, ready : true});
            let file_info = {size : file_size, date : moment.unix(res.result.cert_gen_date).format('DD/MM/YYYY')};
            this.props.getSize(file_info);
        }else{
            let alert_param = { title: 'Failure', message: "Ceritificate could not be downloaded", ok_text: 'OK', confirm: false, alertHandler: this.closeAlert }
            this.setState({ alert_param });
        }
    }

    closeAlert = () => {
        this.setState({ alert_param : null});
    }

    componentDidMount(){
        this.intialize();
    }

    downloadBase64File = () => {
        let base64String = this.state.file_data;
        let filename = this.state.file_name;
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        saveAs(blob, filename);
    }



    render(){
        return(
            <div style={{display : "inline-block"}}>
                {
                    (()=>{
                        if(this.state.ready === false){
                            return <span style={{fontWeight : "600", fontSize : "0.8rem"}}>Loading...</span>
                        }else{
                            return <a href='#' onClick={this.downloadBase64File} style={{fontWeight : "600", fontSize : "0.8rem"}}>{this.state.file_name}</a>
                        }
                    })()
                }
                <AlertBox alertParam={this.state.alert_param} />
            </div>
        )
    }
}

export default CertificateDownloadLink;