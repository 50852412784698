import React from "react";
import moment from 'moment';
import "./AssessmentStyle.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, thin, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import APICall from "../../Common/APICall";
import CSLLoader from '../Common/CSLLoader';
import AssignAssesment from "./AssignAssesment";
import CSLTable from "../Common/CSLTable"
import AssessmentDetails from "../AssessmentDetails/AssessmentDetails";
import Menu from '../Common/Menu';
import RescheduleAssessment from './RescheduleAssessment';
import ReAssessmentModal from './ReAssessmentModal';
import Store from '../../Common/Store.js';
import ExcelGenerator from '../Common/ExcelGenerator';
import AlertBox from '../Common/AlertBox';
import DefaultHeader from "../../Common/DefaultHeader";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import AssessmentDeleteModal from "./AssessmentDeleteModal";

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

class AssessmentOverview extends React.Component {
    constructor (props) {
        super(props);
        this.logged_in_user_role = "";
        this.assessmet_display_data = [];
        this.assessment_backup_data = [];
        this.assessments_status = {
            "over_due": 0,
            "due_now": 0,
            "in_progress": 0,
            "for_approval": 0,
            "completed": 0
        }
    }
    // used for assign assessment for projected(FAKE) rows
    AssignAssesmentSelectAssessmentDropDown = React.createRef();

    state = {
        ready: false,
        selected_assessment_id: 0,
        selected_assignment_id: 0,
        selected_user_id: 0,
        show_reassign_modal: false,
        is_assessment_modal_opened: false,
        show_reschedule_modal: false,
        show_delete_assessment_modal: false,
        is_assessment_details_modal_open: false,
        selected_runs_every: '',
        selected_assessment_overall_acessor: '',
        selected_assessment_name: '',
        reassessment_window: 20,
        assessment_warning_window: 30,
        header_context_visible: false,
        selected_due_date: 0,
        selected_re_schedule_date: 0,
        alert_param: null,
        selected_assessment: null,
    };


    componentDidMount() {
        this.initialiseState();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.initialiseState();
        }
    }

    initialiseState = async () => {
        this.setState({ ready: false })
        let data = [];
        let user_role = Store.getStoreData('role');

        console.log('user_role', user_role);

        const postData = { command: "assessments_details", action: 'get_assessment_dashboard_data', user_role: user_role, assignment_id: 0 };
        const api = new APICall();
        let assessment_response = await api.commandWithoutCallback(postData);
        let assessment_data = assessment_response.result.assessment_details;
        let mod_obj = assessment_response.result.module_object.config_json;
        mod_obj = JSON.parse(mod_obj);

        let has_gc = assessment_response.result.has_gc.result.has_gc.has_group_company;

        const assest_project_window_value = mod_obj.hasOwnProperty("assest_project_window")
            ? mod_obj.assest_project_window
            : 365;
        const assest_ReAssessment_window_value = mod_obj.hasOwnProperty("reassest_window")
            ? mod_obj.reassest_window
            : 20;
        const assest_warning_window_value = mod_obj.hasOwnProperty("assest_warn_window")
            ? mod_obj.assest_warn_window
            : 30;
        const cts = JSON.parse(localStorage.getItem("contactlist"));
        let contacts = cts.result.contactlist;
        assessment_data.forEach((assessment) => {
            let { StatusColumn, CertifiedColumn } = this.getStatusAndCertifiedColumns(assessment);
            let cert_mgr_name = this.getCertMgrNames(assessment);
            let assessmentObj = {
                ...assessment,
                assessment_certified: CertifiedColumn,
                assessment_status: StatusColumn,
                assigned_to_name: assessment["assign_to"] != 0 ? contacts[assessment["assign_to"]]["ContactName"] : "",
                cert_manager_names: cert_mgr_name
            }
            if (assessmentObj.assign_to != 0) {
                let origianl_faked_assessment = this.createProjectedAssessments(assessmentObj, assest_project_window_value);
                data.push(...origianl_faked_assessment);
            }
        });

        // Set the assessment to active if it's completed or due
        for (let i=0; i<data.length; i++) {
            data[i].is_active = true

            if (data[i]?.assessment_status === 'Scheduled') {
                data[i].is_active = false
            }
            if (data[i]?.assessment_status === 'Due now') {
                const dueDate = data[i].due_date * 1000
                const currentDate = Date.now()
                const monthsTime = new Date(currentDate).setMonth(new Date(currentDate).getMonth() + 1)
                if (dueDate <= monthsTime) {
                    continue
                }
                data[i].is_active = false
            }
        }

        localStorage.setItem('has_gc', has_gc);
        this.assessmet_display_data = data;
        this.assessment_backup_data = data;
        this.logged_in_user_role = user_role;

        this.setState({ ready: true, reassessment_window: assest_ReAssessment_window_value, assessment_warning_window: assest_warning_window_value, assest_project_window_value });
    }

    getCertMgrNames = (assessment) => {
        const cts = JSON.parse(localStorage.getItem("contactlist"));
        let contacts = cts.result.contactlist;
        let assessment_assessed_by_user_names = '';
        if (assessment.assessment_assessed_by.includes(',')) {
            let uniqueAssessmentAssessedBy = assessment.assessment_assessed_by.split(',').filter(function (item, pos, self) {
                return self.indexOf(item) == pos;
            }).toString();
            if (uniqueAssessmentAssessedBy.includes(',')) {
                let assessment_assessed_by_array = uniqueAssessmentAssessedBy.split(',');
                assessment_assessed_by_array.forEach((userid) => {
                    if (contacts.hasOwnProperty(userid)) {
                        assessment_assessed_by_user_names += contacts[userid]["ContactName"] + ',';
                    }
                })
                assessment_assessed_by_user_names = assessment_assessed_by_user_names.slice(0, -1);

            } else {
                assessment_assessed_by_user_names = contacts[uniqueAssessmentAssessedBy]["ContactName"];
            }

        } else {
            assessment_assessed_by_user_names = assessment.assessment_assessed_by != 0 ? contacts[assessment.assessment_assessed_by]["ContactName"] : "";
        }
        return assessment_assessed_by_user_names;
    }

    showAssignAssessmentModal = () => {
        this.setState({ is_assessment_modal_opened: true, selected_assessment_id: 0 })
    }
    closeModal = () => {
        this.setState({ 
            is_assessment_modal_opened: false, 
            show_delete_assessment_modal: false,
            selected_assessment: null,
            selected_user_id: 0,
        }, () => {
            this.initialiseState();
        })
    }

    goToAssessmentDetails = (assessment_id, assignment_id, user_id, assessment_assessed_by, topics_accessed_by, runs_every, assessment_name, status, is_physical_assessment) => {
        if (is_physical_assessment == 0) {
            return;
        }

        const userRole = this.logged_in_user_role;
        const contact_id = Store.getStoreData('logged_in_contact_id');
        const regex = new RegExp(`\\b${contact_id}\\b`);

        const isOverAllorIsTopicAccessed = regex.test(assessment_assessed_by) || regex.test(topics_accessed_by);

        switch (userRole) {
            case 'team':
            case 'hr':
                if (status === 'Certified' || isOverAllorIsTopicAccessed) {
                    this.setState(getAssessmentDetailsState());
                }
                break;
            case 'admin_manager':
                this.setState(getAssessmentDetailsState());
                break;
            default:
                break;
        }

        function getAssessmentDetailsState() {
            return {
                selected_assessment_id: assessment_id,
                is_assessment_details_modal_open: true,
                selected_assignment_id: assignment_id,
                selected_user_id: user_id,
                selected_assessment_overall_acessor: assessment_assessed_by,
                selected_runs_every: runs_every,
                selected_assessment_name: assessment_name,
            };
        }
    }

    assignAssessment = (assessment_id, user_id) => {
        this.setState({ selected_assessment_id: assessment_id, is_assessment_modal_opened: true, selected_user_id: user_id })
    }

    reAssignAssessment = (assessment_id) => {
        this.setState({ selected_assessment_id: assessment_id, show_reassign_modal: true })
    }

    rescheduleAssessment = (assessment_id, assignment_id, re_schedule_date, due_date) => {
        this.setState({
            selected_assessment_id: assessment_id,
            selected_assignment_id: assignment_id,
            show_reschedule_modal: true,
            selected_re_schedule_date: re_schedule_date,
            selected_due_date: due_date
        })
    }

    deleteAssessment = (assessment_id, assignment_id) => {
        let msg = 'Are you sure you want to delete this record?';
        let alert_param = { title: 'Confirm', message: msg, ok_text: 'OK', confirm: false, alertHandler: this.deleteAssessmentConfirm, confirm: true, cancel_text: 'Cancel' };
        this.setState({
            selected_assessment_id: assessment_id,
            selected_assignment_id: assignment_id,
            alert_param: alert_param
        })
    }

    deleteAssessmentConfirm = async (status) => {
        if (status) {
            const postData = {
                command: "assessments_details",
                action: 'delete_assessment_from_dashboard',
                assessment_id: this.state.selected_assessment_id,
                assignment_id: this.state.selected_assignment_id
            };
            console.log('post data ==>', postData);
            const api = new APICall();
            let assessment_response = await api.commandWithoutCallback(postData);
            let is_deleted = assessment_response.result.deleted
            if (is_deleted == 1) {
                let msg = 'Assessment deleted successfully';
                let alert_param = { title: 'Confirm', message: msg, ok_text: 'OK', confirm: false, alertHandler: this.reloadAfterDeletion };
                this.setState({
                    alert_param: alert_param
                })
            }
        } else {
            this.setState({ alert_param: null })
        }
    }

    reloadAfterDeletion = () => {
        this.setState({ alert_param: null }, () => {
            this.initialiseState();
        })
    }

    closeReAssessmentModal = () => {
        this.setState({ show_reassign_modal: false })
    }

    closeRescheduleAssessment = () => {
        this.setState({ show_reschedule_modal: false }, () => {
            this.initialiseState();
        })
    }

    showDeleteAssessmentModal = (assessment) => {
        this.setState({ show_delete_assessment_modal: true })
        this.setState({ selected_assessment: assessment })
    }

    processAssessmentOverviewData = () => {
        let has_gc = JSON.parse(localStorage.getItem('has_gc'))
        let table_data = this.assessmet_display_data;
        console.log('table_data ==>', table_data);
        const cts = JSON.parse(localStorage.getItem("contactlist"));
        let contacts = cts.result.contactlist;


        let ret = { data: [], columns: [] }
        ret.columns = [
            {
                Header: 'ASSESSMENT NAME', accessor: 'assessment_name', filterable: true,
                Cell: (row) => {
                    let assessment_id = row.original.assessment_id;
                    let assignment_id = row.original.assignment_id;
                    let assign_to = row.original.assessment_assigned_to;
                    let over_all_acessor = row.original.assessment_cert_managers;
                    let runs_every = row.original.assessment_runs_every;
                    let assessment_name = row.original.assessment_name;
                    let assessment_status = row.original.assessment_status;
                    let topic_accessed_by = row.original.assessment_employee;
                    let is_physical_assessment = row.original.is_physical_assessment;
                    const is_active = row.original.is_active
                    const handleClick = () => {
                        if (!is_active) {
                            return
                        }
                        this.goToAssessmentDetails(assessment_id, assignment_id, assign_to, over_all_acessor, topic_accessed_by, runs_every, assessment_name, assessment_status, is_physical_assessment)
                    }

                    return (
                        <div className={`${is_active ? 'assessment-overview-assessment-name' : ''}`} onClick={() => handleClick()}>
                            {row.original.assessment_name}
                        </div>
                    )
                }
            },
            { Header: 'STATUS', accessor: 'assessment_status', filterable: true },
            {
                Header: 'ASSIGNED TO', accessor: 'assessment_employee', filterable: true,
                Cell: (row) => {
                    if (row.original.assessment_employee.includes(',')) {
                        let topic_assessed_by_user_names = '';
                        //first remove duplicate from comma seperated string and then convert into array

                        let uniqueTopicAssessedBy = row.original.assessment_employee.split(',').filter(function (item, pos, self) {
                            return self.indexOf(item) == pos;
                        }).toString();

                        if (uniqueTopicAssessedBy.includes(',')) {
                            let topic_assessed_by_array = uniqueTopicAssessedBy.split(',');

                            topic_assessed_by_array.forEach((userid) => {
                                if (contacts.hasOwnProperty(userid)) {
                                    topic_assessed_by_user_names += contacts[userid]["ContactName"] + ',';
                                }
                            })
                            topic_assessed_by_user_names = topic_assessed_by_user_names.slice(0, -1);// remove last comma
                            return (
                                <a title={topic_assessed_by_user_names} className='multiple-hover-title'>Multiple</a>
                            )
                        } else {
                            let topicAssessedByName = contacts[uniqueTopicAssessedBy]["ContactName"];

                            return (
                                <span>{topicAssessedByName}</span>
                            )
                        }

                    } else {
                        let topicAssessedByName = row.original.assessment_employee != 0 ? contacts[row.original.assessment_employee]["ContactName"] : "";

                        return (
                            <span>{topicAssessedByName}</span>
                        )
                    }
                }
            },
            {
                Header: 'EMPLOYEE', accessor: 'assessment_assigned_to_name', filterable: true, sortMethod: (a, b) => {
                    return a.localeCompare(b);
                }
            },
            {
                Header: 'GROUP COMPANY', accessor: 'assessment_legal_entity', filterable: true, show: has_gc,
                Cell: (row) => {
                    if (row.original.assessment_legal_entity.includes(',')) {
                        let companyNames = '';
                        let uniqueCompanies = row.original.assessment_legal_entity.split(',').filter(function (item, pos, self) {
                            return self.indexOf(item) == pos;
                        }).toString();
                        let companyNamesArray = uniqueCompanies.split(',');
                        companyNamesArray.forEach((companyName) => {
                            companyNames += companyName + ',';
                        })
                        companyNames = companyNames.slice(0, -1);
                        return (
                            <a title={companyNames} className='multiple-hover-title'>Multiple</a>
                        )

                    } else {
                        return (
                            <span>{row.original.assessment_legal_entity}</span>
                        )
                    }
                }
            },
            {
                Header: 'CERTIFICATION MANAGER', accessor: 'assessment_cert_manager_names', filterable: true,
                Cell: (row) => {
                    if (row.original.assessment_cert_manager_names.includes(',')) {
                        return (
                            <a title={row.original.assessment_cert_manager_names} className='multiple-hover-title'>Multiple</a>
                        )
                    } else {
                        return (
                            <span>{row.original.assessment_cert_manager_names}</span>
                        )
                    }
                }, sortMethod: (a, b) => {
                    const namesA = a.split(',').map(name => name.trim());
                    const namesB = b.split(',').map(name => name.trim());

                    const minLength = Math.min(namesA.length, namesB.length);

                    for (let i = 0; i < minLength; i++) {
                        const result = namesA[i].localeCompare(namesB[i]);

                        if (result !== 0) {
                            return result;
                        }
                    }

                    // Handle cases where one name has more parts than the other
                    if (namesA.length !== namesB.length) {
                        return namesA.length - namesB.length;
                    }

                    // If all names are equal, compare the original strings
                    return a.localeCompare(b);
                }
            },
            {
                Header: 'CERTIFIED', accessor: 'assessment_certified', filterable: true,
                sortMethod: ((a, b) => {
                    const isADueDate = a.startsWith('Due');
                    const isBDueDate = b.startsWith('Due');

                    if (isADueDate && isBDueDate) {
                        return 0; // If both are "Due" dates, maintain their original order
                    } else if (isADueDate) {
                        return 1; // If 'a' is a "Due" date, it should come after 'b'
                    } else if (isBDueDate) {
                        return -1; // If 'b' is a "Due" date, it should come after 'a'
                    } else {
                        const dateA = this.extractDate(a);
                        const dateB = this.extractDate(b);
                        return dateA.getTime() - dateB.getTime(); // Sort actual dates in ascending chronological order
                    }
                })
            },
            {
                'Header': '',
                Cell: row => {
                    const { assessment_assigned_to, assessment_id, assessment_status, assignment_id, assessment_reschedule_date, assessment_due_date, is_physical_assessment } = row.original;
                    const isCertified = assessment_status.toLowerCase() === 'certified';
                    const isRejected = assessment_status.toLowerCase() === 'rejected';
                    const canDelete = !isCertified && !isRejected

                    if (canDelete && this.logged_in_user_role === 'admin_manager') {
                        return (
                            <Menu style={{ cursor: 'pointer' }}>
                                <nav>
                                    <ul className="nav">
                                        <li key={3}>
                                            <a onClick={() => this.showDeleteAssessmentModal(row.original)}>Delete</a>
                                        </li>
                                    </ul>
                                </nav>
                            </Menu>
                        );
                    }
                    return null
                },
                width: 70,
                headerStyle: { textAlign: 'center' },
                filterable: false
            }

        ]


        table_data.forEach((item) => {
            //console.log('item ==>', item);
            let elem = {
                assessment_id: item.assessment_id,
                assignment_id: item.assignment_id,
                assessment_name: item.assessment_name,
                assessment_status: item.assessment_status,
                assessment_employee: item.topic_assessed_by,
                assessment_assigned_to: item.assign_to,
                assessment_assigned_to_name: item.assigned_to_name,
                assessment_legal_entity: item.company_name,
                assessment_cert_managers: item.assessment_assessed_by,
                assessment_cert_manager_names: item.cert_manager_names,
                assessment_cert_manager_labels: "Multiple",
                assessment_certified: item.assessment_certified,
                assessment_runs_every: item.runs_every_month,
                // for re-schedule date modal
                assessment_reschedule_date: item.rescheduled_date,
                assessment_due_date: item.due_date,
                is_physical_assessment: item.is_physical_assessment,
                original_assignment_id: item.original_assignment_id,
                should_project: item.should_project,
                due_date: item.due_date,
                is_active: item.is_active,
            };
            ret.data.push(elem);

        })
        return ret;
    }

    // createProjectedAssessments = (assessment, window = 365) => {
    //     debugger;
    //     const assessments = [];
    //     const projectedAssessments = [];
    //     const now = moment();
    //     const frequencyInMonths = assessment.runs_every_month;
    //     const dueDate = moment(parseInt(assessment.due_date) * 1000);
    //     const maxDueDate = moment().add(window, 'days');

    //     let nextDueDate = dueDate.add(frequencyInMonths, 'months');
    //     while (nextDueDate <= maxDueDate) {
    //         let dueDate = moment.unix(assessment.due_date);
    //         const projectedAssessment = {
    //             ...assessment,
    //             id: 0,
    //             user_id: 0,
    //             assessment_completed: false,
    //             assessment_status: "Scheduled",
    //             assign_to: 0,
    //             assignment_id: 0,
    //             due_date: nextDueDate.unix(),
    //             assessment_certified: `Due ${dueDate.format('DD/MM/YYYY')}`
    //         };
    //         projectedAssessments.push(projectedAssessment);
    //         nextDueDate = nextDueDate.add(frequencyInMonths, 'months');
    //     }

    //     assessments.push(assessment);
    //     return [...assessments, ...projectedAssessments];
    // }

    createProjectedAssessments = (assessment, window = 365) => {
        // debugger;
        const assessments = [];
        const projectedAssessments = [];
        const now = moment();
        const frequencyInMonths = assessment.runs_every_month;
        const dueDate = moment(parseInt(assessment.due_date) * 1000);
        const maxDueDate = moment().add(window, 'days');

        if (assessment.should_project === false) {
            return [assessment]
        }

        let nextDueDate = dueDate.clone().add(frequencyInMonths, 'months');
        while (nextDueDate <= maxDueDate && frequencyInMonths > 0) {
            const projectedAssessment = {
                ...assessment,
                id: 0,
                user_id: 0,
                assessment_completed: false,
                assessment_status: "Scheduled",
                //assign_to: 0,
                assignment_id: 0,
                original_assignment_id: assessment.assignment_id,
                is_physical_assessment: 0,
                due_date: nextDueDate.unix(),
                assessment_certified: `Due ${nextDueDate.format('DD/MM/YYYY')}`
            };
            projectedAssessments.push(projectedAssessment);
            nextDueDate = nextDueDate.clone().add(frequencyInMonths, 'months');
        }

        assessments.push(assessment);
        return [...assessments, ...projectedAssessments];
    }

    getStatusAndCertifiedColumns = (assessment) => {
        const statusArray = JSON.parse(assessment.assessment_status);
        const lastStatus = statusArray[statusArray.length - 1];
        const action = lastStatus.action;

        let statusColumn = '';
        let certifiedColumn = '';
        switch (action) {
            case 'COMP_SMCR_ASSIGNMENT':
                //const dueDate = assessment.hasOwnProperty('rescheduled_date') ? moment.unix(assessment.rescheduled_date): moment.unix(assessment.due_date);

                const dueDate = assessment.hasOwnProperty('rescheduled_date') && parseInt(assessment.rescheduled_date) > 0
                    ? assessment.rescheduled_date
                    : assessment.due_date;

                let now = moment();
                now.set('hour', 0);
                now.set('minute', 0);
                now.set('second', 0);
                now = now.unix();
                // console.log("parseInt(now) <= parseInt(dueDate)==>", parseInt(dueDate) >= parseInt(now))
                // console.log("parseInt(now) <= parseInt(dueDate)==>", parseInt(dueDate) > parseInt(now))
                if (parseInt(now) <= parseInt(dueDate)) {
                    let dueCount = this.assessments_status.due_now;
                    statusColumn = 'Due now';
                    certifiedColumn = `Due ${moment.unix(parseInt(dueDate)).format('DD/MM/YYYY')}`;
                    this.assessments_status.due_now = dueCount + 1;
                } else {
                    let odueCount = this.assessments_status.over_due;
                    statusColumn = 'Overdue';
                    certifiedColumn = `Due ${moment.unix(parseInt(dueDate)).format('DD/MM/YYYY')}`;
                    this.assessments_status.over_due = odueCount + 1;
                }
                break;

            case 'COMP_SMCR_IN_PROGRESS':
            case 'COMP_SMCR_REVIEW_REQUIRED':
                let ipCount = this.assessments_status.in_progress;
                const inProgressDueDate = assessment.hasOwnProperty('rescheduled_date') && parseInt(assessment.rescheduled_date) > 0
                    ? moment.unix(assessment.rescheduled_date)
                    : moment.unix(assessment.due_date);
                const now2 = moment();
                this.assessments_status.in_progress = ipCount + 1;
                if (now2 <= inProgressDueDate) {
                    statusColumn = 'In Progress';
                } else {
                    statusColumn = 'Overdue';
                }
                certifiedColumn = `Due ${inProgressDueDate.format('DD/MM/YYYY')}`;
                break;


            case 'COMP_SMCR_REJECTED':
                const rejectedDate = moment.unix(lastStatus.action_time);
                statusColumn = 'Rejected';
                certifiedColumn = `Rejected ${rejectedDate.format('DD/MM/YYYY')}`;
                break;

            case 'COMP_SMCR_CERTIFIED':
                let certCount = this.assessments_status.completed;
                this.assessments_status.completed = certCount + 1;
                const certifiedDate = moment.unix(lastStatus.action_time);
                statusColumn = 'Certified';
                certifiedColumn = certifiedDate.format('DD/MM/YYYY');
                break;

            case 'COMP_SMCR_FINAL_COMPLETION':
                let finalApprovalCount = this.assessments_status.for_approval;
                this.assessments_status.for_approval = finalApprovalCount + 1;
                const finalCompletionDueDate = assessment.hasOwnProperty('rescheduled_date') && parseInt(assessment.rescheduled_date) > 0
                    ? moment.unix(assessment.rescheduled_date)
                    : moment.unix(assessment.due_date);
                const now3 = moment();
                if (now3 <= finalCompletionDueDate) {
                    statusColumn = 'Final Completion';
                    certifiedColumn = `Due ${finalCompletionDueDate.format('DD/MM/YYYY')}`;
                } else {
                    statusColumn = 'Final Completion Overdue';
                    certifiedColumn = `Due ${finalCompletionDueDate.format('DD/MM/YYYY')}`;
                }
                break;
        }

        return { StatusColumn: statusColumn, CertifiedColumn: certifiedColumn };
    }

    prepareExportData = async () => {
        let ret = {export_data : [], merges : []};

        let api = new APICall();
        let postdata = {
            command : 'export_data',
            action : 'get_assessment_dashboard_export_data'
        };
        let res = await api.commandWithoutCallback(postdata);
        console.log("export res",res.result);
        let assessment_data = res.result;

        let data = [];

        const cts = JSON.parse(localStorage.getItem("contactlist"));

        let contacts = cts.result.contactlist;

        assessment_data.forEach((assessment) => {
            let { StatusColumn, CertifiedColumn } = this.getStatusAndCertifiedColumns(assessment);
            //let cert_mgr_name = this.getCertMgrNames(assessment);
            //console.log("StatusColumn, CertifiedColumn", StatusColumn, CertifiedColumn);
            //debugger;
            let assessmentObj = {
                ...assessment,
                assessment_certified: CertifiedColumn,
                assessment_status: StatusColumn,
                assigned_to_name: assessment["assessee_id"] != 0 ? contacts[assessment["assessee_id"]]["ContactName"] : "",
                //cert_manager_names: cert_mgr_name
            }
            if (assessmentObj.assign_to != 0) {
                let original_faked_assessment = this.createProjectedAssessments(assessmentObj, this.state.assest_project_window_value);
                data.push(...original_faked_assessment);
            }
        });
        console.log("====data===>", data);

        let ex_data = [];
        let merges = [];
        let row_start = 1;
        let new_row = 1;
        for(let d of data){
            let total_rows = d.cert_mgr.length > d.topic_dtl.length ? d.cert_mgr.length : d.topic_dtl.length;
            total_rows = total_rows === 0 ? 1 : total_rows;
            console.log("Row Start", row_start, "total_rows : ",total_rows);
            for(let c = 1; c <= total_rows; c++){
                let data_obj = {
                    "Assessment Name" : c === 1 ? d.assessment_name : '',
                    "Status" : c === 1 ? d.assessment_status : '',
                    "Employee" : c === 1 ? d.assigned_to_name : '',
                    "Group Company (Legal Entity)" : c === 1 ? "gcs" in d ? d.gcs.join(', ') : '' : '',
                    "Certification Manager" : c > d.cert_mgr.length === false ? contacts[d.cert_mgr[c-1].toString()]["ContactName"] : '' ,
                    "Due Date" : c === 1 ? d.assessment_status === "Final Completion Overdue" || d.assessment_status === "Overdue" || d.assessment_status === "Due now" || d.assessment_status === "Scheduled"? d.assessment_certified.split(' ')[1] : '' : '',
                    "Completed Date" : c === 1 ? d.assessment_status === "Certified" ? d.assessment_certified : d.assessment_status === "Rejected" ? d.assessment_certified.split(' ')[1] : '' : '',
                    "Topic Name" : c > d.topic_dtl.length === false ? d.topic_dtl[c-1].topic : '',
                    "Assessor Name" : c > d.topic_dtl.length === false ? d.topic_dtl[c-1].assessor !== null ? contacts[d.topic_dtl[c-1].assessor.toString()]["ContactName"] : '' : ''
                }
                ex_data.push(data_obj);
            }

            if (total_rows > 1) merges.push(
                { s : { r : row_start, c : 0 }, e : { r : row_start+total_rows - 1, c : 0}},
                { s : { r : row_start, c : 1 }, e : { r : row_start+total_rows - 1, c : 1}},
                { s : { r : row_start, c : 2 }, e : { r : row_start+total_rows - 1, c : 2}},
                { s : { r : row_start, c : 3 }, e : { r : row_start+total_rows - 1, c : 3}},
                { s : { r : row_start, c : 5 }, e : { r : row_start+total_rows - 1, c : 5}},
                { s : { r : row_start, c : 6 }, e : { r : row_start+total_rows - 1, c : 6}},
                );
                row_start += total_rows;
            }
        ret.export_data = ex_data;
        ret.merges = merges;
        console.log("====data===>", merges);
        return ret;
    }

    exportToExcel = async (event) => {
        event.preventDefault();
        //let wb = { SheetNames: [], Sheets: {} };
        let ret_data = await this.prepareExportData();
        let ex_data = ret_data.export_data;
        let ws = XLSX.utils.json_to_sheet(ex_data);
        ws["!merges"] = ret_data.merges;
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Assessments");
        var wbout = XLSX.write(wb, {bookType:'xlsx', type:'array'});

        // wb.SheetNames.push("Employees");
        // wb.Sheets["Employees"] = XLSX.utils.json_to_sheet(ex_data);
        const format = "DD-MM-YYYY HH:mm:ss";
        let tm = moment().format(format);
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "SM  & CR - Assessments" + "-" + tm + fileExtension);
    };

    extractDate = (dateString) => {
        const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        const dueDatePattern = /Due (\d{2})\/(\d{2})\/(\d{4})/;

        if (dateString.startsWith('Due')) {
            const [, day, month, year] = dueDatePattern.exec(dateString);
            const dueDate = new Date(`${year}-${month}-${day}`);
            dueDate.setDate(dueDate.getDate() + 1); // Add one day to "Due" dates
            return dueDate;
        } else {
            const [, day, month, year] = pattern.exec(dateString);
            return new Date(`${year}-${month}-${day}`);
        }
    };

    closeAssessmentDetailsModal = () => {
        this.setState({ is_assessment_details_modal_open: false })
    }

    openCloseContexMenu = (event) => {
        event.preventDefault()
        let header_context_visible = this.state.header_context_visible === true ? false : true;
        this.setState({ header_context_visible });
    }

    closeHeaderContextMenu = (e) => {
        e.preventDefault();
        this.setState({ header_context_visible: false });
    }

    render() {

        if (!this.state.ready) {
            return (<div><CSLLoader /></div>);
        }
        const overrideRolesRespoStyle = {
            position: 'absolute',
            backgroundColor: '#f3f0f0',
            padding: '5px 5px',
            display: 'inline-block',
            width: '150px',
            right: '7px',
            boxShadow: '0px 0px 4px rgba(0,0,0,0.4)',
            zIndex: 10000,
            boxSizing: 'border-box',
        };
        return (
            <div className="assessment-dashboard-container">
                <DefaultHeader pageName="Assessment Dashboard" />
                {
                    this.state.is_assessment_modal_opened === true &&
                    <div className="interactive-overlay">
                        <AssignAssesment
                            closeModal={this.closeModal}
                            selectedAssessmentId={this.state.selected_assessment_id}
                            selectedUserId={this.state.selected_user_id}
                        />
                    </div>
                }

                {
                    this.state.is_assessment_details_modal_open === true &&
                    <div className="interactive-overlay">
                        <AssessmentDetails
                            assessment_id={this.state.selected_assessment_id}
                            assignment_id={this.state.selected_assignment_id}
                            user_id={this.state.selected_user_id}
                            over_all_acessor={this.state.selected_assessment_overall_acessor}
                            runs_every={this.state.selected_runs_every}
                            closeModal={this.closeAssessmentDetailsModal}
                            reAssessmentWindow={this.state.reassessment_window}
                            assessmentWarningWindow={this.state.assessment_warning_window}
                            is_open_from_query_str={false}
                        />
                    </div>
                }

                {
                    this.state.show_reschedule_modal === true &&
                    <div className="interactive-overlay">
                        <RescheduleAssessment
                            assessment_id={this.state.selected_assessment_id}
                            assignment_id={this.state.selected_assignment_id}
                            closeModal={this.closeRescheduleAssessment}
                            re_schedule_date={this.state.selected_re_schedule_date}
                            due_date={this.state.selected_due_date}
                        />
                    </div>
                }

                {
                    this.state.show_reassign_modal === true &&
                    <div className="interactive-overlay">
                        <ReAssessmentModal
                            assessment_id={this.state.selected_assessment_id}
                            closeModal={this.closeReAssessmentModal}
                        />
                    </div>
                }
                
                {
                    this.state.show_delete_assessment_modal === true &&
                    <div className="interactive-overlay">
                        <AssessmentDeleteModal
                            assessment={this.state.selected_assessment}
                            closeModal={this.closeModal}
                        />
                    </div>
                }

                {/* For MVP Removing this */}

                {/* <div className="header-action-container">
                    <FontAwesomeIcon className="assessment-grid-action" title="Action" icon={solid('grid')} />
                    <FontAwesomeIcon className="assessment-ellipse-action" title="Action" icon={solid('ellipsis-vertical')} />
                </div> */}

                <div className="assessment-dashboard-body-container">
                    <div className="assessment-card-container">
                        <div className="assessment-section">
                            <div className="button-container-new">
                                {this.logged_in_user_role != 'team' && <button id='assign-assessment' className="btn-assign-assessment" onClick={this.showAssignAssessmentModal}>Assign Assessment</button>}
                            </div>
                            {/* For MVP Removing these cards */}
                            {/* <div className="cards-container">
                                <div className="card">
                                    <p className="card-header-text">Overdue</p>
                                    <p className="card-body-text">{this.assessments_status.over_due}</p>
                                </div>
                                <div className="card">
                                    <p className="card-header-text">Due Now</p>
                                    <p className="card-body-text">{this.assessments_status.due_now}</p>
                                </div>
                                <div className="card">
                                    <p className="card-header-text">In Progress</p>
                                    <p className="card-body-text">{this.assessments_status.in_progress}</p>
                                </div>
                                <div className="card">
                                    <p className="card-header-text">For Approval</p>
                                    <p className="card-body-text">{this.assessments_status.for_approval}</p>
                                </div>
                                <div className="card">
                                    <p className="card-header-text">Complete</p>
                                    <p className="card-body-text">{this.assessments_status.completed}</p>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div className="assessment-table-container">
                        <div className="assessment-table-header-container">
                            <span className="table-title">ASSESSMENTS</span>
                            {/* <span className="table-action">
                                <span className="icon-color">
                                    <Menu style={{ cursor: 'pointer' }}>
                                        <nav>
                                            <ul className="nav">
                                                <li key={1}>
                                                    <a onClick={(e) => this.exportAssessment(e)} >Export</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </Menu>
                                </span>
                            </span> */}
                            <div style={{ position: 'relative', top: '8px', left: '-26px' }}>

                                <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('ellipsis-vertical')} onClick={this.openCloseContexMenu} />
                                {
                                    (() => {
                                        if (this.state.header_context_visible === true) return (
                                            <div style={overrideRolesRespoStyle} onMouseLeave={this.closeHeaderContextMenu}>
                                                <div className="table-context-menu-item" onClick={(e) => this.exportToExcel(e)}>Export</div>
                                            </div>
                                        )
                                    })()
                                }
                            </div>
                        </div>

                        <div>
                            <CSLTable processData={this.processAssessmentOverviewData} tableRows={10} />
                        </div>
                        <AlertBox alertParam={this.state.alert_param} />
                    </div>
                </div>
            </div >
        )
    }
}

export default AssessmentOverview
