import React from 'react';
import styled from 'styled-components';
import * as Style from '../Common/StyledComponents';
import { MdClose } from 'react-icons/md';
import { Select } from 'antd';
import CSLDatePickerRemoveBorder from "../Common/CSLDatePickerRemoveBorder.js";
import AlertBox from '../Common/AlertBox.js';
import Store from '../../Common/Store';
import DatePicker from 'react-date-picker';

const moment = require('moment');
const { Option } = Select
const CSLDateCover = styled.div`
    width: 200px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
    `;

class AssignmentDateRangeModal extends React.Component {
    state = {
        title_msg: "",
        sub_title_msg: "",
        assignment_date_range_data: {},
        assignment_date_range_data_from_prop: {},
        list_contacts: [],
        error_msg: null,
        ready: false,
        isclear: false,
        dropdown_contact_list: [],
        selected_contact: {},
        alert_param: null,
        assigned_date: null,
        effective_from_date: null,
        effective_till_date: null,
        isclear: false,
        selectedGroupCompanies: [],
        userGroupCompanies:[]
    }
    componentDidMount() {
        this.initialiseState();
    }

    initialiseState() {
        //let list_contacts = this.props.list_contacts;
        let assignment_date_range_data = this.props.assignment_date_range_data;
        assignment_date_range_data.assigned_date = assignment_date_range_data?.assigned_date !== null ? this.toDateObject(assignment_date_range_data?.assigned_date) : new Date();
        assignment_date_range_data.effective_from_date = assignment_date_range_data?.effective_from_date === -1 ? null : this.toDateObject(assignment_date_range_data?.effective_from_date);
        assignment_date_range_data.effective_till_date = assignment_date_range_data?.effective_till_date === parseInt(process.env.REACT_APP_NO_END_DATE) ? null : this.toDateObject(assignment_date_range_data?.effective_till_date);
        assignment_date_range_data.role_creation_date = this.toDateObject(assignment_date_range_data?.role_creation_date);
        let assigned_date = assignment_date_range_data?.assigned_date;
        let effective_from_date = assignment_date_range_data?.effective_from_date;
        let effective_till_date = assignment_date_range_data?.effective_till_date;
        let title_msg = ""
        let sub_title_msg = ""
        let list_contacts = this.props.list_contacts;
        list_contacts.sort((a, b) => {
            let a1 = a.ContactName;
            let b1 = b.ContactName;
            if (a1 < b1) return -1
            if (a1 > b1) return 1
            return 0
        })
        let dropdown_contact_list = [];
        let contacts = Store.getStoreData('contacts')
        if (assignment_date_range_data.mode === 'add') {
            title_msg = assignment_date_range_data.role_code + " Assignment";
            sub_title_msg = "Select the employee from the dropdown and complete the required field.";
            dropdown_contact_list.push({ id: 0, name: 'Select' })
            list_contacts.forEach(item => {
                if (item.ID in contacts) dropdown_contact_list.push({ id: parseInt(item?.ID), name: item?.ContactName });
            })
        }
        else if (assignment_date_range_data?.mode === 'edit') {
            let x = this.props.list_contacts?.find(item => parseInt(item?.ID) === assignment_date_range_data?.contact_id);
            title_msg = "Edit Dates for " + x?.ContactName
            sub_title_msg = 'Enter the dates when the role was assigned and when it is effective from. Leave the "To" date blank if the role is still effective and the assignment has not finished.'
            dropdown_contact_list.push({ id: parseInt(x?.ID), name: x?.ContactName });
            this.populateUserGroupCompanies(assignment_date_range_data?.contact_id,assignment_date_range_data?.companyId);
        }
        const selected_contact = assignment_date_range_data?.contact_id != 0 ? dropdown_contact_list?.find(item => item?.id === assignment_date_range_data?.contact_id) : dropdown_contact_list[0];      
        this.setState({
            title_msg, sub_title_msg, assignment_date_range_data, assignment_date_range_data_from_prop: assignment_date_range_data, ready: true, list_contacts: this.props.list_contacts,
            dropdown_contact_list, selected_contact, assigned_date, effective_from_date, effective_till_date
        })
    }


    toDateObject = (date) => {
        let date_str = (date % 100).toString() + "-" + Math.floor((date % 10000) / 100).toString() + "-" + Math.floor(date / 10000).toString();
        let date_obj = moment(date_str, 'DD-MM-YYYY').toDate();
        return date_obj;
    }

    toDateValueAsDatabase = (date) => {
        let ret = moment(date).format('YYYY') + moment(date).format("MM") + moment(date).format("DD")       
        return parseInt(ret);
    }

    onAssignedDateChange = (date) => {
        //debugger;
        let assignment_date_range_data = JSON.parse(JSON.stringify(this.state.assignment_date_range_data));
        let alert_param = null;
        if (date === null) {
            alert_param = {
                title: 'ERROR..', message: 'You must set an assignment date', ok_text: 'Ok', confirm: false,
                alertHandler: this.closeAlert, stack: {}
            }
            assignment_date_range_data.assigned_date = new Date();
        }
        else {
            assignment_date_range_data.assigned_date = date;
        }
        this.setState({ assignment_date_range_data, alert_param });
    }

    onFromDateChange = (date) => {
        let assignment_date_range_data = JSON.parse(JSON.stringify(this.state.assignment_date_range_data));
        let alert_param = null;
        if (assignment_date_range_data.effective_till_date === null) {
            assignment_date_range_data.effective_from_date = new Date(date);
        }
        else if (assignment_date_range_data?.effective_till_date !== null && moment(date).unix() < moment(assignment_date_range_data?.effective_till_date).unix()) {
            assignment_date_range_data.effective_from_date = new Date(date);
            assignment_date_range_data.effective_till_date = assignment_date_range_data.effective_till_date !== null ? new Date(assignment_date_range_data.effective_till_date) : null;
        }
        else {
            alert_param = {
                title: 'ERROR..', message: 'Assignment Effective From Date cannot be greater than Assignment Effective To Date.', ok_text: 'Ok', confirm: false,
                alertHandler: this.closeAlert, stack: {}
            }
            assignment_date_range_data.effective_from_date = assignment_date_range_data.effective_from_date !== null ? new Date(assignment_date_range_data.effective_from_date) : null;
            assignment_date_range_data.effective_till_date = assignment_date_range_data.effective_till_date !== null ? new Date(assignment_date_range_data.effective_till_date) : null;
        }
        this.setState({ assignment_date_range_data, alert_param });
    }

    onToDateChange = (date) => {
        let assignment_date_range_data = JSON.parse(JSON.stringify(this.state.assignment_date_range_data));
        let alert_param = null;
        if (assignment_date_range_data?.effective_from_date === null) {
            assignment_date_range_data.effective_till_date = new Date(date);
        }
        else if (assignment_date_range_data?.effective_from_date !== null && moment(date).unix() > moment(assignment_date_range_data?.effective_from_date).unix()) {
            assignment_date_range_data.effective_till_date = new Date(date);
            assignment_date_range_data.effective_from_date = assignment_date_range_data?.effective_from_date !== null ? new Date(assignment_date_range_data.effective_from_date) : null;
        }
        else {
            alert_param = {
                title: 'ERROR..', message: 'Assignment Effective To Date cannot be less than Assignment Effective From Date', ok_text: 'Ok', confirm: false,
                alertHandler: this.closeAlert, stack: {}
            }
            this.setState({ alert_param });
            assignment_date_range_data.effective_till_date = assignment_date_range_data?.effective_till_date != null ? new Date(assignment_date_range_data.effective_till_date) : null;
            assignment_date_range_data.effective_from_date = assignment_date_range_data?.effective_from_date !== null ? new Date(assignment_date_range_data.effective_from_date) : null;
        }
        this.setState({ assignment_date_range_data, alert_param });
    }

    onKeyPressed_from_date = (e) => {
        e.preventDefault();

    }

    saveDateRange = (e) => {
        e.preventDefault();
       
        if (this.state.selected_contact.id === 0) {
            let alert_param = {
                title: 'ERROR..', message: 'You must select an Employee', ok_text: 'Ok', confirm: false,
                alertHandler: this.closeAlert, stack: {}
            }
            this.setState({ alert_param });
            return;
        }
        if (!this.state.selectedGroupCompanies || this.state.selectedGroupCompanies?.length === 0) {
            let alert_param = {
                title: 'ERROR..', message: 'You must select a Group Companies', ok_text: 'Ok', confirm: false,
                alertHandler: this.closeAlert, stack: {}
            }
            this.setState({ alert_param });
            return;
        }
        if (this.state.assignment_date_range_data.assigned_date === null) {
            let alert_param = {
                title: 'ERROR..', message: 'You must set an assignment date', ok_text: 'Ok', confirm: false,
                alertHandler: this.closeAlert, stack: {}
            }
            this.setState({ alert_param });
            return;
        }
        let is_dirty = false;
        let assignment_date_range_data = JSON.parse(JSON.stringify(this.state.assignment_date_range_data));
        if (JSON.stringify(assignment_date_range_data) != JSON.stringify(this.state.assignment_date_range_data_from_prop)) is_dirty = true;
        if (is_dirty === true) assignment_date_range_data.action = assignment_date_range_data.action === 'nochange' ? "update" : assignment_date_range_data.action;
        if (assignment_date_range_data.contact_id === 0) assignment_date_range_data.contact_id = this.state.selected_contact.id;
        assignment_date_range_data.assigned_date = this.toDateValueAsDatabase(assignment_date_range_data?.assigned_date);
        assignment_date_range_data['selectedGroupCompanies'] = this.state.selectedGroupCompanies;
        assignment_date_range_data.effective_from_date = assignment_date_range_data.effective_from_date === null ? -1 : this.toDateValueAsDatabase(assignment_date_range_data?.effective_from_date);
        assignment_date_range_data.effective_till_date = assignment_date_range_data.effective_till_date === null ? parseInt(process.env.REACT_APP_NO_END_DATE) : this.toDateValueAsDatabase(assignment_date_range_data?.effective_till_date);
        assignment_date_range_data.role_creation_date = this.toDateValueAsDatabase(assignment_date_range_data?.role_creation_date);
      
        this.props.getAssignedInfo(assignment_date_range_data);
    }

    handleChange = (e) => {
        e.preventDefault();
        let selected_contact = this.state.dropdown_contact_list.find(item => item.id === parseInt(e.target.value));
    
        this.setState({ selected_contact, selectedGroupCompanies: undefined });
        this.populateUserGroupCompanies(parseInt(e?.target?.value), null)
    }

    populateUserGroupCompanies = (selectedUserId, selectedCompanyId) =>{
        if(selectedUserId !== 0 && this.props.userConfig?.hasGroupCompanies) {
            const userGroupCompanies = this.getUserCompanies(selectedUserId)
            this.setState({
                userGroupCompanies: userGroupCompanies,
                selectedGroupCompanies: selectedCompanyId?[selectedCompanyId]:userGroupCompanies?.map(cmp=>cmp?.id)
            })
        }else{
            this.setState({ selectedGroupCompanies: [this.props.userConfig?.parentCompany?.id] })
        }
    }

    getUserCompanies = (selectedUserId) => {
        const userCompanyInfo = this.props.userConfig?.userLevelGroupCompanies?.find(user => user?.contactId == selectedUserId)
        return userCompanyInfo?.groupCompanies?.length ? userCompanyInfo?.groupCompanies : []
    }

    closeModal = (e) => {
        e.preventDefault();
        let assignment_date_range_data = JSON.parse(JSON.stringify(this.state.assignment_date_range_data));
        //if(JSON.stringify(assignment_date_range_data) != JSON.stringify(this.state.assignment_date_range_data_from_prop)) is_dirty = true;
        //if(is_dirty === true) assignment_date_range_data.action = assignment_date_range_data.action === 'nochange' ? "update" : assignment_date_range_data.action;
        if (assignment_date_range_data.contact_id === 0) assignment_date_range_data.contact_id = this.state.selected_contact.id;
        assignment_date_range_data.assigned_date = this.toDateValueAsDatabase(assignment_date_range_data.assigned_date);
        assignment_date_range_data.effective_from_date = assignment_date_range_data.effective_from_date === null ? -1 : this.toDateValueAsDatabase(assignment_date_range_data.effective_from_date);
        assignment_date_range_data.effective_till_date = assignment_date_range_data.effective_till_date === null ? parseInt(process.env.REACT_APP_NO_END_DATE) : this.toDateValueAsDatabase(assignment_date_range_data.effective_till_date);
        assignment_date_range_data.role_creation_date = this.toDateValueAsDatabase(assignment_date_range_data.role_creation_date);
        //this.props.getAssignedInfo(assignment_date_range_data);
        this.props.closeModal(assignment_date_range_data);
    }

    closeAlert = () => {
        //e.preventDefault();
        this.setState({ alert_param: null })
    }

    changeClear = () => {
        this.setState({ isclear: false })
    }

    render() {     
        if (this.state.ready === false) return (<div></div>);
        return (
            <div>
                <Style.ModalContainer style={{ color: "#1B3451", top: "100px", width: "90%", margin: "0px 5% 0px 5%" }}>
                    <Style.ModalHeader style={{ color: "#1B3451" }}>
                        <div style={{ fontSize: "20px", float: "left", marginTop: "15px", marginLeft: "30px" }}>
                            {this.state.title_msg}
                        </div>

                        <div style={{ fontSize: "13px", marginTop: "50px", marginLeft: "30px", paddingRight: "60px", marginBottom: "15px" }}>
                            {this.state.sub_title_msg}
                        </div>

                        <Style.ModalHeaderCloseBtn style={{ fontSize: "19px" }} onClick={this.closeModal}><MdClose /></Style.ModalHeaderCloseBtn>
                    </Style.ModalHeader>
                    <Style.ModalBody>
                        <div style={{ paddingLeft: "28px", color: "red" }}>
                            {this.state.error_msg}
                        </div>
                        <div style={{ fontSize: "14px", marginTop: "5px", marginRight: "10px", fontWeight: "700", marginLeft: "30px" }}>
                            Employee:
                        </div>

                        <div style={{ fontSize: "14px", marginTop: "5px", marginRight: "10px", fontWeight: "700" }}>
                            <Style.MRModalSelect style={{ width: "87%", marginLeft: "31px", height: "31px", marginTop: "10px" }} onChange={this.handleChange} value={this.state.selected_contact.id} >
                                {
                                    (() => {
                                        let list = [];
                                        this.state.dropdown_contact_list?.forEach((item) => {
                                            list.push(<option key={item?.id} value={item?.id}>{item?.name}</option>);
                                        })
                                        return list;
                                    })()
                                }
                            </Style.MRModalSelect>
                        </div>
                        {this.props?.userConfig?.hasGroupCompanies ? <>
                            <div className='group-companies'>Group Companies:</div>
                            <Select
                                mode="multiple"
                                allowClear
                                disabled={this.props.assignment_date_range_data.mode === 'edit' || this.state.selected_contact?.id === 0 ? true : false}
                                style={{ width: '86%', left: '29px' }}
                                placeholder="Please select"
                                className='incident-type-filter'
                                maxTagCount={4}
                                value={this.state.selectedGroupCompanies}
                                onChange={(selectedOptions) => {
                                    this.setState({ selectedGroupCompanies: selectedOptions, assignment_date_range_data: { ...this.state.assignment_date_range_data } })
                                }
                                }
                            >
                                {this.state.userGroupCompanies?.map(type => (<Option key={type?.id} value={type?.id}>{type?.name}</Option>))}
                            </Select>
                        </>
                            : <div className='assignment-no-group-companies'> Company: <span className='no-group-comapnies'>{this.props?.userConfig?.parentCompany?.name}</span></div>}
                        <div style={{ fontSize: "14px", marginTop: "15px", marginRight: "10px", fontWeight: "700", marginLeft: "30px" }}>
                            Assigned:
                        </div>
                        <div style={{ marginTop: "10px" }}></div>
                        <CSLDateCover style={{ display: "inline-block", width: "86%", marginLeft: "30px", marginRight: "10px" }}>
                            {/* <CSLDatePickerRemoveBorder
                                    updateFromDate={this.state.assigned_date}
                                    ondateValue={this.onAssignedDateChange}
                                    isclear={this.state.isclear}
                                    changeClear={this.changeClear}

                                /> */}
                            <DatePicker
                                onChange={this.onAssignedDateChange}
                                clearIcon={null}
                                calendarIcon={null}
                                locale={"en-GB"}
                                value={this.state.assignment_date_range_data?.assigned_date}
                                disabled={false}
                                onKeyPress={(e) => { e.preventDefault() }}
                            />
                        </CSLDateCover>
                        <div style={{ fontSize: "14px", marginTop: "15px", marginRight: "10px", fontWeight: "700", marginLeft: "30px" }}>
                            Effective From:
                        </div>
                        <div style={{ marginTop: "10px" }}></div>
                        <CSLDateCover style={{ display: "inline-block", width: "86%", marginLeft: "30px", marginRight: "10px" }}>
                            {/* <CSLDatePickerRemoveBorder
                                    updateFromDate={this.state.effective_from_date}
                                    ondateValue={this.onFromDateChange}
                                    isclear={this.state.isclear}
                                    changeClear={this.changeClear}
                                    onKeyPress = {(e)=>{e.preventDefault()}}
                                /> */}
                            <DatePicker
                                onChange={this.onFromDateChange}
                                clearIcon={null}
                                calendarIcon={null}
                                locale={"en-GB"}
                                value={this.state.assignment_date_range_data?.effective_from_date}
                                disabled={false}
                                onKeyPress={(e) => { e.preventDefault() }}
                            />
                        </CSLDateCover>
                        <div style={{ fontSize: "14px", marginTop: "15px", marginRight: "10px", fontWeight: "700", marginLeft: "30px" }}>
                            To:
                        </div>
                        <div style={{ marginTop: "10px" }}></div>
                        <CSLDateCover style={{ display: "inline-block", width: "86%", marginLeft: "30px", marginRight: "10px" }}>
                            {/* <CSLDatePickerRemoveBorder
                                    updateFromDate={this.state.effective_till_date}
                                    ondateValue={this.onToDateChange}
                                    isclear={this.state.isclear}
                                    changeClear={this.changeClear}
                                    onKeyPress = {(e)=>{e.preventDefault()}}
                                /> */}
                            <DatePicker
                                onChange={this.onToDateChange}
                                clearIcon={null}
                                calendarIcon={null}
                                locale={"en-GB"}
                                value={this.state.assignment_date_range_data?.effective_till_date}
                                disabled={false}
                                onKeyPress={(e) => { e.preventDefault() }}
                            />
                        </CSLDateCover>
                    </Style.ModalBody>
                    <div style={{ width: "100%", height: "2px", backgroundColor: "#EBEBEB", marginTop: "20px" }}></div>
                    <div style={{ textAlign: "right", marginTop: "20px" }}>
                        <Style.ConfigBtn style={{ backgroundColor: "#FFFFFF", borderColor: "#8D8989", color: "#8D8989" }} onClick={this.closeModal}>Cancel</Style.ConfigBtn>
                        <Style.ConfigBtn style={{ marginLeft: "15px", marginRight: "15px", backgroundColor: "#18B8A5" }} onClick={this.saveDateRange}>Save</Style.ConfigBtn>
                    </div>
                    <AlertBox alertParam={this.state.alert_param} />
                    <div style={{ width: "100%", height: "1px", backgroundColor: "#EBEBEB", marginTop: "20px" }}></div>
                </Style.ModalContainer>
            </div>
        );
    }
}

export default AssignmentDateRangeModal;
