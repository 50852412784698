import React, { Component } from "react";
import APICall from '../../Common/APICall.js';
import MultiColumnDropdown from "../Common/MultiColumnDropdown.js";
import Utils from "../../Common/Utils.js";
import CSLLoader from '../Common/CSLLoader';
import DatePicker from 'react-date-picker';
import moment from 'moment'
import AlertBox from '../Common/AlertBox';
import Store from "../../Common/Store.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, thin, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import AssessmentConfirmationModal from "./AssessmentConfirmationModal"
import "./AssessmentStyle.css";
import { Select } from "antd"

moment.defaultFormat = "DD/MM/YYYY";
const { Option } = Select


class AssignAssesment extends Component {
    initialState = {
        assessments_data: {},
        employees_data: [],
        selected_assessment_id: 0,
        selected_assessment_name: '',
        selected_employee_id: this.props.selectedUserId,
        filterDueDate: new Date(),
        due_by_str: '',
        isclear: true,
        alert_param: null,
        show_assessment_confirmation: false,
        eligibleGcIds: [],
        matchedGroupCompanies:[],
        has_gc: JSON.parse(localStorage.getItem('has_gc')),
        logged_in_user_role: Store.getStoreData('role')
    }

    state = {
        ...this.initialState
    }

    constructor (props) {
        super(props);
    }

    componentDidMount() {
        this.initialiseState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.initialiseState();
        }
    }
    changeClear = () => {
        this.setState({ isclear: false })
    }


    initialiseState = () => {
        this.processAccessmentData();
    }

    processAccessmentData = async () => {
        this.setState({ ready: false });
        let selected_assessment_name = "";
        const postData = { command: "assessments_details", action: 'list_assessments_in_multidrop', logged_in_user_role: this.state.logged_in_user_role, has_gc: this.state.has_gc };
        const api = new APICall();
        let result = await api.commandWithoutCallback(postData, this.getAssessmentDetails);
        let data = { data_list: [], selected_ids: {}, header_list: { "Assessment Name": "assest_name", "Description": "desc" } };
        let assessments = result.result.assessments;
        if (assessments != null) {
            if (assessments.length > 0) {
                for (const assessment of assessments) {
                    let assessment_name = assessment["name"];
                    let assessment_description = JSON.parse(assessment["assessment_json"])["description"];
                    let id = assessment["id"];
                    const assessment_gcs = assessment["gc_id"]
                    data["data_list"].push({ id: id, assest_name: assessment_name, desc: assessment_description, display: true, code: assessment_name,company_id:assessment["company_id"],  gc_ids: assessment_gcs });

                }
            }
        }
        if (this.props.selectedAssessmentId != 0) {
            selected_assessment_name = assessments.find(a => a["id"] == this.props.selectedAssessmentId)["name"];
            this.setState({ selected_assessment_id: this.props.selectedAssessmentId, selected_assessment_name: selected_assessment_name })
            this.fetchEmployee(this.props.selectedAssessmentId);
        }
        const payload = { "command": "get_group_companies" };
        const groupCmpRes = await api.commandWithoutCallback(payload);
        this.setState({ ready: true, assessments_data: data, userConfig: groupCmpRes });
    }

    closeView = () => {
        this.props.closeModal();
    }

    selectedAssessmentId = (obj) => {
        let assement_id = 0;
        if (Object.keys(obj).length > 0) {
            const [key, value] = Object.entries(obj)[0];
            assement_id = key;
            this.setState({ selected_assessment_id: key, employees_data: [], selected_employee_id: 0 })
        } else {
            this.setState({ selected_assessment_id: 0, employees_data: [], selected_employee_id: 0 })
        }
        this.fetchEmployee(assement_id);
    }

    selectedEmployeeId = (obj) => {
        let employee_id = 0;
        if (Object.keys(obj).length > 0) {
            const [key, value] = Object.entries(obj)[0];
            employee_id = key;
            this.setState({ selected_employee_id: key })
        } else {
            this.setState({ selected_employee_id: 0 })
        }
    }

    onRequestedDueDate = (date) => {
        let curmonth = parseInt(date.getMonth());
        let currmonth = curmonth + 1;
        var dateStr =
            currmonth >= 10
                ? date.getFullYear() + '/' + currmonth + '/' + date.getDate()
                : date.getFullYear() + '/0' + currmonth + '/' + date.getDate();
        this.setState({ due_by_str: dateStr, filterDueDate: date });
    }

    fetchEmployee = (assessment_id) => {
        const selectedAssement = this.state.assessments_data.data_list.find(assessment => assessment.id == assessment_id);
        if (assessment_id != 0) {
            const postData = { command: "assessments_details", action: 'get_assessment_employees', assessment_id, selectedUserId: this.props.selectedUserId, assessment_gcs: selectedAssement?.gc_ids, logged_in_user_role: this.state.logged_in_user_role };

            const api = new APICall();
            //console.log('post data fetchEmployee==>', postData);
            api.command(postData, this.afterFetchEmployees);
        }

    }

    changeEmployee = (evt) => {
        const assesmentId=this.state.selected_assessment_id
        const employeId= evt?.target?.value
        if (assesmentId != 0 && employeId != 0) {
            const postData = { command: "assessments_details", action: 'get_assessment_employees_gcs', assesmentId,employeId, logged_in_user_role: this.state.logged_in_user_role  };

            const api = new APICall();
            api.command(postData, (response)=> this.afterFetchEmployeGcs(response, employeId));
        }
    }

    afterFetchEmployeGcs= (response, employeId) =>{
       
        const eligibleGcIds = response?.result;
        let employeeCompanies=[]
        if (eligibleGcIds) {
            employeeCompanies=  this.state.userConfig?.allCompanies?.filter(
            (cmp) =>
              (eligibleGcIds?.includes(0) && cmp?.isParent) ||
              eligibleGcIds?.includes(cmp?.id)
          );
        } 
        this.setState({ selected_employee_id: employeId, userGroupCompanies: employeeCompanies, selectedGroupCompanies: [] })
    }

    afterFetchEmployees = (result) => {
        let employees_data = [];
        const cts = JSON.parse(localStorage.getItem("contactlist"));
        let contacts = cts.result.contactlist;
        let employees = result.result.employees;

        let user_roles = cts.result.user_roles.smcr;

        employees.forEach(employee => {
            let emp_id = employee["contact_id"];
            if(contacts[emp_id]){
                let name = contacts[emp_id]["ContactName"];
                if (user_roles.hasOwnProperty(emp_id) && user_roles[emp_id] !== "no_access") {
                    employees_data.push({ 'contact_id': emp_id, 'name': name })
                }
            }
        });

        employees_data.sort((prev, next) => {
            const name = prev.name.toLowerCase()
            const nextName = next.name.toLowerCase()
            if (name > nextName) {
                return 1
            }
            if (name < nextName) {
                return -1
            }
            return 0
        })

        this.setState({ employees_data: employees_data })
    }

    assignAssessmentSubmit = (e) => {
        e.preventDefault();
        const { selected_assessment_id, selected_employee_id, selectedGroupCompanies, has_gc } = this.state;
        if(!has_gc) selectedGroupCompanies.push(this.state.userConfig?.parentCompany?.id);
        if (selected_assessment_id > 0 && selected_employee_id > 0 && selectedGroupCompanies?.length > 0) {
            this.setState({ show_assessment_confirmation: true });
        }
    }

    closeConfirmAssessmentModal = () => {
        this.setState({
            ...this.initialState, show_assessment_confirmation: false, selectedGroupCompanies: undefined
        }, () => {
            this.initialiseState();
        });
    }

    populateUserGroupCompanies = (selectedUserId) => {
        this.setState({
            userGroupCompanies: this.getUserCompanies(selectedUserId)
        })
    }

    getUserCompanies = (selectedUserId) => {
        const selectedUserConfig = this.state.userConfig?.userLevelGroupCompanies?.find(user => user?.contactId == selectedUserId)
        const formatedList = []
        if (this.state.userConfig?.parentCompany?.id && this.state.userConfig?.parentCompany?.name) {
            formatedList.push(
                {
                    id: this.state.userConfig?.parentCompany?.id,
                    name: this.state.userConfig?.parentCompany?.name,
                    isParent: true
                }
            )
        }
        const selectedAssement = this.state.assessments_data.data_list.find(assessment => assessment.id == this.state.selected_assessment_id);
        //let assess_gcs = selectedAssement?.gc_ids?.replace("0", selectedAssement?.company_id);
        const gcCompanies = selectedUserConfig?.groupCompanies?.filter(obj => selectedAssement.gc_ids?.toString().includes(obj.id.toString())).map(obj => ({ id: obj?.id, name: obj?.name, isParent: false }))
        gcCompanies?.forEach(gc => {
            const companyDetail = this.state.userConfig?.allCompanies.find(comp => comp.id === gc.id)
            if(companyDetail) {
                gc['isParent'] = companyDetail.isParent;
                if(gc.isParent) {
                    gc.id = 0;
                }
            }
        })
        if (gcCompanies?.length) {
            return [...formatedList, ...gcCompanies]

        } else {
            return formatedList
        }
    }

    getMatchedGroupCompanies = (ids, selectedUserId) => {
        const userCompanies = this.getUserCompanies(selectedUserId);
        const matchedCompanies = userCompanies?.filter((ugc) => ids?.includes(ugc.id));
        return matchedCompanies
    }



    render() {
        if (!this.state.ready) {
            return (
                <div><CSLLoader /></div>
            );
        }
        return (
            <div className="assign-assessment-wrapper">
                <div className="assign-assessment-modal-container">
                    <AlertBox alertParam={this.state.alert_param} />
                    {(() => {
                        if (this.state.show_assessment_confirmation) {
                            let name = this.state.assessments_data.data_list.find((d) => d["id"] == this.state.selected_assessment_id)["assest_name"]
                            let employeeName = this.state.employees_data.find((d) => d["contact_id"] == this.state.selected_employee_id)["name"]
                            return (
                                // <div className="interactive-overlay">
                                <AssessmentConfirmationModal
                                    id={this.state.selected_assessment_id}
                                    emp_id={this.state.selected_employee_id}
                                    closeModal={this.closeConfirmAssessmentModal}
                                    dueDate={this.state.filterDueDate}
                                    assessment_name={name}
                                    employee_name={employeeName}
                                    matchedGroupCompanies={this.state.matchedGroupCompanies}
                                    selectedCompanies={this.state.selectedGroupCompanies}
                                >
                                </AssessmentConfirmationModal>
                                // </div>

                            )
                        }

                        return null;
                    })()}
                    <div className="assign-assessment-modal-header">
                        <div className="header-content-container">
                            <div>
                                <span className="assign-assessment-first-text">Assign New Assignments</span>
                            </div>
                            <div className="assign-assessment-header-close-btn" onClick={this.closeView}>
                                <FontAwesomeIcon title="Action" icon={solid('xmark')} />
                            </div>
                        </div>
                    </div>
                    <div className="assign-assessment-modal-body">
                        <div className="assign-assessment-help-text-container">
                            {/* <div className="assign-assessment-second">Use this form to assign a new Assessment and choose
                                a date by
                                which it should be completed. If this Assessment has been
                                performed for the employee in the past, use the ‘Assign’ option on
                                their last Assessment entry to maintain the Assessment Schedule.
                            </div> */}
                            <div className="assign-assessment-second">
                                <span>Select the assessment you would like to assign</span>
                            </div>
                        </div>

                        <div className="input-container">
                            <div style={{ height: '118px' }}>
                                {
                                    (!Utils.isEmpty(this.state.assessments_data) && this.props.selectedAssessmentId == 0) &&
                                    <React.Fragment>
                                        <div className="assign-assessment-modal-label">
                                            Select Assessment
                                        </div>
                                        <span className="assign-assessment-sub-label">
                                            Select the assessment you would like to assign
                                        </span>
                                        <div style={{ maxHeight: '100px' }}>
                                            <MultiColumnDropdown
                                                data={this.state.assessments_data}
                                                isMultiSelect={false}
                                                selectedId={this.selectedAssessmentId}
                                            />
                                        </div>
                                    </React.Fragment>
                                }
                                {
                                    (this.props.selectedAssessmentId != 0) &&
                                    <React.Fragment>
                                        <div className="assign-assessment-modal-label">
                                            Select Assessment
                                        </div>
                                        <span className="assign-assessment-sub-label">
                                            Select the assessment you would like to assign
                                        </span>
                                        <div style={{ maxHeight: '100px' }}>
                                            <span className="selected-assessment-name">{this.state.selected_assessment_name}</span>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>

                            <div style={{ position: 'relative' }}>
                                {
                                    this.state.selected_assessment_id > 0 &&
                                    <React.Fragment>
                                        <div className="assign-assessment-modal-label">
                                            Select employee to be assessed
                                        </div>
                                        <span className="assign-assessment-sub-label">Select the employee you would like to be assessed</span>

                                        <div style={{ maxHeight: '100px' }}>
                                            <select
                                                name="field_select_employee"
                                                value={this.state.selected_employee_id}
                                                onChange={this.changeEmployee}
                                                className="assign-assessment-dropdown"
                                                style={{ pointerEvents: this.props.selectedUserId == 0 ? 'auto' : 'none' }}
                                            >
                                                {this.state.employees_data.length > 0 && (
                                                    <option key={0} value={0}>
                                                        Select
                                                    </option>
                                                )}
                                                {this.state.employees_data.map((ab) => (
                                                    <option key={ab.contact_id} value={ab.contact_id}>
                                                        {ab.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                            
                            <div style={{ position: 'relative', marginTop: '5px' }}>
                                {(this.state.selected_assessment_id > 0 && this.state.has_gc ) &&
                                    <React.Fragment>
                                        <div className="assign-assessment-modal-label">Group Companies:</div>
                                        <div style={{ maxHeight: '100px' }}>
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                disabled={(this.state.selected_employee_id == 0)}
                                                style={{ width: '100%' }}
                                                placeholder="Please select"
                                                className='incident-type-filter'
                                                maxTagCount={2}
                                                value={this.state.selectedGroupCompanies}
                                                onChange={(selectedOptions) => {
                                                    const mcCompanies = this.state.userGroupCompanies?.filter(company => selectedOptions.includes(company?.isParent ? 0 : company?.id))
                                                    this.setState({ selectedGroupCompanies: selectedOptions, matchedGroupCompanies: mcCompanies })
                                                }
                                                }
                                            >
                                                {this.state.userGroupCompanies?.map(type => (<Option key={type?.id} value={type?.isParent ? 0 : type.id}>{type?.name}</Option>))}
                                            </Select>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                            
                            <div id="dueDateDiv">
                                <div className="assign-assessment-modal-label">
                                    Due Date
                                </div>
                                <div className="assign-assessment-date-cover">
                                    <DatePicker
                                        onChange={this.onRequestedDueDate}
                                        clearIcon={null}
                                        calendarIcon={<FontAwesomeIcon title="Action" style={{ color: '#243E57', marginLeft: '500px' }} icon={solid('calendar-days')} />}
                                        locale={'en-GB'}
                                        value={this.state.filterDueDate == null ? new Date() : this.state.filterDueDate}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="assign-assessment-modal-footer">
                        <button
                            className={`assign-assessment-btn assign-assessment-submit-btn
                                     ${this.state.selected_assessment_id > 0 && this.state.selected_employee_id > 0 ?
                                    'assign-assessment-btn-enabled' : 'assign-assessment-btn-disabled'}`}
                            onClick={this.assignAssessmentSubmit}
                            id="submit"
                        >
                            Assign Assessment
                        </button>

                        <button className="assign-assessment-btn assign-assessment-cancel-button"
                            onClick={() => this.closeView()}>Cancel</button>

                    </div>

                </div>

            </div >

        );
    }
}

export default AssignAssesment;
