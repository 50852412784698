import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import CSLTable from "../Common/CSLTable";
import APICall from "../../Common/APICall";
import Store from "../../Common/Store";
import styled from 'styled-components';
import { FaEllipsisV } from 'react-icons/fa';
import AssignCertManagerModal from "./AssignCertManagerModal";
import SmallSpinner from "../Common/SmallSpinner";

const ViewDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
`;
const RoleDiv = styled.div`
	display : inline-block;
	padding : 5px 10px 5px 10px;
	border-radius : 5px;
	color : #fff;
	font_weight : bold;
`

class CertMgs extends React.Component {

	state = { assigned_roles: {}, selected_role_for_cert: 0, show_add_cert_modal: false, context_visible: false, loading : false}

	async componentDidMount() {
		await this.setComponentState()
	}

	async componentDidUpdate(prevProps) {
		if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
			await this.setComponentState()
		}
	}

	setComponentState = async () => {
		this.setState({ loading : true })
		const api = new APICall()
		//Get List of user assigned SMCR role codes, color and its assigned certification manager
		let payload1 = { command: 'personnel', action: 'get_user_smcr_rolescolor_and_cert_manager', user_id: parseInt(this.props.selected_employee_id) }
		let [user_ass_roles_response] = await Promise.all([api.commandWithoutCallback(payload1)])
		const user_role_records = user_ass_roles_response.result
		let assigned_roles = {}
		for (let row of user_role_records) {
			if (row.id.toString() in assigned_roles === false) {
				assigned_roles[row.id.toString()] = {
					role_id: row.role_id,
					role_type_id: row.role_type_id,
					gc_id: row.gc_id,//gc_id is required while saving/update smcr_cert_mgrs
					companyName: row?.gc_id ? row?.gcName : row?.companyName,
					role_code: row.role_code,
					role_color: row.color,
					cert_id: row.cert_id,//smcr_cert_mgrs.ID
					cert_mgr_id: row.cert_mgr_id ?? 0,//smcr_cert_mgrs.cert_mgr_id (Default to 0)
					certMgrName: row.certMgrName,//smcr_cert_mgrs.cert_mgr_id -> ContactName					
					row_id: row.id, //smcr_role_assignments.ID
					context_visible: false
				}
			}
		}
		this.setState({ assigned_roles,  loading : false })
	}

	openCloseCertContexMenu = (event) => {
		event.preventDefault()
		let assigned_roles = JSON.parse(JSON.stringify(this.state.assigned_roles))
		const context_visible = this.state.context_visible === true ? false : true
		this.setState({ assigned_roles, context_visible })
	}

	closeCertContextMenu = (event) => {
		event.preventDefault()
		this.setState({ context_visible: false })
	}

	addCertMgr = (mode, row_id = null) => (event) => {
		window.scrollTo(0,0);
		event.preventDefault();
		const context_visible = false
		let assigned_roles = JSON.parse(JSON.stringify(this.state.assigned_roles))
		if (row_id !== null) assigned_roles[row_id].context_visible = false
		if (mode === 'add') {
			this.setState({ show_add_cert_modal: true, assigned_roles, context_visible, modalMode: mode });
		} else if (mode === 'edit') {
			this.setState({ show_add_cert_modal: true, selected_role_for_cert: row_id, assigned_roles, context_visible, modalMode: mode });
		}
	}

	toggleContextMenu = (row_id) => (event) => {
		event.preventDefault()
		let assigned_roles = JSON.parse(JSON.stringify(this.state.assigned_roles))
		if(row_id.toString() in assigned_roles){
			assigned_roles[row_id.toString()].context_visible=assigned_roles[row_id.toString()].context_visible !== true
		}
		this.setState({ assigned_roles })
	}

	closeContextMenu = (row_id) => (event) => {
		event.preventDefault()
		let assigned_roles = JSON.parse(JSON.stringify(this.state.assigned_roles))
		assigned_roles[row_id].context_visible = false
		this.setState({ assigned_roles })
	}

	processCertMgrs = () => {
		let has_gc = JSON.parse(localStorage.getItem('has_gc'));
		let assigned_roles = JSON.parse(JSON.stringify(this.state.assigned_roles))
		let ret = { data: [], columns: [] };
		ret.columns = [
			{
				Header: 'ROLE', Cell: row => (
					<RoleDiv style={{ backgroundColor: row.original.role_color }}>{row.original.role_code}</RoleDiv>
				), minWidth: 70, headerStyle: { textAlign: 'left' }
			},
			{ Header: 'GROUP COMPANY', accessor: 'companyName', minWidth: 50, filterable: true, show:has_gc, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' }},
			{ Header: 'CERTIFICATION MANAGER', accessor: 'certMgrName', minWidth: 50, headerStyle: { textAlign: 'left' } },
			{
				'Header': '', filterable: false, Cell: row => (
					<div className="mc-roles-table-action-container" style={{ width: "100%", paddingRight: "10px" }}>
						<FontAwesomeIcon className="mc-roles-add-icon" title="View" icon={regular('ellipsis-vertical')} onClick={this.toggleContextMenu(row.original.row_id)} />
						{
							row.original.context_visible &&
							<div className="context-menu" onMouseLeave={this.closeContextMenu(row.original.row_id)}>
								<div className="context-menu-item" onClick={this.addCertMgr('edit', row.original.row_id)}>Edit</div>
							</div>
						}
					</div>
				),
				show: Store.getStoreData('role') === "team" ? false : true
			}
		];
		if (Object.keys(assigned_roles).length !== 0) {
			for (let key in assigned_roles) {
				let elem = {
					row_id: assigned_roles[key].row_id,//smcr_role_assignments.ID
					role_type_id: assigned_roles[key].role_type_id,
					role_id: assigned_roles[key].role_id,
					role_code: assigned_roles[key].role_code,
					gc_id: assigned_roles[key].gc_id,//gc_id is required while saving/update smcr_cert_mgrs
					companyName: assigned_roles[key].companyName,
					role_color: assigned_roles[key].role_color,
					cert_mgr_id: assigned_roles[key].cert_mgr_id ?? 0,//smcr_cert_mgrs.cert_mgr_id
					cert_id: assigned_roles[key].cert_id,//smcr_cert_mgrs.ID
					certMgrName: assigned_roles[key].cert_mgr_id !== 0 ? assigned_roles[key].certMgrName : '-',
					context_visible: assigned_roles[key].context_visible
				}
				ret.data.push(elem);
			}
		}
		return ret;
	}

	assignCertificationMgr = async (role_cert_mgrs) => {
		if(Object.keys(role_cert_mgrs).length === 0) return;
		const api = new APICall();
		let postData = { command: 'save_cert_manager', contact_id: this.props.selected_employee_id, role_cert_mgrs: role_cert_mgrs };
		let save_resp = await api.commandWithoutCallback(postData);
		if (save_resp.error_code === 0) await this.setComponentState();
		this.setState({ show_add_cert_modal: false, selected_role_for_cert: 0 });
	}

	closeCertMgrModal = () => {
		this.setState({ show_add_cert_modal: false, selected_role_for_cert: 0 });
	}

	render() {
		return (
			<div>
				{
					this.state.show_add_cert_modal === true && Object.keys(this.state.assigned_roles).length > 0 &&
					<AssignCertManagerModal
						assigned_roles={this.state.assigned_roles}
						assigned_roles_backup={this.state.assigned_roles}
						closeModal={this.closeCertMgrModal}
						user_id={this.props.selected_employee_id.toString()}
						row_id={this.state.selected_role_for_cert}
						companyName={this.state.assigned_roles[this.state.selected_role_for_cert]?.companyName}
						assignCertificationMgr={this.assignCertificationMgr}
						mode={this.state.modalMode}
					 style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}/>
				}
				<div className="mc-table-header-container" style={{ backgroundColor: "#fff" }}>
					<div style={{ fontWeight: "600", fontSize: "15px", paddingLeft: "15px" }}>Certification Managers</div>
					<div style={{ display: "flex", justifyContent: "flex-end", position: "relative", zIndex: "1" }}>
						{
							Store.getStoreData('role') !== "team" &&
							<>
								<FontAwesomeIcon className="mc-roles-add-icon" icon={regular('ellipsis-vertical')} onClick={this.openCloseCertContexMenu} />
								{
									this.state.context_visible &&
									<div className="context-menu" style={{ right: "8px" }} onMouseLeave={this.closeCertContextMenu}>
										<div className="context-menu-item" onClick={this.addCertMgr('add', null)}>Add</div>
									</div>
								}
							</>
						}
					</div>
				</div>
				<div className="mc-table-container">
					<CSLTable processData={this.processCertMgrs} />
					{
						this.state.loading && <SmallSpinner />
					}
				</div>
			</div>
		)
	}
}

export default CertMgs