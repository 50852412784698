import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import "./../Modals/ModalStyle.css"
import APICall from "../../../Common/APICall"
import MultiSelectDropDown from "../../Common/MultiSelectDropdown";
import Store from "../../../Common/Store";
import AlertBox from "../../Common/AlertBox";

class AddAssessmentModal extends React.Component {

    state = {
        role_types: [],
        gcs: [],
        assessors: [],
        selected_assessor_id: 0,
        specific_user_id: 0,
        name: "",
        desc: "",
        frequency: 0,
        selected_role_types: [],
        selected_gcs: [],
        alert_param: null
    }

    async componentDidMount() {
        const api = new APICall()
        const role_payload = { command: "topics_api", action: "list_role_types" }
        const gc_payload = { command: "topics_api", action: "list_gcs" }
        const assess_payload = { command: "topics_api", action: "list_assessed_bys" }
        const [role_result, gc_result, assess_result] = await Promise.all([
            api.commandWithoutCallback(role_payload),
            api.commandWithoutCallback(gc_payload),
            api.commandWithoutCallback(assess_payload)
        ])
        let role_types = []
        let gcs = []
        let assessors = []
        if (role_result.error_code === 0) role_types = role_result.result
        if (role_types.length !== 0) {
            for (let i = 0; i < role_types.length; i++) {
                role_types[i].selected = false
            }
        }
        if (gc_result?.length !== 0) {
            gcs = gc_result
            for (let i = 0; i < gcs.length; i++) {
                gcs[i].id = gcs[i].companyId
                gcs[i].name = gcs[i].companyName
                gcs[i].selected = false
            }
        }
        if (assess_result.error_code === 0) assessors = assess_result.result
        const selected_assessor_id = assessors.find(item => item.is_default === 1).id
        this.setState({ role_types, gcs, assessors, selected_assessor_id })
    }

    closeModal = (event) => {
        event.preventDefault()
        this.props.closeModal()
    }

    addAssessment = async (event) => {
        let has_gc = JSON.parse(localStorage.getItem('has_gc'));
        event.preventDefault()
        let err_entities = []
        let msg = ""
        if (this.state.name === "") err_entities.push("Assessment Title")
        if (has_gc) {
            if (this.state.selected_gcs.length === 0) err_entities.push("Legal Entities")
        }

        if (this.state.selected_role_types.length === 0) err_entities.push("Role Types")
        if (this.state.selected_assessor_id === 0) err_entities.push("Assessed by")
        if (this.state.selected_assessor_id === 6 && this.state.specific_user_id === 0) err_entities.push("Select User")
        if (err_entities.length !== 0) {
            if (err_entities.length === 1) {
                msg = `${err_entities[0]} cannot be empty`
            } else {
                msg = "The following fields cannot be empty<br/><ul>"
                for (let item of err_entities) {
                    msg = `${msg}<li>${item}</li>`
                }
                msg = `${msg}</ul>`
            }
            let alert_param = {
                title: 'ERROR', message: msg, ok_text: 'Ok',
                alertHandler: this.processNothing, stack: {}
            }
            this.setState({ alert_param })
        } else {
            const payload = {
                command: "assessments",
                action: 'save_assessment',
                assessment_name: this.state.name,
                assessment_description: this.state.desc,
                selected_companies: this.state.selected_gcs,
                selected_role_types: this.state.selected_role_types,
                assessment_runs_every: this.state.frequency,
                assessed_by_type: this.state.selected_assessor_id,
                assessed_by_id: this.state.specific_user_id
            }
            const api = new APICall()
            const result = await api.commandWithoutCallback(payload)
            if (result.error_code === 0) {
                this.props.refreshAssessments(result.result.resp)
            }
        }
    }

    processNothing = () => {
        this.setState({ alert_param: null })
    }

    handleChange = (event) => {
        event.preventDefault()
        this.setState({ [event.target.name]: event.target.value })
    }

    changeFrequency = (event) => {
        event.preventDefault()
        this.setState({ frequency: parseInt(event.target.value) })
    }

    changeAssessor = (event) => {
        event.preventDefault()
        this.setState({ selected_assessor_id: parseInt(event.target.value), specific_user_id: 0 })
    }

    changeSpecificUser = (event) => {
        event.preventDefault()
        this.setState({ specific_user_id: parseInt(event.target.value) })
    }

    selectedGcOptions = (options) => {
        let selected_gcs = []
        for (let item of options) {
            selected_gcs.push(item.id)
        }
        this.setState({ selected_gcs })
    }

    selectedRtOptions = (options) => {
        let selected_role_types = []
        for (let item of options) {
            selected_role_types.push(item.id)
        }
        this.setState({ selected_role_types })
    }

    render() {
        // let users = Store.getStoreData("listcontacts")
        const cts = JSON.parse(localStorage.getItem("contactlist"));
        let contacts = cts.result.contactlist;

        let user_roles = cts.result.user_roles.smcr;

        const users = [];

        for (const empId in contacts) {
            if (contacts.hasOwnProperty(empId)) {
                const contact = contacts[empId];
                const contactId = contact["ID"];
                const contactName = contact["ContactName"];
                if (user_roles.hasOwnProperty(contactId) && user_roles[contactId] !== "no_access") {
                    users.push({ 'ID': contactId, 'ContactName': contactName });
                }
            }
        }

        return (
            <div>
                {this.state.alert_param !== null && <AlertBox alertParam={this.state.alert_param} />}
                <div className="add-role-modal-container" style={{ width: "55%" }}>
                    <div className="add-role-modal-header-container">
                        <div className="add-role-modal-header-text-container">
                            <div className="add-role-modal-header-title">Add New Assessment</div>
                            <div className="add-role-modal-header-subtitle">Complete the required fields to create a new assessment</div>
                        </div>
                        <FontAwesomeIcon icon={solid('xmark')} onClick={this.closeModal} size="2x" style={{ cursor: "pointer" }} />
                    </div>
                    <div className="add-role-modal-body-container">
                        <div>
                            <div className="add-role-modal-label" style={{ fontSize: "13px", fontWeight: 'bold' }}>Assessment Title</div>
                            <input type="text" name="name" className="add-role-modal-input" value={this.state.name} onChange={this.handleChange} />
                        </div>
                        <div>
                            <div className="add-role-modal-label" style={{ fontSize: "13px", fontWeight: 'bold' }}>Assessment Description</div>
                            <textarea rows={6} className="add-role-modal-textarea" name="desc" value={this.state.desc} onChange={this.handleChange} />
                        </div>
                        {
                            this.state.gcs.length !== 0 &&
                            <div>
                                <div className="add-role-modal-label" style={{ fontSize: "13px", fontWeight: "600" }}>Legal Entities this Assessment is applicable to</div>
                                <MultiSelectDropDown options={this.state.gcs} get_selected_items={this.selectedGcOptions} />
                            </div>
                        }
                        {
                            this.state.role_types.length !== 0 &&
                            <div>
                                <div className="add-role-modal-label" style={{ fontSize: "13px", fontWeight: "600" }}>Role Types this Assessment is applicable to</div>
                                <MultiSelectDropDown options={this.state.role_types} get_selected_items={this.selectedRtOptions} />
                            </div>
                        }
                        <div>
                            <div className="add-role-modal-label" style={{ fontSize: "13px", fontWeight: "600" }}>Frequency</div>
                            <div style={{ marginTop: "5px", display: "flex", columnGap: "5px", alignItems: "center" }}>
                                <div style={{ fontWeight: "500", paddingBottom: "5px" }}>This Assessment will run every</div>
                                <select className="mc-dropdown-sm" style={{ width: "14%" }} value={this.state.frequency} onChange={this.changeFrequency}>
                                    <option value={0}>Select</option>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(item => (
                                        <option key={`rem${item}`} value={item}>{item}</option>
                                    ))}
                                </select>
                                <div style={{ fontWeight: "500", paddingBottom: "5px" }}>months (Select is a one-off assessment)</div>
                            </div>
                        </div>
                        <div>
                            <div className="add-role-modal-label" style={{ fontSize: "13px", fontWeight: "600" }}>Assessed BY</div>
                            <select className="mc-dropdown" value={this.state.selected_assessor_id} onChange={this.changeAssessor}>
                                <option value={0}>Select</option>
                                {
                                    this.state.assessors.map(item => {
                                        return <option key={`assr${item.id}`} value={item.id}>{item.assessed_by}</option>
                                    })
                                }
                            </select>
                        </div>
                        {
                            this.state.selected_assessor_id === 6 &&
                            <div>
                                <div className="add-role-modal-label" style={{ fontSize: "13px", fontWeight: "600" }}>Select User</div>
                                <select className="mc-dropdown" value={this.state.specific_user_id} onChange={this.changeSpecificUser}>
                                    <option value={0}>Select</option>
                                    {
                                        users.map(item => {
                                            return <option key={`user${item.ID}`} value={item.ID}>{item.ContactName}</option>
                                        })
                                    }
                                </select>
                            </div>
                        }
                    </div>
                    <div className="add-role-modal-footer-container">
                        <button className="add-role-modal-cancel-button" onClick={this.closeModal}>Cancel</button>
                        <button className="add-role-modal-save-button" onClick={this.addAssessment}>Save</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddAssessmentModal
