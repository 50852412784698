import React from "react";
import Store from "../../Common/Store.js"
import "../Moduleconfig/Modals/ModalStyle.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import CSLTable from "../Common/CSLTable.js";
import "../Moduleconfig/MConfig.css";
import APICall from "../../Common/APICall";

class SendForAcceptance extends React.Component {
    
    state = {assignedRoles:[], otherAssignedRoles:[], checked: false, employee_name: "", hasGroupCompanies:false}

    async componentDidMount() {
        await this.setComponentState();
    }

    setComponentState = async () => {
        const hasGroupCompanies = JSON.parse(localStorage.getItem('has_gc'))
        const api = new APICall();
        const payload = {command: "roles_acceptance", action: "list_user_roles", user_id: this.props.employee_id};
        const payload1 = {command: "roles_acceptance", action: "list_other_user_roles", user_id: this.props.employee_id};
        const ret = await api.commandWithoutCallback(payload);
        const ret1 = await api.commandWithoutCallback(payload1);
        let roles = JSON.parse(JSON.stringify(ret));
        let roles1 = JSON.parse(JSON.stringify(ret1));
        const emp = Store.getStoreData("listcontacts").find(item => parseInt(item.ID) === parseInt(this.props.employee_id))
        const employee_name = emp === undefined ? "" : emp.ContactName
        if(roles.error_code === 0){
            this.setState({ assignedRoles:roles.result, employee_name, hasGroupCompanies:hasGroupCompanies?true:false });
        }
        if(roles1.error_code === 0){
            this.setState({ otherAssignedRoles:roles1.result, employee_name, hasGroupCompanies:hasGroupCompanies?true:false  });
        }
    }
    
    closeModal = (e) => {
        e.preventDefault();
        this.props.closemodal();
    }
    submitAcceptance = (e) => {
        e.preventDefault();
        console.log("CHECKBox", this.state.checked);
        this.props.submitForAcceptance();
    }
    handleChange = (e) =>{
        this.setState({checked: e.target.checked});
    }
    isDisabled =(e) =>{
        console.log("BUTTON", e);
    }

    tableData = () => {
        let ret = { data: [], columns: [] };
        ret.columns = [
            {Header: 'Role ID', accessor: "role_code", filterable: true, minWidth: 100},
            {Header: 'Title', accessor: "title", filterable: true, minWidth: 100},
            { Header: 'Group Company', accessor: "companyName", filterable: true, minWidth: 100, show: this.state?.hasGroupCompanies },
            {Header: 'Effective Date', accessor: "effdate", filterable: true, minWidth: 100},
        ];
        if (this.state.assignedRoles.length !== 0) {
            for (let d of this.state.assignedRoles) {
                let elem = {
                    role_code: d.role_code,
                    title: d.title,
                    effdate: d.eff_date,
                    companyName: d?.gcId ? d?.gcName : d?.companyName
                }
                ret.data.push(elem);
            }
        }
        return ret;
    }

    tableData1 = () => {
        let list_contacts = Store.getStoreData('listcontacts');
        let ret = { data: [], columns: [] };
        ret.columns = [
            {Header: 'Role ID', accessor: "role_code", filterable: true, minWidth: 100},
            {Header: 'Assigned To', accessor: "assigned_to", filterable: true, minWidth: 100},
            { Header: 'Group Company', accessor: "companyName", filterable: true, minWidth: 100, show: this.state?.hasGroupCompanies },
            {Header: 'Effective Date', accessor: "effdate", filterable: true, minWidth: 100},
        ];
        if (this.state.otherAssignedRoles.length !== 0) {
            for (let d of this.state.otherAssignedRoles) {
                let elem = {
                    role_code: d.role_code,
                    assigned_to: d.contact_id !== 0 ? list_contacts.find(item => item.ID === d.contact_id.toString()).ContactName : '-',
                    effdate: d.eff_from_date,
                    companyName: d?.gcId ? d?.gcName : d?.companyName
                }
                ret.data.push(elem);
            }
        }
        console.log("RET", ret);
        return ret;
    }

    render(){
        return(
            <div className="add-role-modal-container" style={{top: "15vh", height: "85vh", width: "35vw", position: "absolute", zIndex: 100000}}>
                <div className="add-role-modal-header-container">
                    <div className="add-role-modal-header-text-container">
                        <div className="add-role-modal-header-title" style={{position: "relative"}}>Acceptance Confirmation</div>
                        <div className="add-role-modal-header-subtitle" style={{position: "relative"}}>You are about to send the following Roles to {this.state.employee_name} for acceptance</div>
                        <FontAwesomeIcon icon={solid('xmark')} onClick={this.closeModal} size="2x" style={{cursor: "pointer", position: "absolute", left: "94%"}}/>
                    </div>
                </div>
                <div className="add-role-modal-body-container" style={{height: "69vh", overflowX: "hidden", overflowY: "scroll"}}>
                    <div style={{border: "2px solid rgb(248, 90, 116)", color: "rgb(207,57,57)", backgroundColor: "rgb(236,202,202)", borderRadius: "5px"}}>
                        <div style={{fontWeight: 600, padding: "10px"}}>Please check that the assigned roles/responsibilities below are correct, then submit them to the employee for acceptance.</div>
                    </div>
                    <div className="mc-table-container">
                        <CSLTable tableRows={5} processData={this.tableData}/>
                    </div>
                    <div>
                        {this.state.otherAssignedRoles.length !== 0 &&
                            <>
                                <div style={{border: "2px solid rgb(248, 90, 116)", color: "rgb(207,57,57)", backgroundColor: "rgb(255, 235, 235)", borderRadius: "5px"}}>
                                    <div style={{fontWeight: 600, padding: "10px"}}>Warning: The following roles are also assigned to the employees shown below. Please review and confirm that these roles can be shared/divided.</div>
                                </div>
                                
                                <div className="mc-table-container" style={{marginTop: "25px"}}>
                                    <CSLTable tableRows={5} processData={this.tableData1}/>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginTop: "10px"}}>
                                    <input style={{position: "relative"}} value="test" type="checkbox" onChange= {this.handleChange}/> 
                                    <div style={{position: "relative", fontWeight: 600, padding: "10px"}}>I confirm these role(s) can be shared/divided</div>
                                </div>
                            </>    
                        }    
                    </div>    
                </div>
                <div className="add-role-modal-footer-container">
                    <button className="add-role-modal-cancel-button" onClick={this.closeModal}>Cancel</button>
                    {this.state.otherAssignedRoles.length !== 0 && this.state.checked === true && 
                        <button className="add-role-modal-save-button" onClick={this.submitAcceptance}>Submit</button>
                    }
                    {this.state.otherAssignedRoles.length !== 0 && this.state.checked !== true &&
                        <button className="add-role-modal-save-button" style={{backgroundColor: "#959595", border: "1px solid #959595", cursor: "default"}} disabled={!this.state.isDisabled}>Submit</button>
                    }
                    {this.state.otherAssignedRoles.length === 0 &&
                        <button className="add-role-modal-save-button" onClick={this.submitAcceptance}>Submit</button>
                    }
                </div>
            </div>
        )
    }
}

export default SendForAcceptance;
